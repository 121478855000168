import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import { makeStyles, Typography } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { disconnectSocket } from "../../apiCalls/socketApi";
import { useDispatch, useSelector } from "react-redux";
import { USER_TYPE } from "../../appConstants/constants";
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: 10,
  },
  submitYes: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "white",
    color: "black",
    paddingLeft: 20,
    paddingRight: 20,
    marginRight: 20,
    border: "2px solid #000",
  },
  submitNo: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#FF2E56",
    color: "white",
    paddingLeft: 20,
    paddingRight: 20,
    marginLeft: 20,
  },
  buttonDiv: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    padding: theme.spacing(0, 4, 3),
  },
  divider: {
    width: "100%",
    height: "1px",
    backgroundColor: "#f1f1f1",
  },
}));
function TrailOverModal(props) {
  const classes = useStyles();

  const [headerText, setHeaderText] = useState("");
  const [infoText, setInfoText] = useState("");
  const [footerText, setFooterText] = useState("");

  const dispatch = useDispatch();

  const role = useSelector((state) => state.auth.role);
  const currentCompany = useSelector((state) => state.data.currentCompany);
  const userData = useSelector((state) => state.auth.userData);

  useEffect(() => {
    // console.log({ role, currentCompany, userData });
    if (role === USER_TYPE.COMPANY) {
      if (userData.user.isSubscriptionPaused) {
        setHeaderText("Your subscription has been paused");
        setInfoText("Your subscription has been paused");
        setFooterText("resume your subscription.");
      } else {
        setHeaderText("Your trail has expired");
        setInfoText("Your trail has expired");
        setFooterText("subscribe to our service.");
      }
    } else {
      if (currentCompany?.isSubscriptionPaused) {
        setHeaderText("Your subscription has been paused");
        setInfoText("Your company's subscription has been paused");
        setFooterText("contact you company admin");
      } else {
        setHeaderText("Your trail has expired");
        setInfoText("Your company's trail has expired");
        setFooterText("contact you company admin.");
      }
    }

    // eslint-disable-next-line
  }, [props.open]);

  return (
    <div>
      <Modal
        open={props.open}
        onClose={() => {
          // props.setOpen(false);
        }}
      >
        <div
          style={{
            top: "20%",
            left: "50%",
            transform: `translate(-${50}%, -${50}%)`,
          }}
          className={classes.paper}
        >
          <Typography
            id="simple-modal-title"
            style={{
              textAlign: "center",
              padding: 16,
              fontSize: 20,
              fontWeight: 500,
              fontFamily: "inter,Arial,sans-serif",
            }}
          >
            {headerText}
          </Typography>
          {/* <div className={classes.divider}></div> */}
          <Divider
            variant="middle"
            style={{ borderBottom: "1px solid #F1F1F1", margin: 0 }}
          />
          <Typography
            style={{
              padding: 15,
              textAlign: "center",
              fontFamily: "inter,Arial,sans-serif",
              color: "#5B5D5B",
            }}
          >
            {infoText}. All the topic, messages and conversations are still
            available, to get full access to our services {footerText}
          </Typography>
          <div className={classes.buttonDiv}>
            {/* <Button
              variant="contained"
              className={classes.submitYes}
              onClick={() => {
                props.setOpen(false);
              }}
            >
              Temporary disable (For developer use only)
            </Button> */}
            {role === USER_TYPE.COMPANY && (
              <Button
                variant="contained"
                className={classes.submitYes}
                onClick={props.openSubscribePage}
              >
                Subscription plans
              </Button>
            )}

            <Button
              className={classes.submitNo}
              onClick={() => {
                disconnectSocket();
                dispatch({ type: "LOGOUT_USER" });
                dispatch({ type: "SET_ADMIN_USER", payload: null });
              }}
            >
              Logout
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default TrailOverModal;
