import React from "react";
import { makeStyles } from "@material-ui/core";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { CountryDropdown } from "react-country-region-selector";
import "./phone-styles.css";
const useStyles = makeStyles((theme) => ({
  hideOnSm: { [theme.breakpoints.down("sm")]: { display: "none" } },
  container: {
    backgroundColor: "white",
    border: "1px solid #EAEDF2",
    display: "flex",
    flexDirection: "row",
    margin: 10,
    maxWidth: "45%",
    alignItems: "center",
  },
  inputField: {
    width: "100%",
    fontFamily: "inter,Arial,sans-serif",
    marginLeft: 10,
    fontWeight: 500,
    fontSize: 16,
  },
  inputFieldCountry: {
    width: "100%",
    border: "none",
    padding: "8px 12px",
    fontFamily: "inter,Arial,sans-serif",
    borderRadius: 5,
    "&:focus": {
      border: "none",
    },
    "&:focus-visible": {
      border: "none",
    },
  },
  "@media (max-width: 500px)": {
    container: {
      maxWidth: "100%",
    },
  },
}));
const CustomLoginTextField = (props) => {
  const classes = useStyles();
  return (
    <div
      className={classes.container}
      style={props.phone ? { minWidth: "45%" } : { padding: 10 }}
    >
      {props.icon}

      {props.phone ? (
        <PhoneInput
          country="ie"
          regions={"europe"}
          value={props.value}
          onChange={props.onChange}
          enableSearch
          dropdownClass="drop-down"
          inputClass="input-class"
          containerClass="container-class"
        />
      ) : props.addCountryDropdown ? (
        <CountryDropdown
          value={props.value}
          onChange={props.onChange}
          className={classes.inputFieldCountry}
          showDefaultOption={false}
          // valueType="short"
        />
      ) : (
        <input
          id="PreventChromeAutocomplete"
          name="hidden"
          autoComplete="false"
          placeholder={props.label}
          className={classes.inputField}
          onChange={props.onChange}
          value={props.value}
          type={props.password ? "password" : "text"}
        />
      )}
    </div>
  );
};

export default CustomLoginTextField;
