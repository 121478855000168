import { Typography } from "@material-ui/core";
// eslint-disable-next-line
import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Avatar from "react-avatar";
import "../miscStyles.css";
import DoneIcon from "@material-ui/icons/Done";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { Grid, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import BottomMenu from "../allConversations/BottomMenu";
import { useWindowDims } from "../../../utils/useDimensions";
import LinkIcon from "@material-ui/icons/Link";
import AddCommentIcon from "@material-ui/icons/AddComment";
import BottomMenuButtons from "../allConversations/BottomMenuButtons";
import LibraryAddIcon from "@material-ui/icons/LibraryAdd";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import AssignConversationMenu from "../allConversations/AssignConversationMenu";
import AssignConversationSubMenu from "../allConversations/AssignConversationSubMenu";
import { getEmployeeData } from "../../../utils/helperFunctions";
import { USER_TYPE } from "../../../appConstants/constants";
const useStyles = makeStyles((theme) => ({
  hideOnSm: { [theme.breakpoints.down("sm")]: { display: "none" } },
  mainBox: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.white,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);
let companyAdmin = null;
function InboxHeader(props) {
  const { width } = useWindowDims();

  const [anchorEl, setAnchorEl] = useState(null);
  const [assignChatAnchor, setAssignChatAnchor] = useState(null);
  const [employeeNameToShow, setEmployeeNameToShow] = useState("");

  const classes = useStyles();

  const [showFullSubject, setShowFullSubject] = useState(false);
  const [showBottomMenu, setShowBottomMenu] = useState(false);

  const currentConversation = useSelector(
    (state) => state.data.currentConversation
  );
  const userData = useSelector((state) => state.auth.userData);
  const currentCompanyEmployees = useSelector(
    (state) => state.data.currentCompanyEmployees
  );
  const role = useSelector((state) => state.auth.role);

  useEffect(() => {
    if (userData.user.adminFirstName && userData.user.adminLastName)
      companyAdmin =
        userData.user.adminFirstName + " " + userData.user.adminLastName;
  }, [userData]);
  useEffect(() => {
    // console.log({ currentConversation });

    let tempName = getEmployeeData(
      currentConversation?.assigned_to,
      currentCompanyEmployees
    )?.name;
    // console.log({ tempName });
    if (width > 700) {
      setEmployeeNameToShow(tempName);
    } else {
      setEmployeeNameToShow(tempName?.slice(0, 10) + "...");
    }

    // eslint-disable-next-line
  }, [currentConversation, currentCompanyEmployees, width]);

  const handleClick = (event) => {
    if (width > 700) {
      setAnchorEl(event.currentTarget);
    } else {
      setShowBottomMenu(!showBottomMenu);
    }
  };
  const handleCloseConversation = async () => {
    // console.log("closing...", { currentConversation });
    props.setModalType("close");
    props.setModalOpen(true);
  };
  const handleReopenConversation = async () => {
    // console.log("closing...", { currentConversation });
    props.setModalType("reopen");
    props.setModalOpen(true);
  };
  const handleSpamConversation = async () => {
    // console.log("spamming...", { currentConversation });
    props.setModalType("spam");
    props.setModalOpen(true);

    setAnchorEl(null);
  };
  const handleRemoveSpamConversation = async () => {
    // console.log("spamming...", { currentConversation });
    props.setModalType("removeSpam");
    props.setModalOpen(true);

    setAnchorEl(null);
  };
  const getFormattedString = (str) => {
    // console.log({ str });
    if (str?.length >= 20) {
      const formattedString = str.slice(0, 20) + "...";
      return formattedString;
    } else {
      return str;
    }
  };
  const showEmployeeMenu = (event) => {
    setAssignChatAnchor(event.currentTarget);
  };
  return (
    <div
      style={
        !currentConversation
          ? { display: "none" }
          : { padding: 10, borderBottom: "2px solid #EAEDF2", flex: 1 }
      }
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          // padding: 10,
        }}
      >
        <Grid container className={classes.mainBox}>
          <Grid
            item
            xs={3}
            md={3}
            style={{ display: "flex", alignItems: "center" }}
          >
            <KeyboardBackspaceIcon
              className="back-button"
              onClick={() => {
                // console.log("back");
                props.setShowList(true);
              }}
            />
          </Grid>
          <Grid item xs={3} md={9}>
            <div
              style={{
                display: "flex",
                alignSelf: "flex-end",
                alignItems: "flex-end",
                justifyContent: "flex-end",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={showEmployeeMenu}
                className="custom-name-design"
              >
                {currentConversation?.assigned_to === userData.user._id &&
                role === USER_TYPE.COMPANY ? (
                  <>
                    <Avatar
                      name={companyAdmin || userData.user.name}
                      size={45}
                      round
                    />
                    <Typography
                      style={{
                        fontWeight: 300,
                        fontSize: 15,
                        marginLeft: 10,
                        fontFamily: "inter,Arial,sans-serif",
                      }}
                    >
                      <strong>{companyAdmin || userData.user.name}</strong>
                    </Typography>
                  </>
                ) : (
                  <>
                    <Avatar
                      name={employeeNameToShow || userData.user.name}
                      size={45}
                      round
                    />
                    <Typography
                      style={{
                        fontWeight: 300,
                        fontSize: 15,
                        marginLeft: 10,
                        fontFamily: "inter,Arial,sans-serif",
                      }}
                    >
                      <strong>
                        {employeeNameToShow || userData.user.name}
                      </strong>
                    </Typography>
                  </>
                )}
              </div>
              <div
                className="custom-check-design"
                style={{ cursor: "pointer" }}
                onClick={
                  currentConversation?.status === "closed"
                    ? handleReopenConversation
                    : handleCloseConversation
                }
              >
                <DoneIcon
                  style={{
                    fontSize: 20,
                    color: "black",
                    alignSelf: "center",
                  }}
                />
              </div>
            </div>

            {/* Start of menu */}

            {width > 700 ? (
              <AssignConversationMenu
                assignChatAnchor={assignChatAnchor}
                setAssignChatAnchor={setAssignChatAnchor}
                setAllConversation={props.setAllConversation}
                type={props.type}
                value={props.value}
                setValue={props.setValue}
              />
            ) : (
              <BottomMenu
                open={assignChatAnchor}
                handleClose={() => {
                  setAssignChatAnchor(false);
                }}
                options={
                  <>
                    <AssignConversationSubMenu
                      setAssignChatAnchor={setAssignChatAnchor}
                      setAllConversation={props.setAllConversation}
                      setShowList={props.setShowList}
                      type={props.type}
                      value={props.value}
                      setValue={props.setValue}
                    />
                  </>
                }
              />
            )}

            {/* End of menu */}
          </Grid>
        </Grid>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 5,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "baseline",
            }}
          >
            {currentConversation && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <MailOutlineIcon style={{ marginRight: 10 }} />
                <Typography
                  style={{
                    fontSize: 16,
                    fontWeight: 600,
                    cursor: "pointer",
                    fontFamily: "inter,Arial,sans-serif",
                  }}
                  onClick={() => {
                    setShowFullSubject(!showFullSubject);
                  }}
                >
                  {showFullSubject
                    ? currentConversation?.subject
                    : getFormattedString(currentConversation?.subject) ||
                      "Please add a subject to the conversation"}
                </Typography>
              </div>
            )}
          </div>

          <MoreHorizIcon
            style={{
              fontSize: 30,
              color: "black",
              alignSelf: "center",
              cursor: "pointer",
            }}
            onClick={handleClick}
          />
          <StyledMenu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => {
              setAnchorEl(null);
            }}
          >
            <StyledMenuItem
              onClick={() => {
                props.setIsCreateTopicModalOpen(true);
                setAnchorEl(null);
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <LinkIcon
                  style={{
                    fontSize: 18,
                    color: "rgb(116 121 142)",
                  }}
                />
                <Typography
                  style={{
                    fontSize: 14,
                    marginLeft: 8,
                    fontFamily: "inter,Arial,sans-serif",
                  }}
                >
                  Create new topic
                </Typography>
              </div>
            </StyledMenuItem>
            <StyledMenuItem
              onClick={() => {
                setAnchorEl(null);
                // console.log({ allGs: props.groups });
                if (props.groups.length <= 0) {
                  toast.error("No Topics exist");
                } else {
                  props.setIsSelectGroupModalOpen(true);
                }
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <AddCommentIcon
                  style={{
                    fontSize: 18,
                    color: "rgb(116 121 142)",
                  }}
                />
                <Typography
                  style={{
                    fontSize: 14,
                    marginLeft: 8,
                    fontFamily: "inter,Arial,sans-serif",
                  }}
                >
                  Add conversation to a Topic
                </Typography>
              </div>
            </StyledMenuItem>
            <StyledMenuItem
              onClick={() => {
                props.setOpenSubjectModal(true);
                setAnchorEl(null);
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <LibraryAddIcon
                  style={{
                    fontSize: 18,
                    color: "rgb(116 121 142)",
                  }}
                />
                <Typography
                  style={{
                    fontSize: 14,
                    marginLeft: 8,
                    fontFamily: "inter,Arial,sans-serif",
                  }}
                >
                  Add conversation subject
                </Typography>
              </div>
            </StyledMenuItem>
            <StyledMenuItem
              onClick={
                currentConversation?.status === "spam"
                  ? handleRemoveSpamConversation
                  : handleSpamConversation
              }
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <ErrorOutlineIcon
                  style={{
                    fontSize: 18,
                    color: "rgb(116 121 142)",
                  }}
                />
                <Typography
                  style={{
                    fontSize: 14,
                    marginLeft: 8,
                    fontFamily: "inter,Arial,sans-serif",
                  }}
                >
                  {currentConversation?.status === "spam"
                    ? "Remove from spam"
                    : "Mark as spam"}
                </Typography>
              </div>
            </StyledMenuItem>
          </StyledMenu>
        </div>
      </div>

      {showBottomMenu && (
        <BottomMenu
          open={showBottomMenu}
          handleClose={() => {
            setShowBottomMenu(false);
          }}
          options={
            <>
              <BottomMenuButtons
                text="Create new topic"
                icon={
                  <LinkIcon
                    style={{
                      fontSize: 18,
                      color: "rgb(116 121 142)",
                    }}
                  />
                }
                onClick={() => {
                  props.setIsCreateTopicModalOpen(true);
                  setShowBottomMenu(false);
                }}
              />
              <BottomMenuButtons
                icon={
                  <AddCommentIcon
                    style={{
                      fontSize: 18,
                      color: "rgb(116 121 142)",
                    }}
                  />
                }
                text="Add conversation to a topic "
                onClick={() => {
                  setShowBottomMenu(false);
                  // console.log({ allGs: props.groups });
                  if (props.groups.length <= 0) {
                    toast.error("No Topics exist");
                  } else {
                    props.setIsSelectGroupModalOpen(true);
                  }
                }}
              />
              <BottomMenuButtons
                icon={
                  <LibraryAddIcon
                    style={{
                      fontSize: 18,
                      color: "rgb(116 121 142)",
                    }}
                  />
                }
                text="Add conversation subject"
                onClick={() => {
                  props.setOpenSubjectModal(true);
                  setShowBottomMenu(false);
                }}
              />
              <BottomMenuButtons
                icon={
                  <ErrorOutlineIcon
                    style={{
                      fontSize: 18,
                      color: "rgb(116 121 142)",
                    }}
                  />
                }
                text={
                  currentConversation?.status === "spam"
                    ? "Remove from spam"
                    : "Mark as spam"
                }
                onClick={
                  currentConversation?.status === "spam"
                    ? () => {
                        props.setModalType("removeSpam");
                        props.setModalOpen(true);
                        setShowBottomMenu(false);
                      }
                    : () => {
                        props.setModalType("spam");
                        props.setModalOpen(true);
                        setShowBottomMenu(false);
                      }
                }
              />
            </>
          }
        />
      )}
    </div>
  );
}

export default InboxHeader;
