import { Typography } from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
// import { toast } from "react-toastify";
import "./styles.css";

const AccordionCustomButton = (props) => {
  const isSiteDisabled = useSelector((state) => state.data.isSiteDisabled);
  const dispatch = useDispatch();
  // console.log({ props: props.value });
  return (
    <div
      className={props.value === props.text ? "acd-button-grey" : "acd-button"}
      onClick={() => {
        if (isSiteDisabled) {
          // toast.warn("Site disabled");
          props.setIsTrailOver(true);
        } else {
          props.setValue(props.text);
          props.setShowHead(false);
          if (props.showDetails) {
            dispatch({ type: "SET_EMPLOYEE_DETAILS_FLAG", payload: true });
          } else {
            dispatch({ type: "SET_EMPLOYEE_DETAILS_FLAG", payload: false });
          }
          if (props.employeeProfile) {
            props.setEmployeeData(props.data);
            props.setShowProfile(true);
            props.setShowList(true);
          } else {
            props.setShowProfile(false);
          }
        }
      }}
    >
      {props.icon}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "90%",
        }}
      >
        <Typography
          style={{
            fontSize: 15,
            fontWeight: 300,
            marginLeft: 10,
            fontFamily: "inter,Arial,sans-serif",
            textAlign: "left",
          }}
        >
          {props.text}
        </Typography>
        <Typography
          style={{
            fontSize: 12,
            fontWeight: 300,
            fontFamily: "inter,Arial,sans-serif",
          }}
        >
          {props.total === 0 ? "" : props.total}
        </Typography>
      </div>
    </div>
  );
};

export default AccordionCustomButton;
