import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { useParams } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import CssBaseline from "@material-ui/core/CssBaseline";
import { Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { loginApi, registrationApi } from "../../apiCalls/AuthenticationApi";
import {
  deleteTempUser,
  fetchTempUser,
  getUserCompany,
} from "../../apiCalls/dataApi";
import { USER_TYPE } from "../../appConstants/constants";

const useStyles = makeStyles((theme) => ({
  hideOnSm: { [theme.breakpoints.down("sm")]: { display: "none" } },
  mainContainer: {
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgb(247, 249, 252)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  loginContainer: {
    backgroundColor: "white",
    boxShadow: "rgb(0 0 0 / 9%) 0px 0.5rem 1rem !important",
    borderRadius: 10,
    padding: "2.25rem",
    maxWidth: "55vh",
    // height: "60vh",
    [theme.breakpoints.down("sm")]: { width: "90%" },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  registrationText: {
    fontSize: 36,
    fontWeight: "bold",
    color: "#333333",
  },
}));

function RegistrationPage(props) {
  const classes = useStyles();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [tempUser, setTempUser] = useState();

  const { receivedEmail } = useParams();

  useEffect(() => {
    console.log({ tempUser });
  }, [tempUser]);
  useEffect(() => {
    console.log("the email is : ", receivedEmail);
    if (receivedEmail) {
      getTempData(receivedEmail);
    }
  }, [receivedEmail]);
  const dispatch = useDispatch();

  const getTempData = async (mail) => {
    const response = await fetchTempUser(mail);
    if (response) {
      setTempUser(response.tempUser);
    } else {
      console.log("server error");
    }
  };
  const handleSignUp = async () => {
    console.log({ tempUser, password, confirmPassword });
    if (password === confirmPassword) {
      const response = await registrationApi(
        tempUser?.email?.toLowerCase(),
        password,
        tempUser?.name,
        tempUser?.company,
        tempUser?.phone,
        tempUser?.type
      );
      if (response.error) {
        toast.error(
          response?.error?.response?.data?.message || "Something went wrong"
        );
      } else {
        console.log({ response });
        const deleteResponse = await deleteTempUser(tempUser._id);
        console.log({ deleteResponse });
        const loginResponse = await loginApi(response.user.email, password);
        if (loginResponse) {
          if (loginResponse.user.type === USER_TYPE.COMPANY) {
            const usersCompany = await getUserCompany(
              loginResponse.user.company
            );
            console.log({ usersCompany });
            const adminUser = {
              role: USER_TYPE.COMPANY,
              token: loginResponse.token,
              user: usersCompany.company,
            };
            dispatch({
              type: "SET_ADMIN_USER",
              payload: loginResponse.user,
            });
            props.history.push("/");
            dispatch({
              type: "USER_LOGIN_SUCCESS",
              payload: adminUser,
            });
            dispatch({ type: "SET_ROLE", payload: USER_TYPE.COMPANY });
          } else {
            console.log({ loginResponse });
            dispatch({
              type: "USER_LOGIN_SUCCESS",
              payload: loginResponse,
            });
            dispatch({ type: "SET_ROLE", payload: loginResponse.role });
            props.history.push("/");
          }
        }
      }
    } else {
      toast.error("passwords do not match");
    }
  };
  return (
    <div className={classes.mainContainer}>
      <CssBaseline />
      <div className={classes.loginContainer}>
        <Typography className={classes.registrationText}>Sign Up</Typography>
        <form className={classes.form} noValidate>
          <TextField
            margin="normal"
            required
            fullWidth
            autoComplete="name"
            value={tempUser?.name}
            autoFocus
            disabled
          />
          <TextField
            margin="normal"
            required
            fullWidth
            autoComplete="email"
            autoFocus
            value={tempUser?.email}
            disabled
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Password"
            type="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Confirm password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={confirmPassword}
            onChange={(e) => {
              setConfirmPassword(e.target.value);
            }}
          />

          <div style={{ padding: 20 }}>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#FF2E56",
                color: "white",
                paddingLeft: 20,
                paddingRight: 20,
              }}
              className={classes.submit}
              onClick={handleSignUp}
            >
              Sign Up
            </Button>
          </div>

          <Grid
            style={{
              marginTop: 10,
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
            container
          >
            <Grid item>
              <Link href="/login" variant="body2">
                {"Already have an account? Sign In"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
}

export default RegistrationPage;
