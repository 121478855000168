import { USER_TYPE } from "../../appConstants/constants";

const initState = {
  authenticated: false,
  userData: null,
  companyData: null,
  role: USER_TYPE.USER,
  adminUser: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initState, action) => {
  switch (action.type) {
    case "USER_LOGIN_SUCCESS":
      return (state = {
        ...state,
        authenticated: true,
        userData: action.payload,
      });
    case "UPDATE_USER_DATA":
      return (state = {
        ...state,
        userData: action.payload,
      });
    case "COMPANY_LOGIN_SUCCESS":
      return (state = {
        ...state,
        authenticated: true,
        companyData: action.payload,
      });
    case "SET_ROLE":
      return (state = {
        ...state,
        role: action.payload,
      });
    case "SET_ADMIN_USER":
      return (state = {
        ...state,
        adminUser: action.payload,
      });
    case "LOGOUT_USER":
      return (state = {
        ...state,
        authenticated: false,
        userData: null,
        role: null,
      });

    default:
      return state;
  }
};
