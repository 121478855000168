import React, { useEffect, useState } from "react";
import SearchBarComponent from "../SearchBarComponent";
import "./styles.css";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MenuIcon from "@material-ui/icons/Menu";
import ToggleSwitch from "../messagesComponent/ToggleSwitch";
import ConversationCards from "./ConversationCards";
import Button from "@material-ui/core/Button";
import GroupModal from "../../modalComponents/GroupModal";
import {
  groupConversations,
  searchMessage,
} from "../../../apiCalls/conversationApi";
import { toast } from "react-toastify";

import { makeStyles, Typography, withStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  MESSAGE_CONSTANTS,
  NAVIGATION_TYPE,
  SORT_BY,
  USER_TYPE,
} from "../../../appConstants/constants";
import ResponsiveAllConversationHeader from "./responsiveAllConversationHeader";
import { useWindowDims } from "../../../utils/useDimensions";
import BottomMenuButtons from "./BottomMenuButtons";
import BottomMenu from "./BottomMenu";
import SearchedMessages from "./SearchedMessages";
import moment from "moment";
const useStyles = makeStyles((theme) => ({
  hideOnSm: { [theme.breakpoints.down("sm")]: { display: "none" } },
  messagesContainer: {
    display: "flex",
    flexDirection: "column",
    // height: "70vh",
    overflow: "scroll",
    overflowX: "hidden",
    // paddingLeft: "1rem",
    // paddingRight: "1rem",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      borderRadius: "5px",
    },
    scrollBehavior: "smooth",
    [theme.breakpoints.down("sm")]: { paddingLeft: 0, paddingRight: 0 },

    // [theme.breakpoints.down("sm")]: { height: "50vh" },
  },

  // "@media (max-height: 700px)": {
  //   messagesContainer: {
  //     height: "45vh",
  //   },
  // },
  // "@media (max-height: 750px)": {
  //   messagesContainer: {
  //     height: "52vh",
  //   },
  // },
}));
const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

function AllConversationsComponent(props) {
  // eslint-disable-next-line
  const { type } = props;
  const {
    allConversation,
    messages,
    isSelected,
    setIsSelected,
    // eslint-disable-next-line
    allGroups,
    setSelectedPhone,
    selectedPhone,
    employeeData,
  } = props;
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  // eslint-disable-next-line
  const [searchedItem, setSearchedItem] = useState("");
  const [filteredConversations, setFilteredConversations] = useState([]);
  const [filteredMessages, setFilteredMessages] = useState([]);

  const [checkedConversation, setCheckedConversation] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [inFocus, setInFocus] = useState(0);
  const [sortBy, setSortBy] = useState(SORT_BY.NEW);

  const [toggleState, setToggleState] = useState("all");
  const [showBottomMenu, setShowBottomMenu] = useState(false);

  const { width } = useWindowDims();
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.auth.userData);
  const role = useSelector((state) => state.auth.role);
  const currentCompany = useSelector((state) => state.data.currentCompany);
  const adminUser = useSelector((state) => state.auth.adminUser);

  const handleClick = (event) => {
    if (width > 700) {
      setAnchorEl(event.currentTarget);
    } else {
      setShowBottomMenu(!showBottomMenu);
    }
  };
  const getTrailPeriod = (date) => {
    return moment(moment(date).add(14, "days")).fromNow();
  };
  useEffect(() => {
    // console.log({ toggleState, filteredConversations });

    if (toggleState === "unReplied") {
      setFilteredConversations((prevData) =>
        prevData.filter(
          (e) => e?.last_message_sender === MESSAGE_CONSTANTS.WHATS_APP_USER
        )
      );
    } else if (toggleState === "Me") {
      if (adminUser) {
        setFilteredConversations((prevData) =>
          prevData.filter((e) => e?.assigned_to === adminUser._id)
        );
      } else {
        setFilteredConversations((prevData) =>
          prevData.filter((e) => e?.assigned_to === userData.user._id)
        );
      }
    } else {
      setFilteredConversations(allConversation);
    }
    // eslint-disable-next-line
  }, [toggleState]);
  useEffect(() => {
    if (allConversation) {
      setFilteredConversations(allConversation);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allConversation]);
  useEffect(() => {
    if (searchedItem === "") {
      setFilteredConversations(allConversation);
      setFilteredMessages([]);

      dispatch({
        type: "SET_SEARCHED_MESSAGE",
        payload: null,
      });
    } else {
      setFilteredConversations((prevData) =>
        prevData.filter(
          (e) =>
            e.last_message.toLowerCase().includes(searchedItem.toLowerCase()) ||
            e?.subject?.toLowerCase().includes(searchedItem.toLowerCase())
        )
      );
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedItem]);
  useEffect(() => {
    if (allConversation.length > 0 && filteredConversations?.length === 0) {
      queryDb();
    } else {
      setFilteredMessages([]);
    }

    // eslint-disable-next-line
  }, [filteredConversations]);
  const createGroup = async () => {
    // console.log("creating group : ", { groupName, checkedConversation, role });

    if (role === USER_TYPE.COMPANY) {
      const response = await groupConversations(
        checkedConversation,
        groupName,
        userData?.user?._id
      );
      if (response) {
        // console.log({ response });
        toast.success("Topic created successfully");
        setModalOpen(false);
        props.setIsGrouping(false);
      }
    } else {
      const response = await groupConversations(
        checkedConversation,
        groupName,
        userData?.user?.company
      );
      if (response) {
        // console.log({ response });
        toast.success("Topic created successfully");
        setModalOpen(false);
        props.setIsGrouping(false);
      }
    }
  };
  const queryDb = async () => {
    if (searchedItem !== "") {
      const response = await searchMessage(searchedItem);
      if (response) {
        setFilteredMessages(response.messages);
        dispatch({
          type: "SET_SEARCHED_MESSAGE",
          payload: searchedItem,
        });
      }
    }
  };

  const sortedConversations = filteredConversations.sort(function compare(
    a,
    b
  ) {
    var dateA = new Date(a.last_message_time);
    var dateB = new Date(b.last_message_time);
    if (sortBy === SORT_BY.NEW) {
      return dateB - dateA;
    } else {
      return dateA - dateB;
    }
  });

  return (
    <div style={{ width: "100%" }} className={props.className}>
      {props.showList && !props.showHead && width <= 900 ? (
        <ResponsiveAllConversationHeader
          showList={props.showList}
          showHead={props.showHead}
          setShowHead={props.setShowHead}
          employeeData={employeeData}
          type={props.type}
          handleSortingMenuClick={handleClick}
          searchedItem={searchedItem}
          setSearchedItem={setSearchedItem}
        />
      ) : (
        <>
          <SearchBarComponent
            searchedItem={searchedItem}
            setSearchedItem={setSearchedItem}
          />
          <div
            style={{
              display: "flex",
              padding: 20,
              justifyContent: "space-between",
            }}
          >
            <Typography style={{ fontSize: 16, fontWeight: 600 }}>
              {props.type === NAVIGATION_TYPE.USER_PROFILE
                ? employeeData?.name + "'s messages"
                : type}
            </Typography>

            <MenuIcon
              style={{ fontSize: 25, cursor: "pointer" }}
              onClick={handleClick}
            />
          </div>
        </>
      )}

      <StyledMenu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        <MenuItem
          onClick={() => {
            setSortBy(SORT_BY.NEW);
            setAnchorEl(null);
          }}
        >
          Newest first
        </MenuItem>
        <MenuItem
          onClick={() => {
            setSortBy(SORT_BY.OLD);
            setAnchorEl(null);
          }}
        >
          Oldest first
        </MenuItem>
      </StyledMenu>
      {props.type !== "New" && (
        <ToggleSwitch
          toggleState={toggleState}
          setToggleState={setToggleState}
        />
      )}

      {props.isGrouping && (
        <Button
          variant="contained"
          style={{
            backgroundColor: "#FF2E56",
            color: "white",
            paddingLeft: 20,
            paddingRight: 20,
            marginTop: 20,
            alignSelf: "center",
          }}
          onClick={() => {
            // console.log("grouping...");
            setModalOpen(true);
          }}
        >
          Create Topic
        </Button>
      )}
      <div
        className={classes.messagesContainer}
        style={
          props.showList && !props.showHead && width <= 900
            ? { height: "80vh" }
            : { height: "70vh" }
        }
      >
        <div style={{ marginTop: 10, height: "100%" }}>
          {filteredConversations?.length > 0 || filteredMessages.length > 0 ? (
            <>
              {sortedConversations?.map((element, index) => (
                <ConversationCards
                  setIsSelected={setIsSelected}
                  isSelected={isSelected}
                  selected={index === isSelected ? true : false}
                  index={index}
                  data={element}
                  messages={messages}
                  key={index}
                  setShowList={props.setShowList}
                  setCheckedConversation={setCheckedConversation}
                  isGrouping={props.isGrouping}
                  selectedPhone={selectedPhone}
                  setSelectedPhone={setSelectedPhone}
                  inFocus={index === inFocus ? true : false}
                  setInFocus={setInFocus}
                  showList={props.showList}
                />
              ))}
              {filteredMessages.length > 0 && (
                <Typography>Search results</Typography>
              )}
              {filteredMessages.map((message, index) => (
                <SearchedMessages
                  key={index}
                  data={message}
                  allConversation={allConversation}
                  selectedPhone={selectedPhone}
                  setSelectedPhone={setSelectedPhone}
                  showList={props.showList}
                  setShowList={props.setShowList}
                  setIsSelected={setIsSelected}
                />
              ))}
            </>
          ) : (
            <div
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                style={{ fontSize: 18, fontWeight: 500, color: "#ABAEBE" }}
              >
                No conversations
              </Typography>
            </div>
          )}

          {role === USER_TYPE.COMPANY ? (
            <>
              {!userData?.user?.stripeSubscriptionId && (
                <Typography
                  style={{
                    fontSize: 14,
                    fontWeight: 400,
                    color: "#AEB6D1",
                    marginTop: 30,
                  }}
                >
                  Your trial expires{" "}
                  <span
                    style={{
                      color: "#30AE9F",
                    }}
                  >
                    {getTrailPeriod(userData?.user?.trail_started)}
                  </span>
                </Typography>
              )}
            </>
          ) : (
            <>
              {!currentCompany?.stripeSubscriptionId && (
                <Typography
                  style={{
                    fontSize: 14,
                    fontWeight: 400,
                    color: "#AEB6D1",
                    marginTop: 30,
                  }}
                >
                  Your trial expires{" "}
                  <span
                    style={{
                      color: "#30AE9F",
                    }}
                  >
                    {getTrailPeriod(currentCompany?.trail_started)}
                  </span>
                </Typography>
              )}
            </>
          )}
        </div>
      </div>

      <GroupModal
        open={modalOpen}
        setOpen={setModalOpen}
        setData={setGroupName}
        onSubmit={createGroup}
      />
      {showBottomMenu && (
        <BottomMenu
          open={showBottomMenu}
          handleClose={() => {
            setShowBottomMenu(false);
          }}
          options={
            <>
              <BottomMenuButtons
                text="Newest first"
                onClick={() => {
                  setSortBy(SORT_BY.NEW);
                  setShowBottomMenu(false);
                }}
              />
              <BottomMenuButtons
                text="Oldest First"
                onClick={() => {
                  setSortBy(SORT_BY.OLD);
                  setShowBottomMenu(false);
                }}
              />
            </>
          }
        />
      )}
    </div>
  );
}

export default AllConversationsComponent;
