import React, { useEffect, useState } from "react";
import AllConversationsComponent from "../../components/chatComponenets/allConversations/AllConversationsComponent";
import InboxBody from "../../components/chatComponenets/messagesComponent/InboxBody";
import InboxHeader from "../../components/chatComponenets/messagesComponent/InboxHeader";
import { Grid, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import "./styles.css";
import {
  getGroups,
  getUpdatedConversations,
  joinCompanyConversations,
  joinGroups,
  unsubscribeListener,
} from "../../apiCalls/socketApi";
import {
  addConversationSubject,
  closeConversation,
  getConversationMessagesFromDb,
  getConversationsFromDb,
  removeFromSpam,
  reopenConversation,
  spamConversation,
} from "../../apiCalls/conversationApi";
import ConfirmationModal from "../../components/modalComponents/ConfirmationModal";
import AddSubjectModal from "../../components/modalComponents/AddSubjectModal";
import { toast } from "react-toastify";
import {
  MESSAGE_CONSTANTS,
  NAVIGATION_TYPE,
  SENDER_NAME,
  USER_TYPE,
} from "../../appConstants/constants";
import {
  sendWhatsAppMediaMessage,
  sendWhatsAppMessage,
} from "../../apiCalls/whatsAppApi";
import AllTopicComponent from "../../components/chatComponenets/allTopicsComponent/AllTopicComponent";
import SelectGroupModal from "../../components/modalComponents/selectGroupModal";
import CreateTopicModal from "../../components/modalComponents/createTopicsModal";
import UserDetails from "../../components/userDetails/UserDetails";
import {
  refreshAgentTotals,
  refreshTotals,
} from "../../redux/actions/data.actions";
import { useWindowDims } from "../../utils/useDimensions";

const useStyles = makeStyles((theme) => ({
  mainBox: {
    height: "100%",
    backgroundColor: "white",
  },
  hideOnSm: {
    // display: "flex",

    [theme.breakpoints.down("sm")]: { display: "none" },
    borderRight: "solid",
    borderWidth: 1,
    borderColor: "#cacdd7 !important",
    backgroundColor: "white",
  },
  conversationsColumn: {
    display: "flex",
    flexDirection: "column",
    // [theme.breakpoints.down("sm")]: { display: "none" },
    borderRight: "solid",
    borderWidth: 1,
    borderColor: "#cacdd7 !important",
    backgroundColor: "white",
  },
  messagesColumn: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    // [theme.breakpoints.down("sm")]: { display: "none" },
    backgroundColor: "white",
    padding: 10,
  },
  unreadBadge: {
    height: 10,
    width: 10,
    background: "#FE9900",
    borderRadius: 50,
  },
  paper: {
    position: "absolute",
    // width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
  },
  submitYes: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "white",
    color: "black",
    paddingLeft: 20,
    paddingRight: 20,
    marginRight: 20,
    border: "2px solid #000",
  },
  submitNo: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#FF2E56",
    color: "white",
    paddingLeft: 20,
    paddingRight: 20,
    marginLeft: 20,
  },
  buttonDiv: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
}));

function ChatWindow(props) {
  const {
    type,
    employeeData,
    showList,
    setShowList,
    showHead,
    setShowHead,
    value,
    setValue,
    dbConversations,
  } = props;

  const [allConversation, setAllConversation] = useState([]);
  const [messages, setMessages] = useState(null);
  const [receivedMessage, setReceivedMessage] = useState(null);

  const [isSelected, setIsSelected] = useState(false);
  const [selectedPhone, setSelectedPhone] = useState(0);
  const [searchedItem, setSearchedItem] = useState("");

  const [allGroups, setAllGroups] = useState([]);

  const [typedMessage, setTypedMessage] = useState("");

  const [doesConversationExist, setDoesConversationExist] = useState(false);
  const [modalType, setModalType] = useState("close");
  const [open, setOpen] = useState(false);
  const [openSubjectModal, setOpenSubjectModal] = useState(false);

  const [subject, setSubject] = useState(false);
  const [isGrouping, setIsGrouping] = useState(false);

  const [isSelectGroupModalOpen, setIsSelectGroupModalOpen] = useState(false);
  const [isCreateTopicModalOpen, setIsCreateTopicModalOpen] = useState(false);

  //loading states
  const [isMediaLoading, setIsMediaLoading] = useState(false);
  const [isModalDisabled, setIsModalDisabled] = useState(false);

  // const [loadingConversations, setLoadingConversations] = useState(false);
  //Sound hook

  const dispatch = useDispatch();
  const { width } = useWindowDims();
  const userData = useSelector((state) => state.auth.userData);
  const adminUser = useSelector((state) => state.auth.adminUser);

  const currentConversation = useSelector(
    (state) => state.data.currentConversation
  );
  const currentSender = useSelector((state) => state.data.currentSender);
  // const conversationTotals = useSelector(
  //   (state) => state.data.conversationTotals
  // );

  const role = useSelector((state) => state.auth.role);

  const classes = useStyles();

  useEffect(() => {
    // console.log({ currentConversation });
    if (!currentConversation) {
      setShowList(true);
    }
    // eslint-disable-next-line
  }, [currentConversation]);
  useEffect(() => {
    if (allConversation.length > 0) {
      const temp = allConversation.sort(function compare(a, b) {
        var dateA = new Date(a.last_message_time);
        var dateB = new Date(b.last_message_time);
        return dateB - dateA;
      });
      // console.log({ temp });
      setAllConversation(temp);
      setDoesConversationExist(true);
    } else {
      setDoesConversationExist(false);
    }
    if (role === USER_TYPE.COMPANY) {
      dispatch(refreshTotals(userData.user._id));
    } else {
      dispatch(refreshAgentTotals(userData.user.company, userData.user._id));
    }
    // eslint-disable-next-line
  }, [allConversation]);
  useEffect(() => {
    console.log({ type });
    dispatch({ type: "SET_MESSAGES", payload: [] });
    dispatch({ type: "SET_CURRENT_CONVERSATION", payload: null });
    if (role === USER_TYPE.COMPANY) {
      // console.log("joining company convos");
      joinCompanyConversations(userData?.user?._id);
      joinGroups(userData?.user?._id);
      getGroups(setAllGroups);
    } else {
      // console.log("joining user convos", { userData });
      joinCompanyConversations(userData?.user?.company);
      joinGroups(userData?.user?.company);
      getGroups(setAllGroups);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, role, employeeData]);
  useEffect(() => {
    // console.log({ type });
    if (type === NAVIGATION_TYPE.ADD_A_USER) {
      setShowList(false);
    } else if (type === NAVIGATION_TYPE.USER_PROFILE) {
      setShowList(true);
    }
    // eslint-disable-next-line
  }, [type]);
  useEffect(() => {
    if (userData?.user?._id && role) {
      fetchDbConversations();
    }
    // eslint-disable-next-line
  }, [type, userData, role, employeeData]);
  useEffect(() => {
    // console.log({ showList });
    fetchDbConversations();
    // eslint-disable-next-line
  }, [showList]);
  useEffect(() => {
    setAllConversation((prev) => [...prev, dbConversations]);
  }, [dbConversations]);

  useEffect(() => {
    unsubscribeListener();
    getUpdatedConversations(setReceivedMessage, setAllConversation);
    if (isSelected) {
      fetchDbConversations();
      populateMessages(isSelected, 0, 20);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelected]);
  useEffect(() => {
    if (receivedMessage) {
      if (width < 700 && showList) {
        toast.info("A new message came in", {
          position: "top-right",
          autoClose: 3000,
          pauseOnHover: false,
          pauseOnFocusLoss: false,
        });
      }
      if (receivedMessage?.conversation === currentConversation?._id) {
        if (messages) {
          setMessages((prev) => [...prev, receivedMessage]);
        } else {
          setMessages(receivedMessage);
        }
      } else {
        // console.log("not adding...");
        toast.info("A new message came in", {
          position: "top-right",
          autoClose: 3000,
          pauseOnHover: false,
          pauseOnFocusLoss: false,
        });
      }
    }

    // eslint-disable-next-line
  }, [receivedMessage]);
  useEffect(() => {
    setReceivedMessage(null);
    if (messages) {
      // console.log("Message  : ", { messages });
      dispatch({ type: "SET_MESSAGES", payload: messages });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  // ne git
  const populateMessages = async (id, page, limit) => {
    const response = await getConversationMessagesFromDb(id, page, limit);
    if (response) {
      // console.log({ response });
      setMessages(response.messages);
    }
  };
  const handleSendMessage = async () => {
    console.log({ currentConversation, userData });
    if (currentConversation.assigned_to === userData.user._id && !adminUser) {
      console.log("in first condition");
      dispatch({
        type: "SET_CURRENT_CONVERSATION",
        payload: {
          ...currentConversation,
          last_message_sender: MESSAGE_CONSTANTS.AGENT,
        },
      });

      setAllConversation((prevChat) =>
        prevChat.map((e) =>
          // eslint-disable-next-line
          e._id == currentConversation._id
            ? {
                ...e,
                last_message_sender: MESSAGE_CONSTANTS.AGENT,
              }
            : e
        )
      );
      const messageObject = {
        conversation: currentConversation?._id,
        message: typedMessage,
        date: new Date(),
        from: SENDER_NAME + "-" + userData.user.name,
      };
      setTypedMessage("");
      setMessages((prevChat) => [...prevChat, messageObject]);

      const response = await sendWhatsAppMessage(
        messageObject.message,
        currentSender.phone,
        userData.user._id,
        currentConversation?.sender._id,
        userData.user.name
      );

      console.log({ response });
    } else {
      if (currentConversation?.assigned_to === adminUser?._id) {
        dispatch({
          type: "SET_CURRENT_CONVERSATION",
          payload: {
            ...currentConversation,
            last_message_sender: MESSAGE_CONSTANTS.AGENT,
          },
        });
        setAllConversation((prevChat) =>
          prevChat.map((e) =>
            // eslint-disable-next-line
            e._id == currentConversation._id
              ? {
                  ...e,
                  last_message_sender: MESSAGE_CONSTANTS.AGENT,
                }
              : e
          )
        );
        const messageObject = {
          conversation: currentConversation?._id,
          message: typedMessage,
          date: new Date(),
          from: SENDER_NAME + "-" + adminUser?.name,
        };
        setTypedMessage("");
        setMessages((prevChat) => [...prevChat, messageObject]);

        const response = await sendWhatsAppMessage(
          messageObject.message,
          currentSender.phone,
          adminUser?._id,
          currentConversation?.sender._id,
          adminUser?.name
        );
        console.log({ response });
      } else {
        setTypedMessage("");

        toast.warn(
          "This conversation is assigned to someone else you can not send a message here"
        );
      }
    }
  };
  const handleSendMediaMessage = async (url, type) => {
    console.log({ currentConversation, userData, adminUser, url, type });

    if (currentConversation.assigned_to === userData.user._id && !adminUser) {
      setIsMediaLoading(true);
      const whatsAppResponse = await sendWhatsAppMediaMessage(
        typedMessage,
        currentSender.phone,
        url,
        userData.user._id,
        currentConversation?.sender._id,
        type,
        userData.user.name
      );
      if (whatsAppResponse) {
        console.log({ whatsAppResponse });
        const messageObject = {
          conversation: currentConversation?._id,
          message: typedMessage,
          date: new Date(),
          message_type: type,
          media_uri: url,
          from: SENDER_NAME + "-" + userData.user.name,
        };

        setMessages((prevChat) => [...prevChat, messageObject]);
        console.log({ whatsAppResponse });
        setTypedMessage("");
        setIsMediaLoading(false);
      } else {
        setTypedMessage("");
        setIsMediaLoading(false);

        console.log("error");
      }
    } else {
      if (currentConversation?.assigned_to === adminUser?._id) {
        const messageObject = {
          conversation: currentConversation?._id,
          message: typedMessage,
          date: new Date(),
          message_type: type,
          media_uri: url,
          from: SENDER_NAME + "-" + userData.user.name,
        };
        setTypedMessage("");
        setMessages((prevChat) => [...prevChat, messageObject]);

        const whatsAppResponse = await sendWhatsAppMediaMessage(
          typedMessage,
          currentSender.phone,
          url,
          adminUser?._id,
          currentConversation?.sender._id,
          type,
          adminUser?.name
        );

        console.log({ whatsAppResponse });
      } else {
        setTypedMessage("");

        toast.warn(
          "This conversation is assigned to someone else you can not send a message here"
        );
      }
    }
  };
  const handleUpdateConversationStatus = async () => {
    // console.log({ modalType });
    // dispatch({ type: "SET_LOADING", payload: true });
    if (currentConversation) {
      setIsModalDisabled(true);
      switch (modalType) {
        case "close":
          const response = await closeConversation(currentConversation?._id);
          if (response) {
            setAllConversation((prevData) =>
              prevData.filter((e) => e._id !== response.conversation._id)
            );
            setOpen(false);
            toast.success("Conversation closed");
            setIsModalDisabled(false);
          } else {
          }
          break;
        case "removeSpam":
          const removeSpamResponse = await removeFromSpam(
            currentConversation?._id
          );
          if (removeSpamResponse) {
            setAllConversation((prevData) =>
              prevData.filter(
                (e) => e._id !== removeSpamResponse.conversation._id
              )
            );
            setOpen(false);
            toast.success("Conversation removed from spam");
            setIsModalDisabled(false);
            setValue("Assigned");
          } else {
          }
          break;
        case "reopen":
          const reopenResponse = await reopenConversation(
            currentConversation?._id
          );
          if (reopenResponse) {
            setAllConversation((prevData) =>
              prevData.filter((e) => e._id !== reopenResponse.conversation._id)
            );
            setOpen(false);
            setIsModalDisabled(false);
            setValue("Assigned");
            toast.success("Conversation reopened");
          } else {
          }
          break;
        case "spam":
          const spamResponse = await spamConversation(currentConversation?._id);
          if (spamResponse) {
            // console.log({ spamResponse });
            setAllConversation((prevData) =>
              prevData.filter((e) => e._id !== spamResponse.conversation._id)
            );
            setOpen(false);
            setIsModalDisabled(false);
            toast.success("Conversation marked as spam");
          } else {
          }
          break;
        default:
          break;
      }
    } else {
      toast.warn("Please select a conversation first");
      setOpen(false);
    }
  };
  const addSubject = async () => {
    // console.log("adding...", { subject });
    setIsModalDisabled(true);
    const response = await addConversationSubject(
      currentConversation?._id,
      subject
    );
    if (response) {
      // console.log({ response });
      setAllConversation((prevChat) =>
        prevChat.map((e) =>
          // eslint-disable-next-line
          e._id == response?.conversation?._id
            ? {
                ...e,
                subject: response?.conversation?.subject,
              }
            : e
        )
      );
      dispatch({
        type: "SET_CURRENT_CONVERSATION",
        payload: response?.conversation,
      });
      setOpenSubjectModal(false);
      toast.success("Subject added");
      setIsModalDisabled(false);
    } else {
      setIsModalDisabled(false);
      toast.error("Error while adding subject");
    }
  };

  const fetchDbConversations = async () => {
    let response = null;
    if (role === USER_TYPE.COMPANY) {
      // console.log({ companyId: userData?.user?._id, role });
      if (type === "USER_PROFILE") {
        // console.log({ employeeData });
        response = await getConversationsFromDb(
          userData?.user?._id,
          "assigned",
          employeeData._id
        );
      } else {
        response = await getConversationsFromDb(userData?.user?._id, type);
      }
    } else {
      // console.log({ companyId: userData?.user?.company, role });

      response = await getConversationsFromDb(
        userData?.user?.company,
        type,
        userData?.user?._id
      );
    }
    if (response) {
      setAllConversation(response.conversations);
    }
  };
  return (
    <Grid container className={classes.mainBox}>
      <Grid
        item
        xs={12}
        md={3}
        className={!showList ? classes.hideOnSm : classes.conversationsColumn}
        style={
          type === NAVIGATION_TYPE.ADD_A_USER
            ? { display: "none" }
            : {
                backgroundColor: "white",
              }
        }
      >
        {type === "topics" ? (
          <AllTopicComponent
            type={props.type}
            allGroups={allGroups}
            setAllGroups={setAllGroups}
            isSelected={isSelected}
            setIsSelected={setIsSelected}
            setShowList={setShowList}
            selectedPhone={selectedPhone}
            setSelectedPhone={setSelectedPhone}
            showList={showList}
            showHead={showHead}
            setShowHead={setShowHead}
            employeeData={employeeData}
          />
        ) : (
          <AllConversationsComponent
            isGrouping={isGrouping}
            setIsGrouping={setIsGrouping}
            setShowList={setShowList}
            type={props.type}
            allConversation={allConversation}
            isSelected={isSelected}
            setIsSelected={setIsSelected}
            selectedPhone={selectedPhone}
            setSelectedPhone={setSelectedPhone}
            // setSortBy={setSortBy}
            // sortBy={sortBy}
            employeeData={employeeData}
            showList={showList}
            showHead={showHead}
            setShowHead={setShowHead}
            searchedItem={searchedItem}
            setSearchedItem={setSearchedItem}
          />
        )}
      </Grid>
      <Grid
        item
        xs={12}
        md={type === NAVIGATION_TYPE.ADD_A_USER ? 12 : 9}
        className={
          showList && type !== NAVIGATION_TYPE.ADD_A_USER
            ? classes.hideOnSm
            : classes.messagesColumn
        }
        style={type === NAVIGATION_TYPE.ADD_A_USER ? { padding: 0 } : {}}
      >
        {type === NAVIGATION_TYPE.ADD_A_USER ? (
          <>
            <UserDetails
              type={props.value}
              employeeData={props.employeeData}
              showProfile={props.showProfile}
              setShowList={setShowList}
              setType={props.setType}
              setValue={props.setValue}
              setShowHead={setShowHead}
            />
          </>
        ) : (
          <>
            {" "}
            <InboxHeader
              setShowList={setShowList}
              type={type}
              setModalOpen={setOpen}
              setModalType={setModalType}
              setOpenSubjectModal={setOpenSubjectModal}
              setIsGrouping={setIsGrouping}
              isGrouping={isGrouping}
              setIsSelectGroupModalOpen={setIsSelectGroupModalOpen}
              setIsCreateTopicModalOpen={setIsCreateTopicModalOpen}
              groups={allGroups}
              setAllConversation={setAllConversation}
              value={value}
              setValue={setValue}
            />
            <InboxBody
              type={type}
              doesConversationExist={doesConversationExist}
              typedMessage={typedMessage}
              setTypedMessage={setTypedMessage}
              handleSendMessage={handleSendMessage}
              handleSendMediaMessage={handleSendMediaMessage}
              setAllConversation={setAllConversation}
              isMediaLoading={isMediaLoading}
              populateMessages={populateMessages}
              value={value}
              setValue={setValue}
            />
          </>
        )}
      </Grid>

      <ConfirmationModal
        open={open}
        setOpen={setOpen}
        modalType={modalType}
        handleUpdateConversationStatus={handleUpdateConversationStatus}
        isModalDisabled={isModalDisabled}
      />
      <AddSubjectModal
        open={openSubjectModal}
        setOpen={setOpenSubjectModal}
        update={addSubject}
        setSubject={setSubject}
        isModalDisabled={isModalDisabled}
      />
      <SelectGroupModal
        open={isSelectGroupModalOpen}
        setOpen={setIsSelectGroupModalOpen}
        groups={allGroups}
        isModalDisabled={isModalDisabled}
      />
      <CreateTopicModal
        open={isCreateTopicModalOpen}
        setOpen={setIsCreateTopicModalOpen}
        allConversation={allConversation}
        isModalDisabled={isModalDisabled}
      />
    </Grid>
  );
}

export default ChatWindow;
