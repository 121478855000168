import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addConversationToGroup } from "../../apiCalls/conversationApi";
import SearchBarComponent from "../chatComponenets/SearchBarComponent";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      //   width: "25ch",
    },
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
  },
  submitYes: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "white",
    color: "black",
    paddingLeft: 20,
    paddingRight: 20,
    marginLeft: 20,
    border: "2px solid #000",
  },
  submitNo: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#FF2E56",
    color: "white",
    paddingLeft: 20,
    paddingRight: 20,
    marginRight: 20,
  },
  buttonDiv: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  formDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  TextFieldStyle: {
    width: "100%",
  },
  searchBar: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#F1F1F1",
    marginTop: 10,
    marginBottom: 10,
    padding: 5,
    borderRadius: 10,
  },
}));
function SelectGroupModal(props) {
  const classes = useStyles();

  const [value, setValue] = useState({});
  const [searchedItem, setSearchedItem] = useState("");
  const [filteredTopics, setFilteredTopics] = useState([]);

  const [IsButtonDisabled, setIsButtonDisabled] = useState(false);

  const currentConversation = useSelector(
    (state) => state.data.currentConversation
  );
  // useEffect(() => {
  //   console.log({ convoInModal: currentConversation });
  // }, [currentConversation]);

  useEffect(() => {
    if (props.groups) {
      setFilteredTopics(props.groups);
    }
  }, [props]);
  useEffect(() => {
    if (searchedItem === "") {
      setFilteredTopics(props.groups);
    } else {
      setFilteredTopics((prevData) =>
        prevData.filter((e) =>
          e.name.toLowerCase().includes(searchedItem.toLowerCase())
        )
      );
    }
    // eslint-disable-next-line
  }, [searchedItem]);
  const handleChange = (event) => {
    // console.log({ event });
    setValue(event.target.value);
  };

  const addToGroup = async () => {
    setIsButtonDisabled(true);
    console.log({ currentConversation, value });
    let alreadyExists = false;
    props.groups.forEach((element) => {
      if (element._id === value) {
        console.log({ element });
        if (element.conversations.includes(currentConversation._id)) {
          console.log("it already exists");
          props.setOpen(false);
          toast.warn("Conversation is already in the group");
          setIsButtonDisabled(false);
          alreadyExists = true;
        }
      }
    });
    if (!alreadyExists) {
      const response = await addConversationToGroup(
        currentConversation._id,
        value
      );
      if (response) {
        // console.log({ response });
        props.setOpen(false);
        toast.success("Conversation added to group");
        setIsButtonDisabled(false);
      }
    }
  };

  return (
    <div>
      <Modal
        open={props.open}
        onClose={() => {
          props.setOpen(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          style={{
            top: "50%",
            left: "50%",
            transform: `translate(-${50}%, -${50}%)`,
          }}
          className={classes.paper}
        >
          <h4 id="simple-modal-title" style={{ textAlign: "center" }}>
            Select a Topic
          </h4>
          <SearchBarComponent
            searchedItem={searchedItem}
            setSearchedItem={setSearchedItem}
            customStyle={classes.searchBar}
            placeHolder="Search Topics"
          />

          <FormControl component="fieldset">
            <FormLabel component="legend">Topics</FormLabel>

            <RadioGroup value={value} onChange={handleChange}>
              {filteredTopics.map((e, index) => (
                <FormControlLabel
                  value={e._id}
                  control={<Radio />}
                  label={e.name}
                  key={index}
                />
              ))}
            </RadioGroup>
          </FormControl>

          <div className={classes.buttonDiv}>
            <Button
              className={classes.submitNo}
              onClick={() => {
                props.setOpen(false);
              }}
            >
              cancel
            </Button>
            <Button
              variant="contained"
              className={classes.submitYes}
              onClick={addToGroup}
              disabled={IsButtonDisabled}
            >
              Add to Topic
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default SelectGroupModal;
