import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import PointsListItem from "../../components/setupPageComponents/PointsListItem";
import MenuIcon from "@material-ui/icons/Menu";
import { useSelector } from "react-redux";
import { useWindowDims } from "../../utils/useDimensions";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    overflowY: "scroll !important",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      borderRadius: "5px",
    },
    scrollBehavior: "smooth !important",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FF2D55",
  },
  headingContainer: {
    padding: 40,
    display: "flex",
    flexDirection: "column",
  },
  headingText: {
    fontSize: 48,
    fontWeight: 700,
    fontFamily: "inter,Arial,sans-serif",
    color: "white",
  },
  descriptionText: {
    fontSize: 16,
    fontWeight: 400,
    fontFamily: "inter,Arial,sans-serif",
    color: "white",
    maxWidth: "60%",
    alignSelf: "center",
    textAlign: "center",
  },
  pointsList: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "white",
    width: "80%",
    alignSelf: "center",
    padding: 10,
    borderRadius: 25,
    marginBottom: 20,
    border: "3px solid #686868",
  },

  "@media (max-width: 640px)": {
    headingContainer: {
      paddingTop: 0,
      padding: 10,
    },
    headingText: {
      textAlign: "left",
      lineHeight: 1,
    },
    descriptionText: {
      maxWidth: "90%",
      textAlign: "left",
      alignSelf: "flex-start",
      marginTop: 10,
    },
    pointsList: {
      width: "90%",
    },
  },
}));

function GettingStarted(props) {
  const classes = useStyles();

  const { height } = useWindowDims();
  const userData = useSelector((state) => state.auth.userData);

  return (
    <div className={classes.mainContainer} style={{ height }}>
      <MenuIcon
        className="back-button"
        onClick={() => {
          console.log("back");
          props.setShowList(true);
          props.setShowHead(true);
          // props.setType("assigned");
        }}
        style={{
          alignSelf: "baseline",
          fontSize: 30,
          margin: 10,
          color: "white",
        }}
      />
      <div className={classes.headingContainer}>
        <Typography className={classes.headingText}>What to do next</Typography>
        <Typography className={classes.descriptionText}>
          Now that you’ve signed up, you need to follow these simple steps to
          start receiving feedback reports from the public
        </Typography>
      </div>
      <div className={classes.pointsList}>
        <PointsListItem
          heading="Customize & print Your Signs"
          details="You need to print the ReportAnon signs asking people for feedback on
          your service. These signs should to be placed in prominent places so
          that people can easily scan the QR code & start making reports."
          link="Click here to Customize & Print Your Signs"
          redirect={() => {
            props.setValue("Your sign");
          }}
        />
        <PointsListItem
          heading="Copy the ReportAnon Link"
          details="Next you should copy the ReportAnon digital link & place on your website & social media channels. This allows people to send feedback when they visit these channels."
          whatsAppLink={
            "www.reportanon.com/" + userData?.user?.message_routing_id
          }
        />
        <PointsListItem
          heading="
          Add Your Colleagues"
          details="Add your colleagues so you can all view feedback reports & assign them to the relevant person. "
          link="Click here to add colleagues"
          redirect={() => {
            props.setValue("Add a user");
          }}
        />
        <PointsListItem
          heading="
          Start Answering your Customers"
          details="You're all set! Now you can start receiving feedback & answering your customers. "
          link="Click here to go to your inbox"
          redirect={() => {
            props.setValue("Assigned");
          }}
        />
      </div>
    </div>
  );
}

export default GettingStarted;
