// eslint-disable-next-line
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import "react-toastify/dist/ReactToastify.css";
import "./registrationComponentStyles.css";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Typography } from "@material-ui/core";

import RegistrationTagline from "../../components/miscComponents/RegistrationTagline";
import redLogo from "../../assets/ReportAnonLogoRed.png";
import background from "../../assets/registerBackground.png";
import { toast } from "react-toastify";
import {
  companyRegistration,
  loginApi,
} from "../../apiCalls/AuthenticationApi";
import { useDispatch } from "react-redux";
import { USER_TYPE } from "../../appConstants/constants";
import { sendVerificationCode } from "../../apiCalls/companyApi";
import { createCustomer } from "../../apiCalls/stripeSubscriptionsApi";
import { useWindowDims } from "../../utils/useDimensions";
import CustomLoginTextField from "../../components/loginComponents/CustomLoginTextField";

//icons
import PersonIcon from "@material-ui/icons/Person";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import BusinessIcon from "@material-ui/icons/Business";
import EmailIcon from "@material-ui/icons/Email";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import StreetviewIcon from "@material-ui/icons/Streetview";
import FlagIcon from "@material-ui/icons/Flag";

const useStyles = makeStyles((theme) => ({
  hideOnSm: { [theme.breakpoints.down("sm")]: { display: "none" } },
  mainContainer: {
    backgroundImage: `url(${background})`,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowY: "scroll !important",
    overflowX: "hidden",
    // maxHeight: "85vh",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      borderRadius: "5px",
    },
    scrollBehavior: "smooth !important",
  },
  loginContainer: {
    backgroundColor: "white",
    boxShadow: "rgb(0 0 0 / 9%) 0px 0.5rem 1rem !important",
    borderRadius: 10,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
    paddingBottom: 10,
    maxWidth: "80vh",
    marginTop: 20,
    // height: "60vh",
    [theme.breakpoints.down("sm")]: { width: "90%" },
    border: "1px solid #EAEDF2",
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  formContainer: {
    display: "flex",
    flexDirection: "row",
  },
  "@media (max-width: 500px)": {
    formContainer: {
      flexDirection: "column",
    },
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    padding: 10,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  registrationText: {
    fontSize: 36,
    fontWeight: "bold",
    color: "#333333",
  },
  containerHeadingText: {
    fontSize: 26,
    fontWeight: "700",
    color: "#333333",
    marginTop: 20,
  },
  imgSize: {
    width: 215,
    marginLeft: 5,
  },
  "@media (max-width: 440px)": {
    registrationText: {
      fontSize: 26,
    },
    imgSize: {
      width: 180,
    },
  },
}));

function CompanyRegistration(props) {
  const classes = useStyles();
  const { height, width } = useWindowDims();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [phone, setPhone] = useState("");
  const [streetCity, setStreetCity] = useState("");
  const [stateCountry, setStateCountry] = useState("Ireland");

  const [isUpperCase, setIsUpperCase] = useState(false);
  const [isLowerCase, setIsLowerCase] = useState(false);
  const [hasSpecialChars, setHasSpecialChars] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();

  function hasNumber(str) {
    if (/\d/.test(str)) {
      setHasSpecialChars(true);
    }
  }
  const handleSignUp = async () => {
    // console.log({ firstName, lastName, companyName, email, password, phone });
    // console.log({ address });
    const address = {
      streetAddress: streetCity,
      state: stateCountry,
    };

    if (validateEmail(email)) {
      if (
        isLowerCase &&
        isUpperCase &&
        hasSpecialChars &&
        password.length >= 8
      ) {
        const response = await companyRegistration(
          email.toLowerCase(),
          password,
          companyName,
          firstName,
          lastName,
          phone,
          address
        );
        if (response.error) {
          toast.error(
            response?.error?.response?.data?.message || "Something went wrong"
          );
        } else {
          // console.log({ response });
          await createCustomer(response.company.email);
          const verificationResponse = await sendVerificationCode(
            response.company._id
          );
          if (verificationResponse) {
            // console.log({ verificationResponse });
            const loginResponse = await loginApi(email, password);
            if (loginResponse) {
              // console.log({ loginResponse });
              dispatch({
                type: "USER_LOGIN_SUCCESS",
                payload: loginResponse,
              });
              dispatch({ type: "SET_ROLE", payload: USER_TYPE.COMPANY });
              props.history.push("/");
            }
          } else {
            toast.error(
              verificationResponse?.error?.response?.data?.message ||
                "Something went wrong"
            );
          }
        }
      } else {
        toast.error(
          "The password should be at least 8 characters with 1 upper case "
        );
      }
    } else {
      toast.error("Invalid Email");
    }
  };
  // eslint-disable-next-line
  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  return (
    <div className={classes.mainContainer} style={{ height, width }}>
      <CssBaseline />

      <div style={{ marginTop: 100 }}>
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography className={classes.registrationText}>
            Welcome to
          </Typography>
          <img
            src={redLogo}
            alt="https://reportanon.com/"
            className={classes.imgSize}
          />
        </div>
        <RegistrationTagline />
      </div>
      <div className={classes.loginContainer}>
        <Typography className={classes.containerHeadingText}>
          Try for free
        </Typography>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className={classes.formContainer}>
            <CustomLoginTextField
              label="First name"
              icon={<PersonIcon style={{ color: "#ABACB1" }} />}
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
            />
            <CustomLoginTextField
              icon={<SupervisorAccountIcon style={{ color: "#ABACB1" }} />}
              label="Last name"
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
              }}
            />
          </div>
          <div className={classes.formContainer}>
            <CustomLoginTextField
              icon={<BusinessIcon style={{ color: "#ABACB1" }} />}
              label="Company name"
              value={companyName}
              onChange={(e) => {
                setCompanyName(e.target.value);
              }}
            />
            <CustomLoginTextField
              label="Phone"
              phone
              value={phone}
              onChange={(phone) => setPhone(phone)}
            />
          </div>
          <div className={classes.formContainer}>
            <CustomLoginTextField
              icon={<StreetviewIcon style={{ color: "#ABACB1" }} />}
              label="Street/city"
              value={streetCity}
              onChange={(e) => {
                setStreetCity(e.target.value);
              }}
            />

            <CustomLoginTextField
              icon={<FlagIcon style={{ color: "#ABACB1" }} />}
              label="State/Country"
              value={stateCountry}
              onChange={(val) => setStateCountry(val)}
              addCountryDropdown
            />
          </div>
          <div className={classes.formContainer}>
            <CustomLoginTextField
              icon={<EmailIcon style={{ color: "#ABACB1" }} />}
              label="Email address"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />

            <CustomLoginTextField
              icon={
                <VpnKeyIcon
                  style={{ color: "#ABACB1", transform: `rotate(45deg)` }}
                />
              }
              label="Password"
              value={password}
              onChange={(e) => {
                setIsUpperCase(false);
                setIsLowerCase(false);
                setHasSpecialChars(false);

                const pass = Array.from(e.target.value);
                // eslint-disable-next-line
                var regex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

                if (regex.test(e.target.value)) {
                  setHasSpecialChars(true);
                }
                hasNumber(e.target.value);
                pass.forEach((element) => {
                  if (element.toUpperCase() === element) {
                    setIsUpperCase(true);
                  } else {
                    setIsLowerCase(true);
                  }
                });
                setPassword(e.target.value);
              }}
              password
            />
          </div>
        </div>

        <div style={{ padding: 20 }}>
          <Button
            variant="contained"
            className="gradient-button"
            onClick={handleSignUp}
          >
            Try for free
          </Button>
        </div>
      </div>
      <Grid
        style={{
          marginTop: 10,
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
        container
      >
        <Grid item style={{ margin: 20 }}>
          <Link href="/login" style={{ fontSize: 14, color: "black" }}>
            {"Already have an account? Sign In"}
          </Link>
        </Grid>
      </Grid>
    </div>
  );
}

export default CompanyRegistration;
