import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER,
});
export const createCustomer = async (companyEmail) => {
  console.log({ companyEmail });
  try {
    const response = await api.post(`/stripe/create-customer/`, {
      email: companyEmail,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return false;
  }
};
export const createSubscription = async (
  paymentMethodId,
  customerId,
  price,
  planFrequency,
  productId,
  companyId,
  subscriptionDetails
) => {
  console.log({
    paymentMethodId,
    customerId,
    price,
    planFrequency,
    productId,
    companyId,
    subscriptionDetails,
  });
  try {
    const response = await api.post(
      `/stripe/create-subscription/${companyId}`,
      {
        paymentMethodId,
        customerId,
        price,
        planFrequency,
        productId,
        subscriptionDetails,
      }
    );
    return response.data;
  } catch (error) {
    console.log({ error });
    return false;
  }
};
export const cancelSubscription = async (subscriptionId, companyId) => {
  try {
    const response = await api.post(
      `/stripe/cancel-subscription/${companyId}`,
      {
        subscriptionId,
      }
    );
    return response.data;
  } catch (error) {
    console.log({ error });
    return false;
  }
};
export const pauseSubscription = async (subscriptionId, companyId) => {
  try {
    const response = await api.post(`/stripe/pause-subscription/${companyId}`, {
      subscriptionId,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return false;
  }
};
export const resumeSubscription = async (subscriptionId, companyId) => {
  try {
    const response = await api.post(
      `/stripe/resume-subscription/${companyId}`,
      {
        subscriptionId,
      }
    );
    return response.data;
  } catch (error) {
    console.log({ error });
    return false;
  }
};
export const retrieveInvoice = async (customerId) => {
  try {
    const response = await api.post(`/stripe/retrieve-invoice`, {
      customerId,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return false;
  }
};
