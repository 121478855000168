import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { USER_TYPE } from "../../appConstants/constants";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { CountryDropdown } from "react-country-region-selector";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    // backgroundColor: "red",
  },
  labelContainer: {
    minWidth: "20%",
    display: "flex",
    alignItems: "baseline",
    padding: 16,
  },
  inputField: {
    width: "50%",
    border: "2px solid #F1F1F1",
    padding: "8px 12px",
    fontFamily: "inter,Arial,sans-serif",
    borderRadius: 5,
    "&:focus": {
      border: "2px solid #F1F1F1",
    },
  },
  "@media (max-width: 1000px)": {
    mainContainer: {
      justifyContent: "space-between",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    labelContainer: {
      minWidth: "10%",
      padding: 0,
      paddingTop: 16,
      paddingBottom: 16,
    },
    inputField: {
      width: "90%",
    },
  },
}));
function CustomInputField(props) {
  const classes = useStyles();
  // console.log({ x: props.secondLabel });
  return (
    <div className={classes.mainContainer}>
      <div className={classes.labelContainer}>
        <Typography
          style={{
            fontSize: 15,
            alignSelf: "baseline",
            fontFamily: "inter,Arial,sans-serif",
          }}
        >
          {props.label}
        </Typography>
      </div>
      {props.select ? (
        <>
          <Select
            value={props.value}
            onChange={props.onChange}
            displayEmpty
            disableUnderline
            className={classes.inputField}
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value={USER_TYPE.COMPANY}>
              Admin (Access to all conversations)
            </MenuItem>
            <MenuItem value={USER_TYPE.USER}>
              Agent (Access to assigned conversations)
            </MenuItem>
          </Select>
        </>
      ) : props.customSelect ? (
        props.customSelect
      ) : props.addButton ? (
        props.addButton
      ) : props.addCountryDropdown ? (
        <CountryDropdown
          value={props.value}
          onChange={props.onChange}
          className={classes.inputField}
          showDefaultOption={false}
          // valueType="short"
        />
      ) : props.secondLabel ? (
        <Typography
          style={{
            fontSize: 15,
            fontWeight: "bold",
            fontFamily: "inter,Arial,sans-serif",
          }}
        >
          {/* {isNaN(props.secondLabel) ? "Contact Sales" : "€" + props.secondLabel} */}
          €{props.secondLabel}
        </Typography>
      ) : (
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <input
            type="text"
            id="PreventChromeAutocomplete"
            name="hidden"
            autoComplete="false"
            className={classes.inputField}
            onChange={props.onChange}
            value={props.value}
          />
          {props.addHelperText && (
            <Typography
              style={{
                fontSize: 15,
                fontFamily: "inter,Arial,sans-serif",
                alignSelf: "baseline",
                color: "#888",
              }}
            >
              Please enter your VAT number here. If you leave this field empty
              we will add 23% VAT to your invoice.
            </Typography>
          )}
        </div>
      )}
    </div>
  );
}

export default CustomInputField;
