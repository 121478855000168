import { combineReducers } from "redux";
import authReducer from "./auth.reducer";
import dataReducer from "./data.reducer";
import subscriptionReducer from "./subscription.reducer";

const rootReducer = combineReducers({
  auth: authReducer,
  data: dataReducer,
  subs: subscriptionReducer,
});

export default rootReducer;
