import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import CustomInputField from "../userDetails/CustomInputField";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import {
  GROW_PLAN,
  MONTHLY_PLAN,
  SCALE_PLAN,
  START_PLAN,
  YEARLY_PLAN,
} from "../../appConstants/subscriptionTiers";
import { useDispatch, useSelector } from "react-redux";
import AddPaymentModal from "../modalComponents/AddPaymentModal";
import StripeCardInputField from "./StripeCardInputField";
import { checkIfVatCountry } from "../../utils/helperFunctions";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    marginBottom: 100,
    padding: 10,
    backgroundColor: "white",
    borderRadius: 10,
    paddingTop: 20,
    paddingBottom: 20,
  },
  multiSelectDiv: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },

  customSelect: {
    width: "40%",
    border: "1px solid #d3d4d5",
    padding: "8px 12px",
    marginRight: 30,
    fontFamily: "Poppins,sans-serif",
  },

  inputField: {
    width: "40%",
    border: "1px solid #d3d4d5",
    padding: "8px 12px",
    marginRight: 30,
    fontFamily: "Poppins,sans-serif",
  },
  "@media (max-width: 640px)": {
    customSelect: {
      width: "90%",
      marginBottom: 10,
    },

    multiSelectDiv: {
      flexDirection: "column",
    },
    inputField: {
      width: "90%",
      marginBottom: 10,
    },
  },
}));
const allPlans = [START_PLAN, GROW_PLAN, SCALE_PLAN];

const PaymentForm = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const { plan, setSelectedPlan } = props;
  const [currentPlan, setCurrentPlan] = useState("");
  const [planFrequency, setPlanFrequency] = useState(MONTHLY_PLAN);

  const [country, setCountry] = useState("Ireland");
  const [companyName, setCompanyName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [invoiceEmail, setInvoiceEmail] = useState("");
  const [vatNumber, setVatNumber] = useState("");
  const [isVatApplied, setIsVatApplied] = useState(false);

  const [addingPayment, setAddingPayment] = useState(false);

  const currentBill = useSelector((state) => state.subs.currentBill);
  const userData = useSelector((state) => state.auth.userData);

  const dispatch = useDispatch();

  useEffect(() => {
    if (userData) {
      setCompanyName(userData.user.name);
      setInvoiceEmail(userData.user.email);
    }
  }, [userData]);
  useEffect(() => {
    // console.log({ plan });
    setCurrentPlan(plan.id);
  }, [plan]);

  useEffect(() => {
    dispatch({ type: "SET_PLAN_FREQUENCY", payload: planFrequency });

    // eslint-disable-next-line
  }, [planFrequency]);

  useEffect(() => {
    setIsVatApplied(checkIfVatCountry(country));
    // console.log({ country });
  }, [country]);

  const handlePlanChange = (event) => {
    // console.log({ changedPlan: event.target.value });
    setCurrentPlan(event.target.value);
    allPlans.forEach((e) => {
      if (e.id === event.target.value) {
        setSelectedPlan(e);
      }
    });
  };

  const calculateRealTimeTotalCost = (bill) => {
    console.log({ bill });
    return planFrequency === YEARLY_PLAN ? bill * 12 : bill;
  };
  return (
    <div className={classes.mainContainer} ref={ref}>
      <CustomInputField
        label="Your plan"
        customSelect={
          <div className={classes.multiSelectDiv}>
            <Select
              value={currentPlan}
              onChange={handlePlanChange}
              displayEmpty
              disableUnderline
              className={classes.customSelect}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value={START_PLAN.id}>Coffee</MenuItem>
              <MenuItem value={GROW_PLAN.id}>Restaurant</MenuItem>
              <MenuItem value={SCALE_PLAN.id}>Hotel</MenuItem>
            </Select>
            <Select
              value={planFrequency}
              onChange={(event) => {
                setPlanFrequency(event.target.value);
              }}
              disableUnderline
              className={classes.customSelect}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value={MONTHLY_PLAN}>Monthly</MenuItem>
              <MenuItem value={YEARLY_PLAN}>Yearly</MenuItem>
            </Select>
          </div>
        }
      />

      <CustomInputField
        label="Address"
        customSelect={
          <div className={classes.multiSelectDiv}>
            <input
              type="text"
              id="PreventChromeAutocomplete"
              name="hidden"
              autoComplete="false"
              className={classes.inputField}
              placeholder="Address"
              onChange={(event) => {
                setAddress(event.target.value);
              }}
              value={address}
            />
            <input
              type="text"
              id="PreventChromeAutocomplete"
              autoComplete="false"
              className={classes.inputField}
              placeholder="City"
              onChange={(event) => {
                setCity(event.target.value);
              }}
              value={city}
            />
          </div>
        }
      />
      <CustomInputField
        label="Country"
        value={country}
        onChange={(val) => setCountry(val)}
        addCountryDropdown
      />
      {isVatApplied && (
        <CustomInputField
          label="VAT number"
          value={vatNumber}
          onChange={(e) => {
            setVatNumber(e.target.value);
          }}
          addHelperText
        />
      )}

      <CustomInputField
        label="invoice Email"
        value={invoiceEmail}
        onChange={(e) => {
          setInvoiceEmail(e.target.value);
        }}
      />
      <CustomInputField
        label="Company name"
        value={companyName}
        onChange={(e) => {
          setCompanyName(e.target.value);
        }}
      />
      <CustomInputField
        label={
          planFrequency === YEARLY_PLAN ? "Billed per year" : "Billed per month"
        }
        secondLabel={
          isVatApplied
            ? calculateRealTimeTotalCost(
                currentBill + currentBill * 0.23
              ).toFixed(2)
            : calculateRealTimeTotalCost(currentBill).toFixed(2)
        }
      />
      <StripeCardInputField
        country={country}
        companyName={companyName}
        address={address}
        city={city}
        invoiceEmail={invoiceEmail}
        plan={plan}
        vatNumber={isVatApplied ? vatNumber : null}
      />
      <AddPaymentModal open={addingPayment} setOpen={setAddingPayment} />
    </div>
  );
});

export default PaymentForm;
