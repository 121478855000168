import React from "react";
import { css } from "@emotion/react";
import HashLoader from "react-spinners/HashLoader";

const override = css`
  display: block;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0);
`;

function LoadingComponent() {
  //   const [loading, setLoading] = useState(true);
  //   const [color, setColor] = useState("#ffffff");
  return (
    <div>
      <HashLoader color="black" loading={true} css={override} size={150} />
    </div>
  );
}

export default LoadingComponent;
