import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import React, { useEffect, useState } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ConversationCards from "./ConversationCards";
import Button from "@material-ui/core/Button";
import { getConversationsInGroup } from "../../../apiCalls/conversationApi";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "&:before": {
      content: "unset",
    },
    "&.MuiAccordion-root": {
      boxShadow: "unset",
    },
    "&.MuiAccordionSummary-root.Mui-expanded": {
      minHeight: "unset",
    },
    "&.MuiAccordionSummary-root.Mui-expanded .MuiAccordionSummary-content": {
      margin: 0,
    },
    "&. MuiAccordionSummary-content": {
      margin: 0,
    },
  },
  heading: {
    fontSize: 16,
    fontWeight: 600,
    textAlign: "left",
    marginLeft: 5,
  },
  subHeading: {
    fontSize: "1rem",
  },
}));
function GroupConversations(props) {
  const {
    data,
    isSelected,
    setIsSelected,
    setShowList,
    setSelectedPhone,
    selectedPhone,
  } = props;

  const [conversations, setConversations] = useState([]);
  const [inFocus, setInFocus] = useState(0);

  // const { allConversations, messages, isSelected, setIsSelected, setShowList } =
  //   props;
  useEffect(() => {
    if (data) {
      fetchConversations(data._id);
    }
  }, [data]);
  // useEffect(() => {
  //   console.log({ cg: conversations });
  // }, [conversations]);
  const fetchConversations = async (id) => {
    const response = await getConversationsInGroup(id);
    if (response) {
      setConversations(response);
    }
  };
  // const [groupConversation, setGroupConversation] = useState([]);
  const classes = useStyles();

  return (
    <div>
      <Accordion className={classes.root}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={classes.root}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>{data.name} </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ marginTop: 10, width: "100%" }}>
            {conversations?.map((element, index) => (
              <ConversationCards
                setIsSelected={setIsSelected}
                isSelected={isSelected}
                selected={index === isSelected ? true : false}
                index={index}
                data={element}
                key={index}
                setShowList={setShowList}
                fromGroups
                selectedPhone={selectedPhone}
                setSelectedPhone={setSelectedPhone}
                inFocus={index === inFocus ? true : false}
                setInFocus={setInFocus}
              />
            ))}
            <Button
              variant="contained"
              style={{
                backgroundColor: "red",
                color: "white",
                paddingLeft: 20,
                paddingRight: 20,
                marginTop: 20,
                alignSelf: "center",
              }}
              onClick={() => {
                props.removeGroup(data._id);
              }}
            >
              Remove topic
            </Button>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default GroupConversations;
