import React, { useEffect } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
  Font,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { useSelector } from "react-redux";
import Montserrat from "../../fonts/Montserrat-Bold.otf";
import Kollektif from "../../fonts/Kollektif-Bold.ttf";

import { useWindowDims } from "../../utils/useDimensions";

// Register font
Font.register({ family: "Montserrat", src: Montserrat });
Font.register({ family: "Kollektif", src: Kollektif });

// Create styles
const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#F76B5C",
    // overflow: "auto",
  },
  view: {
    width: "100%",
    height: "100%",
    padding: 0,
    backgroundColor: "white",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
    top: 0,
    bottom: 0,
    opacity: 0.5,
    backgroundColor: "black",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  divider: {
    width: "100%",
    borderBottom: "2px solid #F1F1F1",
    alignSelf: "center",
    marginTop: 5,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    width: "100%",
    flex: 1,
  },
  content_row: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-evenly",
    marginTop: 5,
    alignItems: "center",
  },
  content_container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    position: "relative",
    alignItems: "center",
  },
  inputField: {
    background: "none",
    border: "none",
    fontSize: 20,
    color: "white",
    textAlign: "center",
    fontFamily: "Kollektif",
    maxWidth: "65%",
    marginRight: 40,
  },

  inputField_details: {
    fontSize: 13,
    color: "white",
    textAlign: "center",
    fontFamily: "Kollektif",
    maxWidth: "70%",
    marginTop: 10,
  },
  inputField_link: {
    fontSize: 13,
    color: "white",
    textAlign: "center",
    fontFamily: "Kollektif",
    width: "100%",
    alignSelf: "center",
    marginTop: 15,
  },
  inputField_link2: {
    fontSize: 10,
    color: "#E6CF00",
    textAlign: "center",
    fontFamily: "Kollektif",
    position: "absolute",
    bottom: 2,
  },
});

const PdfSignDocument = () => {
  const pdfData = useSelector((state) => state.data.pdfData);

  const { width } = useWindowDims();
  useEffect(() => {
    console.log({ pdfData });
  }, [pdfData]);
  const MyDoc = () => (
    <Document>
      <Page size="a7" style={styles.page} wrap={false} orientation="landscape">
        <View
          style={{
            width: "100%",
            height: "100%",
            padding: 10,
            paddingLeft: 20,
            paddingRight: 20,
            backgroundColor: "black",
          }}
        >
          <Image
            style={styles.image}
            src={{
              uri: pdfData.backgroundUrl,
              method: "GET",
              headers: { "Cache-Control": "no-cache" },
              body: "",
            }}
            alt="images"
          />
          <View style={styles.header}>
            <Text style={styles.inputField}>
              {pdfData.companyName.toUpperCase()}
            </Text>
            <Image
              src={{
                uri: pdfData.logoUrl,
                method: "GET",
                headers: { "Cache-Control": "no-cache" },
                body: "",
              }}
              style={{
                width: 65,
                height: 40,
                position: "absolute",
                right: 3,
                top: 0,
                objectFit: "contain",
              }}
            />
          </View>
          <View style={styles.divider}></View>
          <View style={styles.content_container}>
            <View style={styles.content_row}>
              <Text style={styles.inputField_details}>{pdfData.TagLine}</Text>

              <Image
                src={{
                  uri: pdfData.qrCodeLink,
                  method: "GET",
                  headers: { "Cache-Control": "no-cache" },
                  body: "",
                }}
                style={{
                  width: 60,
                  height: 60,
                  marginTop: 5,
                }}
              />
            </View>
            <Text style={styles.inputField_link}>
              or visit wwww.ReportAnon.com/{pdfData.whatsAppLink}
            </Text>
            <Text style={styles.inputField_link2}>
              {" "}
              Anonymity Powered By ReportAnon.com
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {width < 700 ? (
        <PDFDownloadLink
          document={<MyDoc />}
          fileName={"generated_sign.pdf"}
          style={{ color: "white" }}
        >
          {({ blob, url, loading, error }) =>
            loading ? "loading..." : "Ready to download"
          }
        </PDFDownloadLink>
      ) : (
        <PDFViewer style={{ width: "100vw", height: "100vh" }}>
          <MyDoc />
        </PDFViewer>
      )}
    </div>
  );
};

export default PdfSignDocument;
