import { Typography } from "@material-ui/core";
import React from "react";

function BottomMenuButtons(props) {
  return (
    <div
      style={{
        display: "flex",
        padding: 12,
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={props.onClick}
    >
      {props.icon && props.icon}
      <Typography style={{ fontSize: 14, marginLeft: 8 }}>
        {props.text}
      </Typography>
    </div>
  );
}

export default BottomMenuButtons;
