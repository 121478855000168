import React, { useEffect, useState } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { NAVIGATION_TYPE } from "../../../appConstants/constants";
import SearchIcon from "@material-ui/icons/Search";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import "./styles.css";
import SearchBarComponent from "../SearchBarComponent";
const useStyles = makeStyles((theme) => ({
  hideOnSm: { [theme.breakpoints.down("sm")]: { display: "none" } },
  show: {
    [theme.breakpoints.down("sm")]: {
      display: "flex !important",
      flexDirection: "column",
      alignItems: "center",
      padding: 10,
      //   justifyContent: "space-between",
    },
  },
  hide: {
    display: "none",
  },
}));
function ResponsiveAllConversationHeader(props) {
  const { showList, showHead, employeeData, searchedItem, setSearchedItem } =
    props;
  const classes = useStyles();

  const [showSearchBar, setShowSearchBar] = useState(false);
  useEffect(() => {
    // console.log({ props });
  }, [props]);

  const capitalize = (s) => {
    return s && s[0].toUpperCase() + s.slice(1);
  };
  return (
    <div
      style={{
        display: "none",
      }}
      className={showList && !showHead && classes.show}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <MenuIcon
            style={{
              fontSize: 35,
              cursor: "pointer",
            }}
            onClick={() => {
              props.setShowHead(!showHead);
              // console.log("menu");
            }}
          />
          <Typography style={{ fontSize: 16, fontWeight: 600, marginLeft: 20 }}>
            {props.type === NAVIGATION_TYPE.USER_PROFILE
              ? employeeData?.name + "'s messages"
              : capitalize(props.type)}
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <SearchIcon
            style={{
              fontSize: 25,
              cursor: "pointer",
              color: "rgb(116 121 142)",
            }}
            onClick={() => {
              setShowSearchBar(!showSearchBar);
              console.log("searching");
            }}
          />
          <EqualizerIcon
            style={{
              fontSize: 25,
              cursor: "pointer",
              color: "rgb(116 121 142)",
            }}
            className={"icon-rotate"}
            onClick={props.handleSortingMenuClick}
          />
        </div>
      </div>
      {showSearchBar && (
        <div style={{ width: "100%", marginTop: 10, marginBottom: 20 }}>
          <SearchBarComponent
            searchedItem={searchedItem}
            setSearchedItem={setSearchedItem}
          />
        </div>
      )}
    </div>
  );
}

export default ResponsiveAllConversationHeader;
