import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { toast } from "react-toastify";
const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    width: "70%",
    marginBottom: 20,
  },
  inputField: {
    fontSize: 16,
    fontWeight: 500,

    padding: 5,
    width: 380,
  },
  fileCopyContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: 20,
  },
  fileCopyIcon: {
    fontSize: 30,
    color: "red",
    marginLeft: 10,
    cursor: "pointer",
  },
  fileCopyText: {
    fontSize: 16,
    fontWeight: 500,
    textAlign: "left",
    cursor: "pointer",
    color: "black",
    backgroundColor: "lightgray",
    padding: 10,
    borderRadius: 5,
    fontFamily: "inter,Arial,sans-serif",
  },
  contentDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginLeft: 10,
  },
  "@media (max-width: 640px)": {
    fileCopyIcon: {
      marginTop: 10,
    },
    mainContainer: {
      width: "100%",
      flexDirection: "column",
    },
    fileCopyText: {
      maxWidth: "75%",
      wordBreak: "break-word",
    },
    contentDiv: {
      marginTop: 10,
    },
  },
}));
function PointsListItem(props) {
  const classes = useStyles();

  return (
    <div className={classes.mainContainer}>
      <CheckCircleIcon
        style={{
          fontSize: 42,
          color: "red",
        }}
      />
      <div className={classes.contentDiv}>
        <Typography
          style={{
            fontSize: 24,
            fontWeight: 600,
            textAlign: "left",
            fontFamily: "inter,Arial,sans-serif",
          }}
        >
          {props.heading}
        </Typography>
        <Typography
          style={{
            fontSize: 16,
            fontWeight: 300,
            textAlign: "left",
            fontFamily: "inter,Arial,sans-serif",
          }}
        >
          {props.details}
          <Typography
            style={{
              fontSize: 16,
              fontWeight: 500,
              textAlign: "left",
              cursor: "pointer",
              color: "red",
            }}
            onClick={props.redirect}
          >
            {props.link}
          </Typography>
        </Typography>
        {props.whatsAppLink && (
          <div className={classes.fileCopyContainer}>
            <Typography
              className={classes.fileCopyText}
              onClick={props.onClick}
            >
              {props.whatsAppLink}
            </Typography>

            <FileCopyIcon
              className={classes.fileCopyIcon}
              onClick={() => {
                navigator.clipboard.writeText(props.whatsAppLink);
                toast.success("Link copied to clipboard");
              }}
            />
          </div>
        )}
        <div
          style={{
            width: "60%",
            height: 2,
            backgroundColor: "red",
            alignSelf: "center",
            marginTop: 20,
          }}
        ></div>
      </div>
    </div>
  );
}

export default PointsListItem;
