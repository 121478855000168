import { getPreSignedLink, uploadFile } from "../apiCalls/AuthenticationApi";
import { ALL_VAT_COUNTRIES } from "../appConstants/constants";

export const getNumberBefore = (number) => {
  return parseInt(number.toString().split(".")[0], 10);
};
export const getNumberAfter = (number) => {
  const x = parseInt(number.toString().split(".")[1], 10);
  const y = parseInt(x.toString().slice(0, 2), 10);

  // console.log({ x, y });
  return y || x || null;
};
export const getEmployeeData = (id, allEmployees) => {
  return allEmployees?.find((element) => element._id === id);
};
export const checkIfVatCountry = (country) => {
  let isVatApplied = false;
  // console.log({ country });
  ALL_VAT_COUNTRIES.forEach((element) => {
    if (element.toLowerCase() === country.toLowerCase()) {
      isVatApplied = true;
    }
  });
  return isVatApplied;
};
export const uploadFilesToServer = async (file, loading) => {
  loading(true);
  const response = await getPreSignedLink({
    name: file.name,
    type: file.type,
  });
  if (response) {
    // console.log({ response });
    await uploadFile({
      url: response,
      file: file,
      type: file.type,
    });
    const url = response.split("?")[0];
    console.log("Uploaded : ", { url });
    loading(false);
    return url;
  } else {
    console.log("error while generating link...");
    loading(false);
    return false;
  }
};
