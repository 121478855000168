import React, { useEffect } from "react";
import { useParams } from "react-router";
import {
  getCompanyFromCode,
  sendEmailToSuperAdmin,
  sendGenericEmailToSuperAdmin,
} from "../../apiCalls/companyApi";

const WhatsappRedirect = () => {
  const { redirectCode } = useParams();
  useEffect(() => {
    if (redirectCode === "GENERIC_CODE") {
      sendGenericCode();
      window.location.href = `https://api.whatsapp.com/send?phone=12062027131&text=Hi, I would like to send some anonymous feedback (send this message to get started)`;
    } else {
      fetchCompanyFromDb();
    }

    // eslint-disable-next-line
  }, []);
  const sendGenericCode = async () => {
    console.log("sending gen code");
    await sendGenericEmailToSuperAdmin();
  };
  const fetchCompanyFromDb = async () => {
    const response = await getCompanyFromCode(redirectCode.toUpperCase());
    // console.log({ response });
    if (response) {
      await sendEmailToSuperAdmin(response?.company?._id);
      let test = response?.company?.name;
      if (test.includes("&")) {
        test = response?.company?.name.replace("&", "and");
      }
      window.location.href = `https://api.whatsapp.com/send?phone=12062027131&text=Hi, I would like to send anonymous feedback to - ${test} - ${response?.company?.message_routing_id} - (click send to get started)`;
    } else {
      alert("An error occurred please try again later");
    }
  };

  return <div></div>;
};

export default WhatsappRedirect;
