import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER,
});
export const loginApi = async (email, password) => {
  try {
    const response = await api.post(`/user/login`, {
      email,
      password,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return false;
  }
};
export const companyLogin = async (email, password) => {
  try {
    const response = await api.post(`/company/login`, {
      email,
      password,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return false;
  }
};
export const registrationApi = async (
  email,
  password,
  name,
  companyId,
  phone,
  type
) => {
  console.log({ email, password, name, companyId, phone, type });
  try {
    const response = await api.post(`/user/`, {
      email,
      password,
      name,
      company: companyId,
      phone,
      type,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return { error };
  }
};
export const companyRegistration = async (
  email,
  password,
  companyName,
  firstName,
  lastName,
  phone,
  address
) => {
  console.log({
    email,
    password,
    companyName,
    firstName,
    lastName,
    phone,
    address,
  });
  try {
    const response = await api.post(`/company/`, {
      adminFirstName: firstName,
      adminLastName: lastName,
      email,
      password,
      companyName,
      phone,
      address,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return { error };
  }
};
export const sendRegistrationLink = async (
  email,
  role,
  name,
  companyId,
  phone
) => {
  console.log({ email, role, name, companyId, phone });
  try {
    const response = await api.post(`/company/${companyId}/invite`, {
      email,
      name,
      type: role,
      phone,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return false;
  }
};
export const getPreSignedLink = async ({ name, type }) => {
  try {
    const response = await api.post(`/upload/`, { name, type });
    return response?.data?.url;
  } catch (error) {
    console.log(JSON.stringify(error, null, 2));
    return false;
  }
};
export const uploadFile = async ({ url, file, type }) => {
  console.log({
    type,
  });
  return new Promise(async (res, rej) => {
    try {
      const response = await axios({
        method: "PUT",
        data: file,
        url,
        headers: {
          "Content-Type": type,
        },
      });
      if (response.status === 200) return res(true);
      throw new Error();
    } catch (error) {
      console.log(JSON.stringify(error, null, 2));
      return rej(error);
    }
  });
};
export const updateUserProfile = async (email, name) => {
  console.log({ email, name });
  try {
    const response = await api.patch(`/user/`, {
      email,
      name,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return false;
  }
};
export const setUserOnlineStatus = async (userId, onlineStatus) => {
  //   console.log({ companyId, code });
  try {
    const response = await api.post(`/user/${userId}/toggleOnline`, {
      isOnline: onlineStatus,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return false;
  }
};
export const logoutUser = async (userId, type) => {
  console.log({ userId, type });
  try {
    const response = await api.post(`/user/${userId}/logout`, {
      type,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return false;
  }
};
export const sendResetLink = async (email) => {
  // console.log({ email });
  try {
    const response = await api.post(`/user/resetPassword`, {
      email,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return false;
  }
};
export const updatePassword = async (id, password) => {
  console.log({ id, password });
  try {
    const response = await api.post(`/user/updatePassword`, {
      id,
      password,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return false;
  }
};
