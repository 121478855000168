import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import { makeStyles, Typography } from "@material-ui/core";
// import { useWindowDims } from "../../../utils/useDimensions";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
  hideOnSm: { [theme.breakpoints.down("sm")]: { display: "none" } },
  bottomMenuDialog: {
    backgroundColor: "yellow",
    // width: width,
  },
  root: {
    "& .MuiDialog-paper": {
      margin: 0,
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      position: "absolute",
      bottom: 0,
      width: "100%",
      padding: 0,
    },
  },
}));
function BottomMenu(props) {
  const classes = useStyles();

  // const { width } = useWindowDims();
  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      onClose={props.handleClose}
      className={classes.root}
    >
      <DialogContent
        style={{ display: "flex", flexDirection: "column", padding: 0 }}
      >
        <Typography
          style={{
            fontSize: 16,
            paddingTop: 16,
            paddingBottom: 16,
            alignSelf: "center",
            fontWeight: 500,
            // marginLeft: width / 2 - 100,
          }}
        >
          Options
        </Typography>

        {props.options}
      </DialogContent>
    </Dialog>
  );
}

export default BottomMenu;
