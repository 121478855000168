// eslint-disable-next-line
import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { assignConversation } from "../../apiCalls/conversationApi";
import { toast } from "react-toastify";
// import { toast } from "react-toastify";
import { css } from "@emotion/react";
import HashLoader from "react-spinners/HashLoader";

const override = css`
  display: block;
  margin: 0 auto;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      //   width: "25ch",
    },
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
  },
  submitYes: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "white",
    color: "black",
    paddingLeft: 20,
    paddingRight: 20,
    marginLeft: 20,
    border: "2px solid #000",
  },
  submitNo: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#FF2E56",
    color: "white",
    paddingLeft: 20,
    paddingRight: 20,
    marginRight: 20,
  },
  buttonDiv: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  formDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  TextFieldStyle: {
    width: "100%",
  },
}));
function SelectAgentModal(props) {
  const classes = useStyles();

  const [value, setValue] = useState({});
  const [loading, setLoading] = useState(false);
  const currentCompanyEmployees = useSelector(
    (state) => state.data.currentCompanyEmployees
  );
  const userData = useSelector((state) => state.auth.userData);
  const adminUser = useSelector((state) => state.auth.adminUser);

  // useEffect(() => {
  //   console.log({ currentCompanyEmployees });
  // }, [currentCompanyEmployees]);

  const handleChange = (event) => {
    // console.log({ event });
    setValue(event.target.value);
  };
  const handleAssignToAgent = async () => {
    setLoading(true);
    console.log("assigning to : ", { value });
    // console.log("assigning conversation : ", { convo: props.conversation });

    const response = await assignConversation(value, props.conversation);
    if (response) {
      // console.log({ response });
      props.setConversation(response.conversation);
      props.setOpen(false);
      toast.success("Conversation assigned");

      props.setAllConversation((prevData) =>
        prevData.filter((e) => e._id !== response.conversation?._id)
      );
      setLoading(false);
      props.setValue("Assigned");
    } else {
      toast.error("Something went wrong ");
      props.setOpen(false);
      setLoading(false);
    }
  };
  return (
    <div>
      <Modal
        open={props.open}
        onClose={() => {
          props.setOpen(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          style={{
            top: "50%",
            left: "50%",
            transform: `translate(-${50}%, -${50}%)`,
          }}
          className={classes.paper}
        >
          {loading ? (
            <HashLoader color="black" loading={true} css={override} size={30} />
          ) : (
            <>
              <h4 id="simple-modal-title" style={{ textAlign: "center" }}>
                Select an agent
              </h4>

              <FormControl component="fieldset">
                <FormLabel component="legend">All Agents</FormLabel>
                <RadioGroup value={value} onChange={handleChange}>
                  {adminUser ? (
                    <>
                      <FormControlLabel
                        value={adminUser?._id}
                        control={<Radio />}
                        label="Assign to me"
                      />
                      <FormControlLabel
                        value={userData?.user?._id}
                        control={<Radio />}
                        label={
                          userData?.user?.adminFirstName +
                          " " +
                          userData?.user?.adminLastName
                        }
                      />
                    </>
                  ) : (
                    <>
                      <FormControlLabel
                        value={userData?.user?._id}
                        control={<Radio />}
                        label="Assign to me"
                      />
                      {currentCompanyEmployees?.map((e, index) => (
                        <FormControlLabel
                          key={index}
                          value={e._id}
                          control={<Radio />}
                          label={e.name}
                        />
                      ))}
                    </>
                  )}
                </RadioGroup>
              </FormControl>

              <div className={classes.buttonDiv}>
                <Button
                  className={classes.submitNo}
                  onClick={() => {
                    props.setOpen(false);
                  }}
                >
                  cancel
                </Button>
                <Button
                  variant="contained"
                  className={classes.submitYes}
                  onClick={handleAssignToAgent}
                >
                  Assign to Agent
                </Button>
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default SelectAgentModal;
