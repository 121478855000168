import React, { useEffect, useRef, useState } from "react";

import "./styles.css";
import AccordionCustomButton from "../../components/tabComponents/AccordionCustomButton";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

import Button from "@material-ui/core/Button";
import ChatWindow from "../chatWindow/ChatWindow";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

// ICONS
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import AddIcon from "@material-ui/icons/Add";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import PictureAsPdfOutlinedIcon from "@material-ui/icons/PictureAsPdfOutlined";
import PaymentIcon from "@material-ui/icons/Payment";
import SupervisorAccountOutlinedIcon from "@material-ui/icons/SupervisorAccountOutlined";
// import MenuIcon from "@material-ui/icons/Menu";

import logo from "../../assets/ReportAnonLogoRed.png";
import { NAVIGATION_TYPE, USER_TYPE } from "../../appConstants/constants";
import { getUserCompany, getUsersInCompany } from "../../apiCalls/dataApi";
import {
  disconnectSocket,
  joinNewConversationListener,
  newConversationTest,
} from "../../apiCalls/socketApi";
import moment from "moment";
import TrailOverModal from "../../components/modalComponents/TrailOverModal";
import VerifyCompanyModal from "../../components/modalComponents/VerifyCompanyModal";
import { useWindowDims } from "../../utils/useDimensions";
import { logoutUser } from "../../apiCalls/AuthenticationApi";
import {
  getAgentConversationTotals,
  getConversationTotals,
} from "../../apiCalls/conversationApi";
import GettingStarted from "../setupPages/GettingStarted";
import PdfSign from "../setupPages/PdfSign";
import PaymentPlans from "../setupPages/PaymentPlans";
import { setCompanyStatus } from "../../apiCalls/companyApi";
import { toast } from "react-toastify";
const useStyles = makeStyles((theme) => ({
  hideOnSm: {
    [theme.breakpoints.down("sm")]: { display: "none" },
    borderRight: "solid",
    borderWidth: 1,
    borderColor: "#cacdd7 !important",
  },

  show: {
    [theme.breakpoints.down("sm")]: {
      display: "block !important",
      fontSize: 35,
      cursor: "pointer",
      marginLeft: 20,
      marginTop: 10,
    },
  },
  unreadBadge: {
    height: 10,
    width: 10,
    background: "#FE9900",
    borderRadius: 50,
  },
  mainContainer: {
    backgroundColor: "white",
    flex: 1,
    height: "100%",
  },
  tabContainer: {
    backgroundColor: "rgb(247, 249, 252)",
    // backgroundColor: "red",
  },
  iconLabelsDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  root: {
    width: "100%",
    "&:before": {
      content: "unset",
    },
    "&.MuiAccordion-root": {
      boxShadow: "unset",
    },
    "&.MuiAccordionSummary-root.Mui-expanded": {
      minHeight: "unset",
      margin: 0,
    },
    "&.MuiAccordion-root.Mui-expanded": {
      margin: 0,
    },

    "&.MuiAccordionSummary-root.Mui-expanded .MuiAccordionSummary-content": {
      margin: 0,
    },
    "&. MuiAccordionSummary-content": {
      margin: 0,
    },
  },

  leftSidePanel: {
    display: "flex",
    flexDirection: "column",
    flex: "auto",
    overflowY: "scroll !important",
    overflowX: "hidden",
    // maxHeight: "85vh",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      borderRadius: "5px",
    },
    scrollBehavior: "smooth !important",
  },
}));
let companyAdmin = "Company Admin";
const LandingPage = React.memo((props) => {
  // console.log("Component Mouned");
  const [value, setValue] = useState("Assigned");
  const [showHead, setShowHead] = useState(false);
  const [employeeData, setEmployeeData] = useState();
  const [showProfile, setShowProfile] = useState(false);
  const [showList, setShowList] = useState(false);
  const [type, setType] = useState("");
  // const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);

  // const [userCompanyData, setUserCompanyData] = useState(false);

  //modal flags
  const [isTrailOver, setIsTrailOver] = useState(false);
  const [isCompanyVerified, setIsCompanyVerified] = useState(true);

  const classes = useStyles();
  const { height } = useWindowDims();
  const dispatch = useDispatch();
  const cleanup = useRef(null);

  //redux data
  const userData = useSelector((state) => state.auth.userData);
  const currentCompanyEmployees = useSelector(
    (state) => state.data.currentCompanyEmployees
  );
  const currentCompany = useSelector((state) => state.data.currentCompany);
  const conversationTotals = useSelector(
    (state) => state.data.conversationTotals
  );
  const role = useSelector((state) => state.auth.role);
  const authenticated = useSelector((state) => state.auth.authenticated);

  //test state
  const [newConversation, setNewConversation] = useState({});
  useEffect(() => {
    console.log({ newConversation });
  }, [newConversation]);
  useEffect(() => {
    console.log({ type, value, newConversation });
    if (value === "New") {
      cleanup.current = joinNewConversationListener(setNewConversation);
    } else {
      if (role === USER_TYPE.COMPANY) {
        cleanup.current = newConversationTest(
          toast,
          userData?.user?._id,
          dispatch
        );
      } else {
        cleanup.current = newConversationTest(
          toast,
          userData?.user?.company,
          dispatch
        );
      }
    }
    return () => {
      cleanup.current &&
        cleanup.current.listener.off(
          "NEW_CONVERSATION_CAME_IN",
          cleanup.current.fn
        );
    };

    // eslint-disable-next-line
  }, [type, value, role]);
  //End of test state

  useEffect(() => {
    console.log({ cleanup });
  }, [cleanup]);

  useEffect(() => {
    if (role === USER_TYPE.COMPANY) {
      getTrailInfo();

      fetchUsersInTheCompany(userData.user._id);
    } else if (role === USER_TYPE.USER) {
      // console.log("its a user", { userData });
      fetchUsersInTheCompany(userData.user.company);

      if (!currentCompany) {
        fetchUserCompany();
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role, authenticated, userData]);
  useEffect(() => {
    if (userData && role === USER_TYPE.COMPANY) {
      if (userData.user.adminFirstName && userData.user.adminLastName)
        companyAdmin =
          userData.user.adminFirstName + " " + userData.user.adminLastName;

      setIsCompanyVerified(userData.user.verified);
      fetchConversationTotals(userData.user._id);
      checkForNewCompany(userData.user._id, userData?.user.isNewCompany);
    } else if (userData && role === USER_TYPE.USER) {
      fetchAgentConversationTotals(userData.user.company, userData.user._id);
    }
    // eslint-disable-next-line
  }, [userData, role]);
  useEffect(() => {
    if (showProfile) {
      setType(NAVIGATION_TYPE.USER_PROFILE);
    } else {
      setType(NAVIGATION_TYPE.ADD_A_USER);
    }
  }, [showProfile]);
  useEffect(() => {
    getTrailInfo();
    // eslint-disable-next-line
  }, [userData, currentCompany]);

  const fetchUsersInTheCompany = async (id) => {
    const response = await getUsersInCompany(id);
    if (response) {
      // console.log({ employees: response });
      dispatch({
        type: "SET_CURRENT_COMPANY_EMPLOYEES",
        payload: response.users,
      });
    }
  };
  const fetchUserCompany = async () => {
    const response = await getUserCompany(userData.user.company);
    if (response) {
      // console.log({ company: response });
      dispatch({
        type: "SET_CURRENT_COMPANY",
        payload: response.company,
      });
      getTrailInfo(response?.company?.trail_started);
      // setUserCompanyData(response.company);
    }
  };
  const handleLogout = () => {
    const logoutResponse = logoutUser(userData.user._id, userData.role);
    if (logoutResponse) {
      disconnectSocket();
      dispatch({ type: "SET_ADMIN_USER", payload: null });
      dispatch({ type: "LOGOUT_USER" });
      dispatch({
        type: "SET_CURRENT_COMPANY",
        payload: null,
      });
      dispatch({
        type: "SET_CURRENT_COMPANY_EMPLOYEES",
        payload: null,
      });
    }
    // console.log({ logoutResponse });
  };
  const getTrailInfo = () => {
    // console.log("checking trial info...");
    let subscriptionPlanStripeId = null;
    let isSubscriptionPaused = null;
    let trailStarted = null;
    if (role === USER_TYPE.COMPANY) {
      // console.log("A company");
      subscriptionPlanStripeId = userData?.user?.stripeSubscriptionId;
      isSubscriptionPaused = userData?.user?.isSubscriptionPaused;
      trailStarted = userData?.user?.trail_started;
    } else {
      // console.log("A user");
      subscriptionPlanStripeId = currentCompany?.stripeSubscriptionId;
      isSubscriptionPaused = currentCompany?.isSubscriptionPaused;
      trailStarted = currentCompany?.trail_started;
    }

    const isExpired = moment(moment(trailStarted).add(14, "days")).isBefore(
      moment(new Date())
    );

    if (isExpired && (!subscriptionPlanStripeId || isSubscriptionPaused)) {
      setIsTrailOver(true);
      dispatch({
        type: "SET_DISABLE_SITE",
        payload: true,
      });
    } else {
      dispatch({
        type: "SET_DISABLE_SITE",
        payload: false,
      });
    }
    // console.log({ theDate: x, isExpired });
  };
  const fetchConversationTotals = async (companyId) => {
    // console.log({ companyId });
    const totalsResponse = await getConversationTotals(companyId);
    if (totalsResponse) {
      // console.log({ totalsResponse });

      dispatch({ type: "SET_CONVERSATION_TOTALS", payload: totalsResponse });
    }
  };
  const checkForNewCompany = async (companyId, isNewCompany) => {
    // console.log({ companyId });
    // console.log({ userData });

    if (userData?.user.isNewCompany) {
      setValue("Getting started");
      const response = await setCompanyStatus(companyId);
      if (response) {
        const updatedUser = {
          token: userData.token,
          user: response.company,
          role: role,
        };
        dispatch({ type: "UPDATE_USER_DATA", payload: updatedUser });
      }
    }
  };
  const fetchAgentConversationTotals = async (companyId, agentId) => {
    // console.log({ companyId });
    const totalsResponse = await getAgentConversationTotals(companyId, agentId);
    if (totalsResponse) {
      console.log({ totalsResponse });

      dispatch({ type: "SET_CONVERSATION_TOTALS", payload: totalsResponse });
    }
  };
  const openSubscribePage = () => {
    setValue("Payment plans");
    setIsTrailOver(false);
  };

  return (
    <div
      style={{ height: "100%", backgroundColor: "white", flexWrap: "nowrap" }}
    >
      <Grid container className={classes.mainContainer}>
        {/* LEFT SIDE PANEL */}
        <Grid item xs={12} md={2} className={!showHead && classes.hideOnSm}>
          <div className="conversations-button">
            <img
              src={logo}
              alt="Mountain"
              style={{ width: 150, height: 50, resize: "auto" }}
            />
          </div>
          {/* <Divider /> */}

          <div
            className={classes.leftSidePanel}
            style={{ height: height * 0.85 }}
          >
            <Accordion defaultExpanded className={classes.root}>
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={classes.root}
              >
                <div className={classes.iconLabelsDiv}>
                  <ArrowForwardIosIcon
                    style={{ fontSize: 18 }}
                  ></ArrowForwardIosIcon>
                  <Typography
                    style={{
                      fontSize: 16,
                      fontWeight: 700,
                      marginLeft: 5,
                      fontFamily: "inter,Arial,sans-serif",
                    }}
                  >
                    Inbox
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <AccordionCustomButton
                    setIsTrailOver={setIsTrailOver}
                    icon={<EmailOutlinedIcon style={{ fontSize: 22 }} />}
                    text="New"
                    value={value}
                    setValue={setValue}
                    setShowHead={setShowHead}
                    setShowProfile={setShowProfile}
                    total={conversationTotals.newConversations}
                    setShowList={setShowList}
                  />
                  <AccordionCustomButton
                    setIsTrailOver={setIsTrailOver}
                    setShowList={setShowList}
                    icon={<PeopleAltOutlinedIcon style={{ fontSize: 22 }} />}
                    text="Assigned"
                    value={value}
                    setValue={setValue}
                    setShowHead={setShowHead}
                    setShowProfile={setShowProfile}
                    total={conversationTotals.assignedConversations}
                  />
                  <AccordionCustomButton
                    setIsTrailOver={setIsTrailOver}
                    setShowList={setShowList}
                    icon={<PeopleAltOutlinedIcon style={{ fontSize: 22 }} />}
                    text="Topics"
                    value={value}
                    setValue={setValue}
                    setShowHead={setShowHead}
                    setShowProfile={setShowProfile}
                    // total={totalNew}
                  />
                  <AccordionCustomButton
                    setIsTrailOver={setIsTrailOver}
                    setShowList={setShowList}
                    icon={<CheckOutlinedIcon style={{ fontSize: 22 }} />}
                    text="Closed"
                    value={value}
                    setValue={setValue}
                    setShowHead={setShowHead}
                    setShowProfile={setShowProfile}
                    total={conversationTotals.closedConversations}
                  />
                  <AccordionCustomButton
                    setIsTrailOver={setIsTrailOver}
                    setShowList={setShowList}
                    icon={<ErrorOutlineIcon style={{ fontSize: 22 }} />}
                    text="Spam"
                    value={value}
                    setValue={setValue}
                    setShowHead={setShowHead}
                    setShowProfile={setShowProfile}
                    total={conversationTotals.spamConversations}
                  />
                </div>
              </AccordionDetails>
            </Accordion>

            {/* SETUP ACCORDION   */}
            {role === USER_TYPE.COMPANY && (
              <Accordion className={classes.root} defaultExpanded>
                <AccordionSummary
                  className={classes.root}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className={classes.iconLabelsDiv}>
                    <ArrowForwardIosIcon
                      style={{ fontSize: 18 }}
                    ></ArrowForwardIosIcon>
                    <Typography
                      style={{
                        fontSize: 16,
                        fontWeight: 700,
                        marginLeft: 5,
                        fontFamily: "inter,Arial,sans-serif",
                      }}
                    >
                      Setup
                    </Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <AccordionCustomButton
                      setIsTrailOver={setIsTrailOver}
                      setShowList={setShowList}
                      icon={<EmailOutlinedIcon style={{ fontSize: 22 }} />}
                      text="Getting started"
                      value={value}
                      setValue={setValue}
                      setShowHead={setShowHead}
                      setShowProfile={setShowProfile}
                    />
                    <AccordionCustomButton
                      setIsTrailOver={setIsTrailOver}
                      setShowList={setShowList}
                      icon={
                        <PictureAsPdfOutlinedIcon style={{ fontSize: 22 }} />
                      }
                      text="Your sign"
                      value={value}
                      setValue={setValue}
                      setShowHead={setShowHead}
                      setShowProfile={setShowProfile}
                    />
                    <AccordionCustomButton
                      setIsTrailOver={setIsTrailOver}
                      setShowList={setShowList}
                      icon={<PaymentIcon style={{ fontSize: 22 }} />}
                      text="Payment plans"
                      value={value}
                      setValue={setValue}
                      setShowHead={setShowHead}
                      setShowProfile={setShowProfile}
                    />
                    <AccordionCustomButton
                      setIsTrailOver={setIsTrailOver}
                      setShowList={setShowList}
                      icon={
                        <SupervisorAccountOutlinedIcon
                          style={{ fontSize: 22 }}
                        />
                      }
                      text="Show employee details"
                      value={value}
                      setValue={setValue}
                      setShowHead={setShowHead}
                      setShowProfile={setShowProfile}
                      showDetails
                    />
                  </div>
                </AccordionDetails>
              </Accordion>
            )}

            {/* USER ACCORDION */}
            <Accordion className={classes.root}>
              <AccordionSummary
                className={classes.root}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className={classes.iconLabelsDiv}>
                  <ArrowForwardIosIcon
                    style={{ fontSize: 18 }}
                  ></ArrowForwardIosIcon>
                  <Typography
                    style={{
                      fontSize: 16,
                      fontWeight: 700,
                      marginLeft: 5,
                      fontFamily: "inter,Arial,sans-serif",
                    }}
                  >
                    Users
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  {role === USER_TYPE.USER && (
                    <AccordionCustomButton
                      setIsTrailOver={setIsTrailOver}
                      setShowList={setShowList}
                      icon={<EmailOutlinedIcon style={{ fontSize: 22 }} />}
                      text="My profile"
                      value={value}
                      setValue={setValue}
                      setShowHead={setShowHead}
                      setShowProfile={setShowProfile}
                    />
                  )}

                  {role === USER_TYPE.COMPANY && (
                    <div>
                      <AccordionCustomButton
                        setIsTrailOver={setIsTrailOver}
                        setShowList={setShowList}
                        icon={<EmailOutlinedIcon style={{ fontSize: 22 }} />}
                        text={companyAdmin}
                        value={value}
                        setValue={setValue}
                        setShowHead={setShowHead}
                        employeeProfile
                        data={userData.user}
                        setEmployeeData={setEmployeeData}
                        setShowProfile={setShowProfile}
                      />
                      {currentCompanyEmployees?.map((e, index) => (
                        <AccordionCustomButton
                          setIsTrailOver={setIsTrailOver}
                          setShowList={setShowList}
                          icon={<EmailOutlinedIcon style={{ fontSize: 22 }} />}
                          text={e.name}
                          value={value}
                          setValue={setValue}
                          setShowHead={setShowHead}
                          employeeProfile
                          data={e}
                          setEmployeeData={setEmployeeData}
                          setShowProfile={setShowProfile}
                          key={index}
                        />
                      ))}
                      <AccordionCustomButton
                        setIsTrailOver={setIsTrailOver}
                        setShowList={setShowList}
                        icon={<AddIcon style={{ fontSize: 22 }} />}
                        text="Add a user"
                        value={value}
                        setValue={setValue}
                        setShowHead={setShowHead}
                        setShowProfile={setShowProfile}
                      />
                    </div>
                  )}
                </div>
              </AccordionDetails>
            </Accordion>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#FF2E56",
                color: "white",
                width: "80%",
                alignSelf: "center",
                marginBottom: 20,
              }}
              className={classes.submit}
              onClick={handleLogout}
            >
              Logout
            </Button>
          </div>
          {/* INBOX ACCORDION */}

          {/* LOGOUT BUTTON */}
        </Grid>
        {/* RIGHT SIDE CONTENT PANEL */}
        <Grid
          item
          xs={12}
          md={10}
          className={!showHead ? classes.tabContainer : classes.hideOnSm}
          // className={value === "Your sign" ? "pdf-container" : ""}
        >
          {value === "New" ? (
            <ChatWindow
              dbConversations={newConversation}
              setShowHead={setShowHead}
              type="new"
              setShowList={setShowList}
              showList={showList}
              showHead={showHead}
              value={value}
              setValue={setValue}
            />
          ) : value === "Assigned" ? (
            <ChatWindow
              dbConversations={newConversation}
              value={value}
              setValue={setValue}
              setShowHead={setShowHead}
              showHead={showHead}
              setShowList={setShowList}
              showList={showList}
              type="assigned"
            />
          ) : value === "Topics" ? (
            <ChatWindow
              dbConversations={newConversation}
              value={value}
              setValue={setValue}
              setShowHead={setShowHead}
              showHead={showHead}
              setShowList={setShowList}
              showList={showList}
              type="topics"
            />
          ) : value === "Closed" ? (
            <ChatWindow
              dbConversations={newConversation}
              value={value}
              setValue={setValue}
              setShowHead={setShowHead}
              showHead={showHead}
              setShowList={setShowList}
              showList={showList}
              type="closed"
            />
          ) : value === "Spam" ? (
            <ChatWindow
              dbConversations={newConversation}
              value={value}
              setValue={setValue}
              setShowHead={setShowHead}
              showHead={showHead}
              setShowList={setShowList}
              showList={showList}
              type="spam"
            />
          ) : value === "Getting started" ? (
            <GettingStarted
              value={value}
              setValue={setValue}
              setShowHead={setShowHead}
              setShowList={setShowList}
            />
          ) : value === "Your sign" ? (
            <PdfSign
              value={value}
              setShowHead={setShowHead}
              setShowList={setShowList}
            />
          ) : value === "Payment plans" ? (
            <PaymentPlans
              value={value}
              setShowHead={setShowHead}
              setShowList={setShowList}
            />
          ) : (
            <ChatWindow
              dbConversations={newConversation}
              value={value}
              setValue={setValue}
              setShowHead={setShowHead}
              showHead={showHead}
              setShowList={setShowList}
              showList={showList}
              type={type}
              setType={setType}
              employeeData={employeeData}
              showProfile={showProfile}
            />
          )}
        </Grid>
      </Grid>
      {/* MODALS   */}
      <TrailOverModal
        open={isTrailOver}
        setOpen={setIsTrailOver}
        openSubscribePage={openSubscribePage}
      />
      <VerifyCompanyModal
        open={!isCompanyVerified}
        setOpen={setIsCompanyVerified}
      />
    </div>
  );
}, []);

export default LandingPage;
