export const SOCKET_EVENTS = {
  JOIN: "JOIN",
  JOINED: "JOINED",
  SEND_MESSAGE: "SEND_MESSAGE",
  MESSAGE_RECIEVED: "MESSAGE_RECIEVED",
  LEFT: "LEFT",
  CREATE_TICKET: "CREATE_TICKET",
  END_TICKET: "END_TICKET",
  LEAVE: "LEAVE",
  CONNECTION: "connection",
  DISCONNECT: "disconnect",
  CHATS_UPDATED: "CHATS_UPDATED",
  JOIN_ROOM: "JOIN_ROOM",
  LEAVE_ROOM: "LEAVE_ROOM",
  TICKET_CREATED: "TICKET_CREATED",
  TICKET_ENDED: "TICKET_ENDED",
  MESSAGE_SEEN: "MESSAGE_SEEN",
  NEW_NOTIFICATIONS: "NEW_NOTIFICATIONS",
  JOIN_NOTIFICATIONS: "JOIN_NOTIFICATIONS",
  HANDLE_NOTIFICATION: "HANDLE_NOTIFICATION",
  NOTIFICATION_UPDATED: "NOTIFICATION_UPDATED",

  JOIN_CONVERSATIONS: "JOIN_CONVERSATIONS",
  JOIN_ASSIGNED_CONVERSATIONS: "JOIN_ASSIGNED_CONVERSATIONS",
  JOIN_ASSIGNED_TO_COMPANY_CONVERSATIONS:
    "JOIN_ASSIGNED_TO_COMPANY_CONVERSATIONS",

  JOIN_CLOSED_CONVERSATIONS: "JOIN_CLOSED_CONVERSATIONS",
  JOIN_GROUP_CONVERSATIONS: "JOIN_GROUP_CONVERSATIONS",
  JOIN_SPAM_CONVERSATIONS: "JOIN_SPAM_CONVERSATIONS",
  RETURN_CONVERSATIONS: "RETURN_CONVERSATIONS",
  RETURN_GROUP_CONVERSATION: "RETURN_GROUP_CONVERSATION",
  JOIN_COMPANY_CONVERSATIONS: "JOIN_COMPANY_CONVERSATIONS",
  NEW_CONVERSATION_CAME_IN: "NEW_CONVERSATION_CAME_IN",

  GET_MESSAGES: "GET_MESSAGES",
};
