import "./App.css";
import { ToastContainer } from "react-toastify";

import RouteRedirect from "./routes/route.redirect";
import LoadingComponent from "./components/LoadingComponent";
import { useSelector } from "react-redux";
import { useWindowDims } from "./utils/useDimensions";
import React from "react";

const Main = React.memo(
  () => {
    const loading = useSelector((state) => state.data.loading);

    // console.log("Rendered");

    return loading ? (
      <LoadingComponent />
    ) : (
      <>
        <RouteRedirect />
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          draggable
          pauseOnHover={false}
        />
      </>
    );
  },
  () => true
);

function App() {
  // console.log("App Mouned");
  const { width, height } = useWindowDims();

  return (
    <div
      className="App"
      style={{
        width,
        height,
      }}
    >
      <Main />
    </div>
  );
}

export default App;
