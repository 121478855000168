import { Avatar, Menu, Typography, withStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { assignConversation } from "../../../apiCalls/conversationApi";
import { USER_TYPE } from "../../../appConstants/constants";
import SearchBarComponent from "../SearchBarComponent";
import { css } from "@emotion/react";
import HashLoader from "react-spinners/HashLoader";

const override = css`
  display: block;
  margin: 0 auto;
`;
const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    padding: 0,
    // backgroundColor: "red",.MuiMenu-list
    "&.MuiMenu-list": {
      padding: 0,
    },
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));
let companyAdmin = null;

const AssignConversationMenu = (props) => {
  const { assignChatAnchor, setAssignChatAnchor } = props;
  const [searchedItem, setSearchedItem] = useState("");
  const [filteredEmployees, setFilteredEmployees] = useState([]);

  const [isDisabled, setIsDisabled] = useState(false);

  const dispatch = useDispatch();
  const currentCompanyEmployees = useSelector(
    (state) => state.data.currentCompanyEmployees
  );
  const currentConversation = useSelector(
    (state) => state.data.currentConversation
  );
  const userData = useSelector((state) => state.auth.userData);
  const role = useSelector((state) => state.auth.role);
  useEffect(() => {
    if (userData.user.adminFirstName && userData.user.adminLastName)
      companyAdmin =
        userData.user.adminFirstName + " " + userData.user.adminLastName;
    console.log({ companyAdmin });
  }, [userData]);
  useEffect(() => {
    setFilteredEmployees(currentCompanyEmployees);
  }, [currentCompanyEmployees]);

  useEffect(() => {
    if (searchedItem === "") {
      setFilteredEmployees(currentCompanyEmployees);
    } else {
      setFilteredEmployees((prevData) =>
        prevData.filter((e) =>
          e.name.toLowerCase().includes(searchedItem.toLowerCase())
        )
      );
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedItem]);

  return (
    <StyledMenu
      id="simple-menu"
      anchorEl={assignChatAnchor}
      keepMounted
      open={Boolean(assignChatAnchor)}
      onClose={() => {
        setAssignChatAnchor(null);
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <Typography
          style={{
            fontSize: 16,
            fontWeight: 500,
            fontFamily: "inter,Arial,sans-serif",
          }}
        >
          Assign conversation to
        </Typography>

        <SearchBarComponent
          searchedItem={searchedItem}
          setSearchedItem={setSearchedItem}
        />

        <div style={{ marginTop: 20 }}></div>

        {isDisabled ? (
          <div style={{ margin: 20 }}>
            <HashLoader color="black" loading={true} css={override} size={30} />
          </div>
        ) : (
          <>
            {role === USER_TYPE.USER ? (
              <div
                style={{
                  width: "100%",
                  backgroundColor: "#f1f1f1",
                  display: "flex",
                  alignItems: "center",
                  padding: 12,
                  cursor: "pointer",
                  marginTop: 1,
                }}
                onClick={async () => {
                  if (!isDisabled) {
                    setIsDisabled(true);
                    console.log({ company: userData?.user.company });
                    const response = await assignConversation(
                      userData?.user.company,
                      currentConversation?._id
                    );
                    if (response) {
                      // console.log({ response });
                      toast.success(
                        "Conversation has been assigned to company admin "
                      );
                      dispatch({
                        type: "SET_CURRENT_CONVERSATION",
                        payload: response?.conversation,
                      });
                      props.setAllConversation((prevData) =>
                        prevData.filter(
                          (e) => e._id !== response?.conversation._id
                        )
                      );
                      setAssignChatAnchor(null);
                      props.setValue("Assigned");
                      setIsDisabled(false);
                    }
                  }
                }}
              >
                <Avatar name="Company Admin" size={30} round />
                <Typography
                  style={{
                    fontSize: 14,
                    fontFamily: "inter,Arial,sans-serif",
                    marginLeft: 5,
                  }}
                >
                  Company Admin
                </Typography>
              </div>
            ) : (
              <div
                style={{
                  width: "100%",
                  backgroundColor: "#f1f1f1",
                  display: "flex",
                  alignItems: "center",
                  padding: 12,
                  cursor: "pointer",
                  marginTop: 1,
                }}
                onClick={async () => {
                  if (!isDisabled) {
                    setIsDisabled(true);

                    console.log({ company: userData?.user._id });
                    console.log({ type: props.type });

                    const response = await assignConversation(
                      userData?.user._id,
                      currentConversation?._id
                    );
                    if (response) {
                      console.log({ response });
                      toast.success(
                        "Conversation has been assigned to company admin "
                      );
                      dispatch({
                        type: "SET_CURRENT_CONVERSATION",
                        payload: response?.conversation,
                      });
                      if (props.type === "new") {
                        props.setAllConversation((prevData) =>
                          prevData.filter(
                            (e) => e._id !== response?.conversation._id
                          )
                        );
                      }
                      setAssignChatAnchor(null);
                      props.setValue("Assigned");
                      setIsDisabled(false);
                    }
                  }
                }}
              >
                <Avatar
                  name={companyAdmin || userData.user.name}
                  size={30}
                  round
                />
                <Typography
                  style={{
                    fontSize: 14,
                    fontFamily: "inter,Arial,sans-serif",
                    marginLeft: 5,
                  }}
                >
                  {companyAdmin || userData.user.name}
                </Typography>
              </div>
            )}

            {filteredEmployees?.map((element, index) => (
              <>
                {element._id !== userData?.user?._id && (
                  <div
                    key={index}
                    style={{
                      width: "100%",
                      backgroundColor: "#f1f1f1",
                      display: "flex",
                      alignItems: "center",
                      padding: 12,
                      cursor: "pointer",
                      marginTop: 1,
                    }}
                    onClick={async () => {
                      if (!isDisabled) {
                        setIsDisabled(true);

                        console.log({ element });
                        const response = await assignConversation(
                          element._id,
                          currentConversation?._id
                        );
                        if (response) {
                          console.log({ response });
                          toast.success(
                            "Conversation has been assigned to: " + element.name
                          );
                          dispatch({
                            type: "SET_CURRENT_CONVERSATION",
                            payload: response?.conversation,
                          });
                          if (role === USER_TYPE.USER) {
                            props.setAllConversation((prevData) =>
                              prevData.filter(
                                (e) => e._id !== response?.conversation._id
                              )
                            );
                          } else if (props.type === "new") {
                            props.setAllConversation((prevData) =>
                              prevData.filter(
                                (e) => e._id !== response?.conversation._id
                              )
                            );
                          }
                          setAssignChatAnchor(null);
                          props.setValue("Assigned");
                          setIsDisabled(false);
                        }
                      }
                    }}
                  >
                    <Avatar name={element.name} size={30} round />
                    <Typography
                      style={{
                        fontSize: 14,
                        fontFamily: "inter,Arial,sans-serif",
                        marginLeft: 5,
                      }}
                    >
                      {element.name}
                    </Typography>
                  </div>
                )}
              </>
            ))}
          </>
        )}
      </div>
    </StyledMenu>
  );
};

export default AssignConversationMenu;
