import { Typography } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { USER_TYPE } from "../../../appConstants/constants";
import "../miscStyles.css";
function ToggleSwitch(props) {
  const { toggleState, setToggleState } = props;
  const role = useSelector((state) => state.auth.role);

  return (
    <div
      style={{
        borderRadius: 10,
        display: "flex",
        justifyContent: "space-evenly",
        marginLeft: 12,
        marginRight: 12,
        padding: 5,
      }}
      className="toggle-back"
    >
      <div
        className={
          toggleState === "all" ? "button-toggle-on" : "button-toggle-off"
        }
        onClick={() => {
          setToggleState("all");
        }}
      >
        <Typography
          style={{
            fontSize: 15,
            fontWeight: 300,
            fontFamily: "inter,Arial,sans-serif",
          }}
        >
          All
        </Typography>
      </div>
      <div
        className={
          toggleState === "unReplied" ? "button-toggle-on" : "button-toggle-off"
        }
        onClick={() => {
          setToggleState("unReplied");
        }}
      >
        <Typography
          style={{
            fontSize: 15,
            fontWeight: 300,
            fontFamily: "inter,Arial,sans-serif",
          }}
        >
          Unreplied
        </Typography>
      </div>
      {role === USER_TYPE.COMPANY && (
        <div
          className={
            toggleState === "Me" ? "button-toggle-on" : "button-toggle-off"
          }
          onClick={() => {
            setToggleState("Me");
          }}
        >
          <Typography
            style={{
              fontSize: 15,
              fontWeight: 300,
              fontFamily: "inter,Arial,sans-serif",
            }}
          >
            Me
          </Typography>
        </div>
      )}
    </div>
  );
}

export default ToggleSwitch;
