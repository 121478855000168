import React from "react";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";

import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      //   width: "25ch",
    },
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
  },
  submitYes: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "white",
    color: "black",
    paddingLeft: 20,
    paddingRight: 20,
    marginLeft: 20,
    border: "2px solid #000",
  },
  submitNo: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#FF2E56",
    color: "white",
    paddingLeft: 20,
    paddingRight: 20,
    marginRight: 20,
  },
  buttonDiv: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  formDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    border: "1px solid #EAEDF2",
    padding: 5,
  },
  TextFieldStyle: {
    width: "100%",
  },
}));
function AddSubjectModal(props) {
  const classes = useStyles();
  return (
    <div>
      <Modal
        open={props.open}
        onClose={() => {
          props.setOpen(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          style={{
            top: "50%",
            left: "50%",
            transform: `translate(-${50}%, -${50}%)`,
          }}
          className={classes.paper}
        >
          <h4 id="simple-modal-title" style={{ textAlign: "center" }}>
            Add Subject
          </h4>

          <form className={classes.root} noValidate autoComplete="off">
            <div className={classes.formDiv}>
              <TextField
                id="filled-multiline-static"
                className={classes.TextFieldStyle}
                label="Cause of this conversation"
                variant="standard"
                onChange={(e) => {
                  props.setSubject(e.target.value);
                }}
                // fullWidth
              />
            </div>
          </form>
          <div className={classes.buttonDiv}>
            <Button
              className={classes.submitNo}
              onClick={() => {
                props.setOpen(false);
              }}
            >
              cancel
            </Button>
            <Button
              variant="contained"
              className={classes.submitYes}
              onClick={props.update}
              disabled={props.isModalDisabled}
            >
              submit
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default AddSubjectModal;
