import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER,
});
export const sendVerificationCode = async (companyId) => {
  //   console.log({ companyId });
  try {
    const response = await api.get(
      `/company/sendVerificationCode/${companyId}`
    );
    return response.data;
  } catch (error) {
    console.log({ error });
    return false;
  }
};
export const verifyCompany = async (companyId, code) => {
  //   console.log({ companyId, code });
  try {
    const response = await api.post(`/company/verify/${companyId}`, {
      code,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return false;
  }
};
export const setOnlineStatus = async (companyId, onlineStatus) => {
  //   console.log({ companyId, code });
  try {
    const response = await api.post(`/company/${companyId}/toggleOnline`, {
      isOnline: onlineStatus,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return false;
  }
};
export const setCompanyStatus = async (companyId) => {
  //   console.log({ companyId, code });
  if (companyId) {
    try {
      const response = await api.post(
        `/company/${companyId}/switchStartupScreens`
      );
      return response.data;
    } catch (error) {
      console.log({ error });
      return false;
    }
  } else {
    console.log("error : no ID");
  }
};
export const setCompanyWhatsAppDetails = async (
  companyId,
  whatsAppLink,
  qrCodeLink
) => {
  //   console.log({ companyId, code });
  try {
    const response = await api.post(
      `/company/${companyId}/addWhatsAppDetails`,
      {
        whatsAppLink,
        qrCodeLink,
      }
    );
    return response.data;
  } catch (error) {
    console.log({ error });
    return false;
  }
};
export const getCompanyFromCode = async (code) => {
  //   console.log({ companyId, code });
  try {
    const response = await api.get(`/search/fetchCompanyName/${code}`);
    return response.data;
  } catch (error) {
    console.log({ error });
    return false;
  }
};
export const sendEmailToSuperAdmin = async (companyId) => {
  try {
    const response = await api.get(`/company/${companyId}/QrCodeScanned`);
    return response.data;
  } catch (error) {
    console.log({ error });
    return false;
  }
};
export const sendGenericEmailToSuperAdmin = async () => {
  try {
    const response = await api.get(`/company/QrCODE/generic`);
    return response.data;
  } catch (error) {
    console.log({ error });
    return false;
  }
};
export const setTestCompanyName = async (name) => {
  //   console.log({ companyId, code });
  try {
    const response = await api.post(`/search/saveCompanyName`, { name });
    return response.data;
  } catch (error) {
    console.log({ error });
    return false;
  }
};
export const getAllCompanies = async () => {
  //   console.log({ companyId, code });
  try {
    const response = await api.get(`/company/fetchAll`);
    return response.data;
  } catch (error) {
    console.log({ error });
    return false;
  }
};
