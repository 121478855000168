import React, { useState } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import CssBaseline from "@material-ui/core/CssBaseline";
import { useDispatch } from "react-redux";
import { loginApi, sendResetLink } from "../../apiCalls/AuthenticationApi";
import logo from "../../assets/ReportAnonLogoRed.png";
import { useWindowDims } from "../../utils/useDimensions";
import { USER_TYPE } from "../../appConstants/constants";
import { getUserCompany } from "../../apiCalls/dataApi";

const useStyles = makeStyles((theme) => ({
  hideOnSm: { [theme.breakpoints.down("sm")]: { display: "none" } },
  mainContainer: {
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgb(247, 249, 252)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  loginContainer: {
    backgroundColor: "white",
    boxShadow: "rgb(0 0 0 / 9%) 0px 0.5rem 1rem !important",
    borderRadius: 10,
    padding: "2.25rem",
  },

  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),

    borderRadius: 30,
    backgroundColor: "#30AE9F",
  },
  registrationText: {
    fontSize: 36,
    fontWeight: "bold",
    color: "#333333",
  },
}));

function LoginPage(props) {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { width } = useWindowDims();
  //rendering flags
  const [isResetting, setIsReSetting] = useState(false);

  const dispatch = useDispatch();
  const handleLogin = async () => {
    // console.log({ email, password, loginType });
    if (validateEmail(email)) {
      if (password.length >= 6) {
        // console.log({ loginType });
        dispatch({ type: "SET_LOADING", payload: true });
        const response = await loginApi(email, password);
        if (response) {
          console.log({ response });
          if (response.user.type === USER_TYPE.COMPANY) {
            console.log({ response });
            const usersCompany = await getUserCompany(response.user.company);
            console.log({ usersCompany });
            const adminUser = {
              role: USER_TYPE.COMPANY,
              token: response.token,
              user: usersCompany.company,
            };
            dispatch({
              type: "SET_ADMIN_USER",
              payload: response.user,
            });
            props.history.push("/");
            dispatch({
              type: "USER_LOGIN_SUCCESS",
              payload: adminUser,
            });
            dispatch({ type: "SET_ROLE", payload: USER_TYPE.COMPANY });
            dispatch({ type: "SET_LOADING", payload: false });
          } else {
            props.history.push("/");
            dispatch({
              type: "USER_LOGIN_SUCCESS",
              payload: response,
            });
            dispatch({ type: "SET_LOADING", payload: false });
            dispatch({ type: "SET_ROLE", payload: response.role });
          }
        } else {
          dispatch({ type: "SET_LOADING", payload: false });
          toast.error("Invalid Email or password");
        }
      } else {
        dispatch({ type: "SET_LOADING", payload: false });
        toast.error("Password too short");
      }
    } else {
      dispatch({ type: "SET_LOADING", payload: false });
      toast.error("Invalid Email");
    }
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  const handleForgotPassword = () => {
    setIsReSetting(true);
  };
  const handleSendResetLink = async () => {
    if (validateEmail(email)) {
      const resetResponse = await sendResetLink(email);
      if (resetResponse) {
        toast.info("We have sent you an email with a password reset link", {
          position: "top-right",
          autoClose: 15000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setIsReSetting(false);
      } else {
        toast.warn("This email is not registered with ReportAnon");
      }
    } else {
      toast.error("The email is invalid");
    }
  };
  return (
    <div className={classes.mainContainer}>
      <CssBaseline />
      <div
        className={classes.loginContainer}
        style={
          width > 1200
            ? { width: width * 0.25 }
            : width > 600
            ? { width: width * 0.45 }
            : { width: width * 0.9 }
        }
      >
        <img src={logo} alt="Mountain" style={{ width: 150 }} />

        {/* <Typography className={classes.registrationText}>Sign In</Typography> */}
        <form className={classes.form} noValidate>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            variant="outlined"
            style={{
              fontFamily: "inter,Arial,sans-serif",
            }}
          />
          {isResetting && (
            <Typography
              style={{
                fontSize: 14,
                fontFamily: "inter,Arial,sans-serif",
                alignSelf: "flex-end",
                color: "#5C5C5C",
                cursor: "pointer",
              }}
              onClick={() => setIsReSetting(false)}
            >
              Cancel
            </Typography>
          )}
          {!isResetting && (
            <>
              {" "}
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                variant="outlined"
                style={{
                  fontFamily: "inter,Arial,sans-serif",
                }}
              />
              <Typography
                style={{
                  fontSize: 14,
                  fontFamily: "inter,Arial,sans-serif",
                  alignSelf: "flex-end",
                  color: "#5C5C5C",
                  cursor: "pointer",
                }}
                onClick={handleForgotPassword}
              >
                Forgot password?
              </Typography>
            </>
          )}

          <div style={{ padding: 10 }}>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#FF2E56",
                color: "white",
                paddingLeft: 20,
                paddingRight: 20,
                fontFamily: "inter,Arial,sans-serif",
              }}
              className={classes.submit}
              onClick={isResetting ? handleSendResetLink : handleLogin}
              fullWidth
            >
              {isResetting ? "Send reset link" : "Sign in"}
            </Button>
          </div>

          <Grid
            style={{
              marginTop: 10,
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
            container
          ></Grid>
        </form>
      </div>
      <Grid item style={{ marginTop: 20 }}>
        <Link
          href="/CompanyRegistration"
          variant="body2"
          style={{ fontFamily: "inter,Arial,sans-serif" }}
        >
          {"Don't have an account? Sign Up"}
        </Link>
      </Grid>
    </div>
  );
}

export default LoginPage;
