import React, { useEffect, useState } from "react";

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import { useSelector } from "react-redux";
import { retrieveInvoice } from "../../apiCalls/stripeSubscriptionsApi";
import { toast } from "react-toastify";

function createData(date, amount, status, receiptNo, downloadUrl) {
  return {
    date: date + "",
    amount,
    status,
    receiptNo,
    downloadUrl,
  };
}

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },

  mainContainer: {
    padding: 30,
    display: "flex",
    flexDirection: "column",
    overflowY: "scroll !important",
    overflowX: "hidden",

    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      borderRadius: "5px",
    },
    scrollBehavior: "smooth !important",
    backgroundColor: "#F8F8F8",
  },

  pncContainer: {
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignSelf: "center",
    borderRadius: 10,
    padding: 10,
    margin: 10,
  },
  heading: {
    fontSize: 48,
    fontWeight: 700,
    alignSelf: "center",
    marginTop: 30,
    fontFamily: "inter,Arial,sans-serif",
  },
  tagline: {
    fontSize: 16,
    maxWidth: "50%",
    alignSelf: "center",
    marginTop: 30,
    color: "#686868",
    fontFamily: "inter,Arial,sans-serif",
  },
  tagline2: {
    fontSize: 16,
    maxWidth: "50%",
    alignSelf: "center",
    fontFamily: "inter,Arial,sans-serif",

    marginTop: 20,
    color: "#686868",
  },
  planButton: {
    backgroundColor: "#FF2D55",
    color: "white",
    border: "1px solid #d3d4d5",
    borderRadius: 8,
    boxShadow: "none",
    "&.MuiButton-contained:hover": {
      border: "1px solid #FF2D55",
      color: "#FF2D55",
    },
  },

  pauseOrCancel: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    width: "100%",
  },
  "@media (max-width: 640px)": {
    heading: {
      alignSelf: "flex-start",
      textAlign: "center",
      lineHeight: 1,
    },
    tagline: {
      maxWidth: "100%",
    },
    tagline2: {
      maxWidth: "100%",
    },
    pricingCards: {
      flexDirection: "column",
    },
    pauseOrCancel: {
      flexDirection: "column",
    },
    currentPlanContainer: {
      width: "90%",
    },
  },
}));
const InvoiceComponent = () => {
  const classes = useStyles();
  const [rows, setRows] = useState([]);

  const userData = useSelector((state) => state.auth.userData);

  useEffect(() => {
    getInvoiceData();
    // eslint-disable-next-line
  }, [userData]);

  const getInvoiceData = async () => {
    const response = await retrieveInvoice(userData?.user?.stripeCustomerId);
    if (response) {
      console.log({ response });
      let tempData = [];
      response.data.forEach((element) => {
        const rowData = createData(
          moment(userData.user.stripeSubscriptionDetails.startDate).format(
            "MMM Do YY"
          ),
          element.amount_paid / 100,
          element.status,
          element.receipt_number,
          element.invoice_pdf
        );
        tempData.push(rowData);
      });

      setRows(tempData);
    } else {
      toast.error("Error while fetching data please try again later");
    }
  };
  return (
    <div className={classes.pncContainer}>
      <Typography className={classes.heading}>Invoices</Typography>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell align="center">Amount</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Receipt number</TableCell>
              <TableCell align="right">Download link</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.date}>
                <TableCell component="th" scope="row">
                  {row.date}
                </TableCell>
                <TableCell align="center">€{row.amount}</TableCell>
                <TableCell align="center">{row.status}</TableCell>
                <TableCell align="center">{row.receiptNo}</TableCell>
                <TableCell align="right">
                  <a href={row.downloadUrl}>download receipt</a>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default InvoiceComponent;
