import io from "socket.io-client";
import { MESSAGE_CONSTANTS } from "../appConstants/constants";
import { SOCKET_EVENTS } from "../appConstants/socketConstants";
import { refreshTotals } from "../redux/actions/data.actions";
// let socket = io.connect("https://report-anon.herokuapp.com/");
let socket = io.connect("https://server.reportanon.com/");

export const joinCompanyConversations = (companyId) => {
  // const newSocket = io.connect("https://report-anon.herokuapp.com/");
  const newSocket = io.connect("https://server.reportanon.com/");

  socket = newSocket;
  // console.log("joining company convos : ", companyId);
  socket.emit(SOCKET_EVENTS.JOIN_COMPANY_CONVERSATIONS, {
    room: companyId,
  });
};

export const joinGroups = (id) => {
  socket.emit(SOCKET_EVENTS.JOIN_GROUP_CONVERSATIONS, {
    id: id,
  });
};
export const getGroups = (setData) => {
  // console.log("getting data");
  socket.on(SOCKET_EVENTS.RETURN_GROUP_CONVERSATION, (val) => {
    setData(val.groups);
  });
};

export const joinNewConversationListener = (setData) => {
  const fn = (val) => {
    console.log("new convo came", val.conversation);
    setData(val.conversation);
  };
  const listener = socket.on(SOCKET_EVENTS.NEW_CONVERSATION_CAME_IN, fn);
  return {
    listener,
    fn,
  };
};
export const newConversationTest = (toast, id, dispatch) => {
  const fn = (val) => {
    console.log({ id });
    dispatch(refreshTotals(id));
    toast.info("You have a new message", {
      position: "top-right",
      autoClose: 1000,
      closeOnClick: true,
      draggable: true,
    });
  };

  const listener = socket.on(SOCKET_EVENTS.NEW_CONVERSATION_CAME_IN, fn);

  return {
    listener,
    fn,
  };
};
export const getUpdatedConversations = (setData, setConversationData) => {
  // console.log("getting convos : ", phone);
  socket.on(SOCKET_EVENTS.CHATS_UPDATED, (val) => {
    if (!val.message.MediaContentType0) {
      if (val?.message?.Latitude) {
        const messageObject = {
          conversation: val.id,
          message: val.message.Body,
          date: new Date(),
          from: val?.message?.From?.split(":")[1],
          latitude: val.message.Latitude,
          longitude: val.message.Longitude,
          message_type: "location",
        };

        setData((prev) => [...prev, messageObject]);
        setConversationData((prevChat) =>
          prevChat.map((e) =>
            // eslint-disable-next-line
            e._id == val?.conversation?._id
              ? {
                  ...e,
                  last_message: val?.conversation?.last_message,
                  last_message_time: val?.conversation?.last_message_time,
                }
              : e
          )
        );
      } else {
        setData({
          conversation: val.conversation._id,
          message: val.message.Body,
          date: new Date(),
          from: val?.message?.From?.split(":")[1],
        });
        setConversationData((prevChat) =>
          prevChat.map((e) =>
            // eslint-disable-next-line
            e._id == val?.conversation?._id
              ? {
                  ...e,
                  last_message: val?.conversation?.last_message,
                  last_message_time: val?.conversation?.last_message_time,
                  last_message_sender: MESSAGE_CONSTANTS.AGENT,
                }
              : e
          )
        );
      }
    } else {
      setData({
        conversation: val.conversation._id,
        message: val.message.Body,
        date: new Date(),
        message_type: val.message.MediaContentType0,
        media_uri: val.message.MediaUrl0,
        from: val?.message?.From?.split(":")[1],
      });
      setConversationData((prevChat) =>
        prevChat.map((e) =>
          // eslint-disable-next-line
          e._id == val?.conversation?._id
            ? {
                ...e,
                last_message: val?.conversation?.last_message,
                last_message_time: val?.conversation?.last_message_time,
                last_message_sender: MESSAGE_CONSTANTS.AGENT,
              }
            : e
        )
      );
    }
  });
};
export const unsubscribeListener = () => {
  socket.removeAllListeners(SOCKET_EVENTS.CHATS_UPDATED);
};
export const disconnectSocket = () => {
  console.log("disconnecting..");
  socket.disconnect();
};
