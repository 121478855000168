// eslint-disable-next-line
import React, { useEffect, useState } from "react";
import "../miscStyles.css";
import Avatar from "react-avatar";
import { Typography } from "@material-ui/core";
import ReactPlayer from "react-player/lazy";
import moment from "moment";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app
import { useWindowDims } from "../../../utils/useDimensions";
import { useSelector } from "react-redux";

function MessageRight(props) {
  const { data } = props;
  const { width } = useWindowDims();
  const searchedMessage = useSelector((state) => state.data.searchedMessage);

  const [openLightbox, setOpenLightbox] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignSelf: "flex-end",
        alignItems: "flex-end",
        width: "100%",
      }}
    >
      <div className="message-styling">
        {/* <Avatar
          name={props.currentSender?.user?.email || "anonymous"}
          size={30}
          round
        /> */}
        <Avatar
          name={data?.from?.split("-")[1] || "Report Anon"}
          size={30}
          round
        />

        {data?.message_type?.split("/")[0] === "image" ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <img
              src={data.media_uri}
              alt=""
              style={{ maxHeight: 200, maxWidth: 200, cursor: "pointer" }}
              onClick={() => {
                setOpenLightbox(true);
              }}
            />
            {/* <Divider style={{ marginTop: 5, border: "1px solid #EAEDF2" }} /> */}
            <Typography
              style={{
                marginLeft: 10,
                wordBreak: "break-word",
                textAlign: "left",
                fontFamily: "inter,Arial,sans-serif",
              }}
            >
              {data?.message || ""}
            </Typography>
            {openLightbox && (
              <Lightbox
                mainSrc={data.media_uri}
                onCloseRequest={() => setOpenLightbox(false)}
              />
            )}
          </div>
        ) : data?.message_type?.split("/")[0] === "video" ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <ReactPlayer
              url={data.media_uri}
              width={width > 400 ? "300px" : "250"}
              height="200px"
              controls
            />
            <Typography
              style={{
                marginLeft: 10,
                wordBreak: "break-word",
                textAlign: "left",
                fontFamily: "inter,Arial,sans-serif",
              }}
            >
              {data?.message || ""}
            </Typography>
          </div>
        ) : data?.message_type?.split("/")[0] === "audio" ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <ReactPlayer
              url={data.media_uri}
              width="300px"
              height="50px"
              controls
            />
            <Typography
              style={{
                fontFamily: "inter,Arial,sans-serif",
                marginLeft: 10,
                wordBreak: "break-word",
                textAlign: "left",
              }}
            >
              {data?.message || ""}
            </Typography>
          </div>
        ) : data?.message_type?.split("/")[0] === "application" ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Typography
              style={{
                textAlign: "start",
                marginLeft: 10,
                color: "grey",
                fontFamily: "inter,Arial,sans-serif",
              }}
            >
              <a href={data?.media_uri} target="_blank" rel="noreferrer">
                {data?.message_type}
              </a>
            </Typography>
          </div>
        ) : (
          <Typography
            style={
              data?.message
                ?.toLowerCase()
                .includes(searchedMessage?.toLowerCase())
                ? {
                    marginLeft: 10,
                    wordBreak: "break-word",
                    textAlign: "left",
                    fontFamily: "inter,Arial,sans-serif",
                    backgroundColor: "yellow",
                  }
                : {
                    marginLeft: 10,
                    wordBreak: "break-word",
                    textAlign: "left",
                    fontFamily: "inter,Arial,sans-serif",
                    // backgroundColor: "yellow",
                  }
            }
          >
            {data?.message || ""}
          </Typography>
        )}
      </div>

      <Typography
        style={{
          textAlign: "start",
          marginTop: 10,
          color: "grey",
          fontFamily: "inter,Arial,sans-serif",
        }}
      >
        {moment(data.date).startOf("ss").fromNow() + ""}
      </Typography>
    </div>
  );
}

export default MessageRight;
