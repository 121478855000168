import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER,
});
export const assignConversation = async (userId, conversationId) => {
  // console.log({ userId, conversationId });
  try {
    const response = await api.post(`/conversation/${conversationId}/assign`, {
      userId,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return false;
  }
};
export const addConversationSubject = async (conversationId, subject) => {
  // console.log({ subject, conversationId });
  try {
    const response = await api.post(`/conversation/${conversationId}/subject`, {
      subject,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return false;
  }
};
export const getConversationsInGroup = async (groupId) => {
  // console.log({ groupId });
  try {
    const response = await api.get(`group/${groupId}/conversations`);
    return response.data.conversations;
  } catch (error) {
    console.log({ error });
    return false;
  }
};
export const groupConversations = async (
  conversationIds,
  groupName,
  companyId
) => {
  // console.log({ conversationIds, groupName, companyId });
  try {
    const response = await api.post(`/group/`, {
      conversationIds,
      groupName,
      companyId,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return false;
  }
};
export const addConversationToGroup = async (conversationId, groupId) => {
  // console.log({ conversationId, groupId });
  try {
    const response = await api.post(`/group/${groupId}/add`, {
      conversationId,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return false;
  }
};
export const deleteGroup = async (id) => {
  try {
    const response = await api.delete(`/group/${id}`);
    return response.data;
  } catch (error) {
    console.log({ error });
    return false;
  }
};
export const closeConversation = async (conversationId) => {
  // console.log({ conversationId });
  try {
    const response = await api.post(`/conversation/${conversationId}/close`);
    return response.data;
  } catch (error) {
    console.log({ error });
    return false;
  }
};
export const reopenConversation = async (conversationId) => {
  // console.log({ conversationId });
  try {
    const response = await api.post(`/conversation/${conversationId}/re-open`);
    return response.data;
  } catch (error) {
    console.log({ error });
    return false;
  }
};
export const spamConversation = async (conversationId) => {
  // console.log({ conversationId });
  try {
    const response = await api.post(`/conversation/${conversationId}/markSpam`);
    return response.data;
  } catch (error) {
    console.log({ error });
    return false;
  }
};
export const removeFromSpam = async (conversationId) => {
  // console.log({ conversationId });
  try {
    const response = await api.post(
      `/conversation/${conversationId}/unmarkSpam`
    );
    return response.data;
  } catch (error) {
    console.log({ error });
    return false;
  }
};
export const getConversationTotals = async (companyId) => {
  //   console.log({ companyId, code });
  try {
    const response = await api.get(`/conversation/${companyId}/getTotals`);
    return response.data;
  } catch (error) {
    console.log({ error });
    return false;
  }
};
export const getAgentConversationTotals = async (companyId, agentId) => {
  //   console.log({ companyId, code });
  try {
    if (companyId) {
      const response = await api.post(
        `/conversation/${companyId}/getAgentTotals`,
        { assignedTo: agentId }
      );
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log({ error });
    return false;
  }
};
export const getConversationsFromDb = async (companyId, type, assignedTo) => {
  // console.log({ companyId, type, assignedTo });
  try {
    const response = await api.post(
      `/conversation/${companyId}/getConversations`,
      {
        type,
        assignedTo,
      }
    );
    return response.data;
  } catch (error) {
    console.log({ error });
    return false;
  }
};
export const getConversationMessagesFromDb = async (
  conversationId,
  page,
  limit
) => {
  // console.log({ conversationId, page, limit });
  try {
    if (!conversationId) {
      return false;
    } else {
      const response = await api.post(
        `/conversation/${conversationId}/getConversationMessages`,
        {
          page,
          limit,
        }
      );
      return response.data;
    }
  } catch (error) {
    console.log({ error });
    return false;
  }
};
export const searchMessage = async (searchQuery) => {
  // console.log({ searchQuery });
  try {
    const response = await api.post(`/search/messages`, {
      searchQuery,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return false;
  }
};
