import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import "./styles.css";
import PauseCircleFilledIcon from "@material-ui/icons/PauseCircleFilled";
import StopIcon from "@material-ui/icons/Stop";
const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: 24,
    fontWeight: 500,
    alignSelf: "center",
    marginTop: 30,
    fontFamily: "inter,Arial,sans-serif",
  },
  tagline: {
    fontSize: 16,
    maxWidth: "80%",
    alignSelf: "center",
    marginTop: 10,
    color: "#686868",
    fontFamily: "inter,Arial,sans-serif",
  },
  iconContainer: {
    padding: 15,
    backgroundColor: "#FF2D55",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "@media (max-width: 640px)": {
    heading: {
      alignSelf: "flex-start",
      textAlign: "left",
      lineHeight: 1,
    },
    tagline: {
      maxWidth: "100%",
    },
  },
}));
const PauseOrCancelButton = (props) => {
  const classes = useStyles();

  return (
    <div className="pnc-button" onClick={props.onClick}>
      <div className={classes.iconContainer}>
        {props.pause ? (
          <PauseCircleFilledIcon
            style={{
              fontSize: 30,
              color: "white",
            }}
          />
        ) : (
          <StopIcon
            style={{
              backgroundColor: "white",
              fontSize: 30,
              color: "#FF2D55",
              borderRadius: "50%",
            }}
          />
        )}
      </div>
      <Typography className={classes.heading}>{props.title}</Typography>
      <Typography className={classes.tagline}>{props.tagline}</Typography>
    </div>
  );
};

export default PauseOrCancelButton;
