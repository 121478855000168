import React from "react";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core";
import { css } from "@emotion/react";
import HashLoader from "react-spinners/HashLoader";

const override = css`
  display: block;
  margin: 0 auto;
`;

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    // width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
  },
  submitYes: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "white",
    color: "black",
    paddingLeft: 20,
    paddingRight: 20,
    marginRight: 20,
    border: "2px solid #000",
  },
  submitNo: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#FF2E56",
    color: "white",
    paddingLeft: 20,
    paddingRight: 20,
    marginLeft: 20,
  },
  buttonDiv: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
}));
function ConfirmationModal(props) {
  const classes = useStyles();

  return (
    <div>
      <Modal
        open={props.open}
        onClose={() => {
          props.setOpen(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {props.custom ? (
          <div
            style={{
              top: "50%",
              left: "50%",
              transform: `translate(-${50}%, -${50}%)`,
            }}
            className={classes.paper}
          >
            <h3 id="simple-modal-title" style={{ textAlign: "center" }}>
              Are you sure you want to {props.modalHeader}
            </h3>

            {props.isModalLoading ? (
              <HashLoader
                color="black"
                loading={true}
                css={override}
                size={30}
              />
            ) : (
              <div className={classes.buttonDiv}>
                <Button
                  variant="contained"
                  className={classes.submitYes}
                  onClick={props.handleSubmit}
                >
                  Yes
                </Button>
                <Button
                  className={classes.submitNo}
                  onClick={() => {
                    props.setOpen(false);
                  }}
                >
                  No
                </Button>
              </div>
            )}
          </div>
        ) : (
          <div
            style={{
              top: "50%",
              left: "50%",
              transform: `translate(-${50}%, -${50}%)`,
            }}
            className={classes.paper}
          >
            <h3 id="simple-modal-title" style={{ textAlign: "center" }}>
              {props.modalType === "close"
                ? " Are you sure you want to close this conversation?"
                : props.modalType === "spam"
                ? " Are you sure you want to mark this conversation as spam?"
                : props.modalType === "reopen"
                ? " Are you sure you want to reopen this conversation?"
                : " Are you sure you want to remove this conversation from spam?"}
            </h3>
            <div className={classes.buttonDiv}>
              <Button
                variant="contained"
                className={classes.submitYes}
                onClick={props.handleUpdateConversationStatus}
                disabled={props.isModalDisabled}
              >
                Yes
              </Button>
              <Button
                className={classes.submitNo}
                onClick={() => {
                  props.setOpen(false);
                }}
              >
                No
              </Button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
}

export default ConfirmationModal;
