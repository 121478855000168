import React from "react";
import "./miscStyles.css";
import SearchIcon from "@material-ui/icons/Search";
function SearchBarComponent(props) {
  const { searchedItem, setSearchedItem } = props;
  return (
    <div className={props.customStyle || "search-container"}>
      <SearchIcon style={{ fontSize: 25 }} />

      <input
        type="text"
        placeholder={props.placeHolder || "Search"}
        value={searchedItem}
        className="form-control"
        onChange={(e) => {
          setSearchedItem(e.target.value);
        }}
      />
    </div>
  );
}

export default SearchBarComponent;
