import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
/**
 * @author
 * @function PrivateRoute
 **/

const PrivateRoute = ({ component: Component, ...rest }) => {
  const authStatus = useSelector((state) => state.auth.authenticated);

  return (
    <Route
      {...rest}
      component={(props) => {
        if (authStatus) {
          return <Component {...props} />;
        } else {
          return <Redirect to={`/login`} />;
        }
      }}
    />
  );
};

export default PrivateRoute;
