import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import { makeStyles, Typography } from "@material-ui/core";
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import ReactCodeInput from "react-code-input";
import { useWindowDims } from "../../utils/useDimensions";
import { sendVerificationCode, verifyCompany } from "../../apiCalls/companyApi";
import { toast } from "react-toastify";
import { css } from "@emotion/react";
import HashLoader from "react-spinners/HashLoader";

const override = css`
  display: block;
  margin: 0 auto;
`;

const useStyles = makeStyles((theme) => ({
  modalDiv: {
    height: "95%",
    width: "90vw",
    alignSelf: "center",
    position: "absolute",
    left: "5%",
    top: "2%",
    overflowY: "scroll",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: 10,
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      borderRadius: "5px",
    },
    scrollBehavior: "smooth",
  },

  submitYes: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "white",
    color: "black",
    paddingLeft: 20,
    padding: "16px 32px",
    fontSize: 16,
    border: "2px solid #000",
    borderRadius: 30,
    [theme.breakpoints.down("sm")]: {
      borderRadius: 25,
      padding: "8px 16px",
      fontSize: 12,
    },
  },
  submitNo: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#FF2E56",
    color: "white",
    padding: "16px 32px",
    marginLeft: 20,
    borderRadius: 30,
    fontSize: 16,
    [theme.breakpoints.down("sm")]: {
      borderRadius: 25,
      padding: "8px 16px",
      fontSize: 12,
    },
  },
  buttonDiv: {
    display: "flex",
    width: "100%",
    justifyContent: "baseline",
    padding: theme.spacing(0, 4, 3),
    alignSelf: "flex-end",
    paddingLeft: "5rem",
    paddingTop: "2rem",
    paddingRight: "1rem",
    marginBottom: "5rem",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "1rem",
      paddingTop: "1rem",
      paddingRight: "1rem",
      marginBottom: "1rem",
    },
  },
  divider: {
    width: "100%",
    height: "1px",
    backgroundColor: "#f1f1f1",
  },
  leftColumn: {
    width: "35%",
    background: "linear-gradient(90deg, #ff2d55 1%, #c60f32 100%)",
    [theme.breakpoints.down("sm")]: { display: "none" },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  rightColumn: {
    width: "70%",
    backgroundColor: "white",
    [theme.breakpoints.down("sm")]: { width: "100%" },
    // height: "100vh",
  },
  loadingColumn: {
    width: "70%",
    backgroundColor: "white",
    [theme.breakpoints.down("sm")]: { width: "100%" },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  contentDiv: {
    marginTop: "6rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingLeft: "5rem",
    paddingTop: "2rem",
    paddingRight: "1rem",
    [theme.breakpoints.down("sm")]: {
      marginTop: "3rem",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingLeft: "1rem",
      paddingTop: "1rem",
      paddingRight: "1rem",
    },
  },
}));
function VerifyCompanyModal(props) {
  const { width } = useWindowDims();
  // const width = 1920;

  const codeInputProps = {
    inputStyle: {
      fontFamily: "inter,Arial,sans-serif",
      margin: "4px",
      MozAppearance: "textfield",
      borderRadius: width * 0.01,
      fontSize: width <= 760 ? 30 : 45,
      fontWeight: 700,
      textAlign: "center",
      width: width <= 760 ? width * 0.1 : 100,
      height: width <= 760 ? width * 0.1 : 100,
      paddingLeft: "7px",
      backgroundColor: "white",
      color: "black",
      border: "1px solid #eaeaea",
    },
    inputStyleInvalid: {
      fontFamily: "inter,Arial,sans-serif",
      margin: "4px",
      MozAppearance: "textfield",
      width: "15px",
      borderRadius: "3px",
      fontSize: "14px",
      height: "26px",
      paddingLeft: "7px",
      backgroundColor: "white",
      color: "red",
      border: "1px solid red",
    },
  };

  const [secondsRemaining, setSecondsRemaining] = React.useState(0);
  const [loading, setLoading] = useState(false);

  const classes = useStyles();

  const [verificationCode, setVerificationCode] = useState("");
  const userData = useSelector((state) => state.auth.userData);

  const dispatch = useDispatch();

  useEffect(() => {
    if (secondsRemaining > 0) {
      setTimeout(() => setSecondsRemaining(secondsRemaining - 1), 1000);
    } else {
      // console.log("time over");
      setSecondsRemaining(0);
    }
    // eslint-disable-next-line
  }, [secondsRemaining]);

  useEffect(() => {
    if (verificationCode.length >= 6) {
      handleSubmitCode();
    }
    // eslint-disable-next-line
  }, [verificationCode]);
  const handleResendCode = async () => {
    console.log("resending...");
    setLoading(true);
    const verificationResponse = await sendVerificationCode(userData.user._id);
    if (verificationResponse) {
      setLoading(false);
      console.log(verificationResponse);
      setSecondsRemaining(60);
      toast.success("Verification code sent");
    } else {
      setLoading(false);
      toast.error(
        verificationResponse?.error?.response?.data?.message ||
          "Something went wrong"
      );
    }
  };

  const handleSubmitCode = async () => {
    // console.log("submitting...", verificationCode);
    setLoading(true);
    const verifyResponse = await verifyCompany(
      userData.user._id,
      verificationCode
    );
    if (verifyResponse) {
      setLoading(false);
      console.log({ verifyResponse });
      toast.success("Verified");
      const updatedUserData = {
        token: userData.token,
        user: verifyResponse.company,
      };
      dispatch({
        type: "UPDATE_USER_DATA",
        payload: updatedUserData,
      });
    } else {
      // setSecondsRemaining(60);
      setLoading(false);
      toast.error("Invalid code");
    }
  };
  return (
    <div>
      <Modal
        open={props.open}
        onClose={() => {
          // props.setOpen(false);
        }}
      >
        <div className={classes.modalDiv}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              backgroundColor: "red",
              height: "100%",
              width: "100%",
            }}
          >
            <div className={classes.leftColumn}>
              <img
                src="https://reportanon.com/wp-content/uploads/2021/07/Vector-1-1.png"
                alt="Mountain"
                style={{
                  width: "70%",
                  // backgroundColor: "red",
                }}
              />
            </div>
            {loading ? (
              <div className={classes.loadingColumn}>
                <HashLoader
                  color="black"
                  loading={true}
                  css={override}
                  size={150}
                />
              </div>
            ) : (
              <div className={classes.rightColumn}>
                <div className={classes.contentDiv}>
                  <h1
                    id="simple-modal-title"
                    style={{
                      padding: 16,
                      fontWeight: 700,
                      alignSelf: "baseline",
                    }}
                  >
                    Thanks for signing up
                  </h1>
                  <Typography
                    style={{
                      fontWeight: 400,
                      fontSize: "1.25rem",
                      padding: 16,
                      alignSelf: "baseline",
                    }}
                  >
                    We've sent a verification code to{" "}
                    <strong>{userData?.user.email}</strong>
                    <br /> Enter the code below to access your inbox.
                  </Typography>

                  <Typography
                    style={{
                      fontWeight: 400,
                      fontSize: "1.25rem",
                      padding: 16,
                      alignSelf: "baseline",
                      marginTop: "3rem",
                    }}
                  >
                    Fill out the code
                  </Typography>
                  <div style={{ alignSelf: "flex-start" }}>
                    <ReactCodeInput
                      fields={6}
                      {...codeInputProps}
                      onChange={(e) => {
                        setVerificationCode(e);
                      }}
                      // disabled={secondsRemaining > 0}
                      inputMode="numeric"
                    />
                  </div>
                  <Typography
                    style={{
                      fontWeight: 400,
                      fontSize: "1.25rem",
                      padding: 16,
                      alignSelf: "baseline",
                      // marginTop: "3rem",
                    }}
                  >
                    Didn't receive anything?{" "}
                    <strong
                      style={{ cursor: "pointer" }}
                      onClick={
                        secondsRemaining === 0
                          ? handleResendCode
                          : () => {
                              toast.warn(
                                secondsRemaining +
                                  " seconds before you can send another request"
                              );
                            }
                      }
                    >
                      {secondsRemaining === 0
                        ? "Resend Code"
                        : "Send another request in: " + secondsRemaining}
                    </strong>
                  </Typography>
                </div>
                <div className={classes.buttonDiv}>
                  <Button
                    variant="contained"
                    className={classes.submitYes}
                    onClick={() => {
                      props.setOpen(true);
                    }}
                  >
                    Temporary disable (For developer use only)
                  </Button>
                  <Button
                    className={classes.submitNo}
                    onClick={handleSubmitCode}
                    // disabled={secondsRemaining > 0}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default VerifyCompanyModal;
