import { Button, makeStyles, MenuItem, Select } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import {
  getAllCompanies,
  setCompanyWhatsAppDetails,
  setTestCompanyName,
} from "../../apiCalls/companyApi";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { getPreSignedLink, uploadFile } from "../../apiCalls/AuthenticationApi";
import CustomInputField from "../../components/userDetails/CustomInputField";
import { toast } from "react-toastify";
const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: 40,
    backgroundColor: "white",
    borderRadius: 10,

    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    alignSelf: "center",
  },
  multiSelectDiv: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },

  customSelect: {
    width: "40%",
    border: "1px solid #d3d4d5",
    padding: "8px 12px",
    marginRight: 30,
    fontFamily: "Poppins,sans-serif",
  },

  inputField: {
    width: "40%",
    border: "1px solid #d3d4d5",
    padding: "8px 12px",
    marginRight: 30,
    fontFamily: "Poppins,sans-serif",
  },
  "@media (max-width: 640px)": {
    customSelect: {
      width: "90%",
      marginBottom: 10,
    },

    multiSelectDiv: {
      flexDirection: "column",
    },
    inputField: {
      width: "90%",
      marginBottom: 10,
    },
  },
}));
const AddCompany = () => {
  const classes = useStyles();

  const [companyLink, setCompanyLink] = useState("");
  const [addCompanyNameToLink, setAddCompanyNameToLink] = useState("");

  const [uploading, setUploading] = useState(false);
  const [company, setCompany] = useState(null);
  const [allCompanies, setAllCompanies] = useState(null);
  const [companyQrCodeLink, setCompanyQrCodeLink] = useState(null);
  const [isDone, setIsDone] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const [filesToUpload, setFilesToUpload] = useState(null);

  const inputFile = useRef(null);

  useEffect(() => {
    fetchAllCompanies();
  }, []);
  useEffect(() => {
    if (filesToUpload) {
      setUploading(true);
      uploadFilesToServer();
      // console.log({ filesToUpload });
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesToUpload]);

  const onFileSelected = (event) => {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];
    // console.log({ file });
    setFilesToUpload(file);
  };
  const uploadFilesToServer = async () => {
    const response = await getPreSignedLink({
      name: filesToUpload.name,
      type: filesToUpload.type,
    });
    if (response) {
      // console.log({ response });
      const uploadFileResult = await uploadFile({
        url: response,
        file: filesToUpload,
        type: filesToUpload.type,
      });
      console.log("Uploaded : ", JSON.stringify(uploadFileResult, null, 2));
      const url = response.split("?")[0];
      console.log({ url });
      setCompanyQrCodeLink(url);
      setUploading(false);
      setIsDone(true);
      toast.info("Qr code added");
    } else {
      setUploading(false);
      console.log("error while generating link...");
    }
  };
  const handleChange = (event) => {
    setCompany(event.target.value);
  };

  const fetchAllCompanies = async () => {
    const response = await getAllCompanies();
    setAllCompanies(response.company);
  };
  return (
    <div
      style={{
        backgroundColor: "#F8F8F8",
        display: "flex",
        flexDirection: "column",
        width: "100vw",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className={classes.mainContainer}>
        <CustomInputField
          label="Select Company"
          customSelect={
            <div className={classes.multiSelectDiv}>
              <Select
                value={company}
                onChange={handleChange}
                label="Select a company"
                className={classes.customSelect}
                displayEmpty
                disableUnderline
              >
                {allCompanies?.map((e, index) => (
                  <MenuItem value={e._id} key={index}>
                    {e.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          }
        />
        <CustomInputField
          label="Add company link"
          value={companyLink}
          onChange={(e) => setCompanyLink(e.target.value)}
        />
        <CustomInputField
          label=" Add Qr code"
          customSelect={
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "black",
                  color: "white",
                  paddingLeft: 20,
                  paddingRight: 20,
                  fontFamily: "inter,Arial,sans-serif",
                }}
                disabled={uploading}
                startIcon={<CloudUploadIcon />}
                onClick={async () => {
                  inputFile.current.click();
                }}
              >
                {uploading ? "uploading..." : "Upload qr code"}
              </Button>

              {isDone && (
                <CheckCircleIcon
                  style={{
                    marginLeft: 10,
                    color: "green",
                    fontSize: 30,
                  }}
                  // fontSize={30}
                />
              )}
            </div>
          }
        />

        <input
          type="file"
          id="file"
          ref={inputFile}
          style={{ display: "none" }}
          onChange={onFileSelected}
        />
        <div
          style={{
            // padding: 10,
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
            alignSelf: "flex-end",
            marginTop: 20,
          }}
        >
          <Button
            variant="contained"
            style={{
              backgroundColor: "#FF2E56",
              color: "white",
              paddingLeft: 20,
              paddingRight: 20,
              fontFamily: "inter,Arial,sans-serif",
            }}
            disabled={uploading}
            onClick={async () => {
              console.log({ company, companyLink, companyQrCodeLink });
              if (company) {
                const response = await setCompanyWhatsAppDetails(
                  company,
                  companyLink,
                  companyQrCodeLink
                );
                if (response) {
                  setIsSaved(true);
                  toast.success("Company details saved");
                }
                console.log({ response });
              } else {
                toast.warn("please select a company");
              }
            }}
          >
            {isSaved ? "Company details saved" : "save to database"}
          </Button>
        </div>
      </div>

      <div
        style={{
          width: "50%",
          backgroundColor: "white",
          borderRadius: 10,
          marginTop: 20,
          padding: 20,
        }}
      >
        <CustomInputField
          label="Add company name to link"
          value={addCompanyNameToLink}
          onChange={(e) => setAddCompanyNameToLink(e.target.value)}
        />
        <div
          style={{
            // padding: 10,
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
            alignSelf: "flex-end",
            marginTop: 20,
          }}
        >
          <Button
            variant="contained"
            style={{
              backgroundColor: "#FF2E56",
              color: "white",
              paddingLeft: 20,
              paddingRight: 20,
              fontFamily: "inter,Arial,sans-serif",
            }}
            onClick={async () => {
              console.log({ addCompanyNameToLink });

              const linkResponse = await setTestCompanyName(
                addCompanyNameToLink
              );
              if (linkResponse) {
                console.log({ linkResponse });
                toast.success("Company name added to link");
              }
            }}
          >
            save to database
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddCompany;
