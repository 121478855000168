import React, { useEffect, useState, useRef } from "react";
import { Button, makeStyles, Typography } from "@material-ui/core";
import PricingCards from "../../components/SubscriptionComponents/PricingCards";
import {
  GROW_PLAN,
  SCALE_PLAN,
  START_PLAN,
  YEARLY_PLAN,
} from "../../appConstants/subscriptionTiers";
import MenuIcon from "@material-ui/icons/Menu";
import PaymentForm from "../../components/SubscriptionComponents/PaymentForm";
import { useWindowDims } from "../../utils/useDimensions";
import { useDispatch, useSelector } from "react-redux";
import { getNumberAfter, getNumberBefore } from "../../utils/helperFunctions";
import PauseOrCancelButton from "../../components/SubscriptionComponents/PauseOrCancelButton";
import ConfirmationModal from "../../components/modalComponents/ConfirmationModal";
import {
  cancelSubscription,
  pauseSubscription,
  resumeSubscription,
} from "../../apiCalls/stripeSubscriptionsApi";
import { toast } from "react-toastify";
import moment from "moment";
import InvoiceComponent from "../../components/SubscriptionComponents/InvoiceComponent";
const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: 30,
    display: "flex",
    flexDirection: "column",
    overflowY: "scroll !important",
    overflowX: "hidden",

    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      borderRadius: "5px",
    },
    scrollBehavior: "smooth !important",
    backgroundColor: "#F8F8F8",
  },
  currentPlanContainer: {
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    width: "60%",
    alignSelf: "center",
    borderRadius: 10,
    padding: 10,
  },
  pncContainer: {
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignSelf: "center",
    borderRadius: 10,
    padding: 10,
    margin: 10,
  },
  heading: {
    fontSize: 48,
    fontWeight: 700,
    alignSelf: "center",
    marginTop: 30,
    fontFamily: "inter,Arial,sans-serif",
  },
  tagline: {
    fontSize: 16,
    maxWidth: "50%",
    alignSelf: "center",
    marginTop: 30,
    color: "#686868",
    fontFamily: "inter,Arial,sans-serif",
  },
  tagline2: {
    fontSize: 16,
    maxWidth: "50%",
    alignSelf: "center",
    fontFamily: "inter,Arial,sans-serif",

    marginTop: 20,
    color: "#686868",
  },
  planButton: {
    backgroundColor: "#FF2D55",
    color: "white",
    border: "1px solid #d3d4d5",
    borderRadius: 8,
    boxShadow: "none",
    "&.MuiButton-contained:hover": {
      border: "1px solid #FF2D55",
      color: "#FF2D55",
    },
  },
  pricingCards: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    marginTop: 20,
    marginBottom: 20,
  },
  pauseOrCancel: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    width: "100%",
  },
  "@media (max-width: 640px)": {
    heading: {
      alignSelf: "flex-start",
      textAlign: "center",
      lineHeight: 1,
    },
    tagline: {
      maxWidth: "100%",
    },
    tagline2: {
      maxWidth: "100%",
    },
    pricingCards: {
      flexDirection: "column",
    },
    pauseOrCancel: {
      flexDirection: "column",
    },
    currentPlanContainer: {
      width: "90%",
    },
  },
}));

function PaymentPlans(props) {
  const classes = useStyles();
  const { height } = useWindowDims();

  const [selectedPlan, setSelectedPlan] = useState(null);
  const [totalBill, setTotalBill] = useState(0);
  const [StripeSubscriptionId, setStripeSubscriptionId] = useState(null);

  //rendering flag
  const [changingPlan, setChangingPlan] = useState(false);
  const [ShowPricingPlans, setShowPricingPlans] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);

  //loading flag
  const [isModalLoading, setIsModalLoading] = useState(false);

  //refs
  const allPlansRef = useRef(null);
  const paymentFormRef = useRef(null);

  const dispatch = useDispatch();

  //Reducer data
  const totalAgentsSelected = useSelector(
    (state) => state.subs.totalAgentsSelected
  );
  const currentBill = useSelector((state) => state.subs.currentBill);
  const planFrequency = useSelector((state) => state.subs.planFrequency);
  const userData = useSelector((state) => state.auth.userData);
  const role = useSelector((state) => state.auth.role);

  const subscriptionPlanStripeId = userData?.user?.stripeSubscriptionId;
  const subscriptionPlanDetails = userData?.user?.stripeSubscriptionDetails;

  useEffect(() => {
    var check = moment(subscriptionPlanDetails?.startDate, "YYYY/MM/DD").format(
      "Do"
    );
    var check2 = moment(
      subscriptionPlanDetails?.startDate,
      "YYYY/MM/DD"
    ).format("MMMM");
    // var month = check.format("M");
    // var day = check.format("D");
    // var year  = check.format('YYYY');
    console.log({ check, check2, subscriptionPlanDetails });
  }, [subscriptionPlanDetails]);
  useEffect(() => {
    if (userData) {
      // console.log({ userData });
      setStripeSubscriptionId(userData.user.stripeSubscriptionId);
      if (
        userData.user.stripeSubscriptionDetails &&
        userData?.user?.stripeSubscriptionId
      ) {
        setShowPricingPlans(false);
      }
    }
  }, [userData]);

  useEffect(() => {
    if (selectedPlan) {
      // console.log({ selectedPlan });
      dispatch({ type: "SET_CURRENT_PLAN", payload: selectedPlan });
    }
    if (paymentFormRef) paymentFormRef.current?.scrollIntoView();
    // eslint-disable-next-line
  }, [selectedPlan]);

  useEffect(() => {
    if (allPlansRef) allPlansRef.current?.scrollIntoView();
  }, [ShowPricingPlans]);
  const switchPlan = (agents) => {
    // console.log({ agents });
    if (agents === 3) {
      setSelectedPlan(GROW_PLAN);
    } else if (agents === 6) {
      setSelectedPlan(SCALE_PLAN);
    } else {
      console.log("noo need to switch");
    }
  };
  const handleCancelSubscription = async () => {
    // console.log({ StripeSubscriptionId });
    setIsModalLoading(true);
    const cancelResponse = await cancelSubscription(
      StripeSubscriptionId,
      userData.user._id
    );
    if (cancelResponse) {
      // console.log({ cancelResponse });
      const updatedUser = {
        token: userData.token,
        user: cancelResponse.company,
        role: role,
      };
      toast.info("You have been unsbscribed from ReportAnon", {
        position: "top-right",
        autoClose: 15000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsModalLoading(false);
      dispatch({ type: "UPDATE_USER_DATA", payload: updatedUser });
      setIsModalOpen(false);
      setShowPricingPlans(true);
    }
  };
  const handlePauseSubscription = async () => {
    setIsModalLoading(true);
    // console.log({ StripeSubscriptionId });
    const pauseResponse = await pauseSubscription(
      StripeSubscriptionId,
      userData.user._id
    );
    if (pauseResponse) {
      console.log({ pauseResponse });
      const updatedUser = {
        token: userData.token,
        user: pauseResponse.company,
        role: role,
      };
      toast.info("Your ReportAnon subscription has been paused", {
        position: "top-right",
        autoClose: 15000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsModalLoading(false);
      dispatch({ type: "UPDATE_USER_DATA", payload: updatedUser });
      setIsModalOpen(false);
      // setShowPricingPlans(true);
    }
  };
  const handleResumeSubscription = async () => {
    setIsModalLoading(true);
    // console.log({ StripeSubscriptionId });
    const resumeResponse = await resumeSubscription(
      StripeSubscriptionId,
      userData.user._id
    );
    if (resumeResponse) {
      console.log({ resumeResponse });
      const updatedUser = {
        token: userData.token,
        user: resumeResponse.company,
        role: role,
      };
      toast.info("Your ReportAnon subscription has been resumed", {
        position: "top-right",
        autoClose: 15000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsModalLoading(false);
      dispatch({ type: "UPDATE_USER_DATA", payload: updatedUser });
      setIsModalOpen(false);
      // setShowPricingPlans(true);
    }
  };
  const calculateRealTimeTotalCost = () => {
    return planFrequency === YEARLY_PLAN
      ? getNumberBefore(currentBill * 12) +
          "." +
          (getNumberAfter(currentBill * 12) === null
            ? ""
            : getNumberAfter(currentBill * 12))
      : getNumberBefore(currentBill) + "." + (getNumberAfter(currentBill) || 0);
  };
  const calculateArchivedTotalCost = () => {
    // console.log({ subscriptionPlanDetails });
    return subscriptionPlanDetails.planFrequency === YEARLY_PLAN
      ? getNumberBefore(subscriptionPlanDetails.price * 12) +
          "." +
          (getNumberAfter(currentBill * 12) === null
            ? ""
            : getNumberAfter(currentBill * 12))
      : getNumberBefore(subscriptionPlanDetails.price) +
          "." +
          (getNumberAfter(subscriptionPlanDetails.price) || 0);
  };
  const getTrailPeriod = (date) => {
    return moment(moment(date).add(14, "days")).fromNow();
  };
  return (
    <div className={classes.mainContainer} style={{ height: height }}>
      <MenuIcon
        className="back-button"
        onClick={() => {
          console.log("back");
          props.setShowList(true);
          props.setShowHead(true);
          // props.setType("assigned");
        }}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          fontSize: 30,
          margin: 10,
        }}
      />
      {changingPlan ? (
        <>
          <div className={classes.currentPlanContainer}>
            <Typography className={classes.heading}>Current Plan</Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography className={classes.tagline2}>
                Subscription Plan: <span>{selectedPlan?.name}</span>
              </Typography>
              <Typography className={classes.tagline2}>
                Number of Customer Agents: <span>{totalAgentsSelected}</span>
              </Typography>
              <Typography className={classes.tagline2}>
                Total Cost: €<span>{calculateRealTimeTotalCost()}</span>+ vat /
                {planFrequency}
              </Typography>
              <Typography className={classes.tagline2}>
                {planFrequency === YEARLY_PLAN
                  ? `Billed on the ${moment(
                      subscriptionPlanDetails?.startDate,
                      "YYYY/MM/DD"
                    ).format("Do")} of ${moment(
                      subscriptionPlanDetails?.startDate,
                      "YYYY/MM/DD"
                    ).format("MMMM")} every year`
                  : `Billed on the ${moment(
                      subscriptionPlanDetails?.startDate,
                      "YYYY/MM/DD"
                    ).format("Do")} of every month`}
              </Typography>

              <div
                style={{
                  width: "60%",
                  alignSelf: "center",
                  margin: 10,
                  marginTop: 20,
                }}
              >
                <Button
                  variant="contained"
                  className={classes.planButton}
                  onClick={() => {
                    if (allPlansRef) {
                      allPlansRef.current?.scrollIntoView();
                    } else {
                      console.log("no ref");
                    }
                  }}
                >
                  Change plan
                </Button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {" "}
          {subscriptionPlanStripeId && subscriptionPlanDetails ? (
            <div className={classes.currentPlanContainer}>
              <Typography className={classes.heading}>Current Plan</Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography className={classes.tagline2}>
                  Subscription Plan: <span>{subscriptionPlanDetails.plan}</span>
                </Typography>
                <Typography className={classes.tagline2}>
                  Number of Customer Agents:{" "}
                  <span>{subscriptionPlanDetails.selectedAgents}</span>
                </Typography>
                <Typography className={classes.tagline2}>
                  Total Cost: €<span>{calculateArchivedTotalCost()}</span>+ vat
                  /
                  {subscriptionPlanDetails.planFrequency === YEARLY_PLAN
                    ? "year"
                    : "month"}
                </Typography>
                <Typography className={classes.tagline2}>
                  {subscriptionPlanDetails.planFrequency === YEARLY_PLAN
                    ? `Billed on the ${moment(
                        subscriptionPlanDetails?.startDate,
                        "YYYY/MM/DD"
                      ).format("Do")} of ${moment(
                        subscriptionPlanDetails?.startDate,
                        "YYYY/MM/DD"
                      ).format("MMMM")} every year`
                    : `Billed on the ${moment(
                        subscriptionPlanDetails?.startDate,
                        "YYYY/MM/DD"
                      ).format("Do")} of every month`}
                </Typography>

                <div
                  style={{
                    width: "60%",
                    alignSelf: "center",
                    margin: 10,
                    marginTop: 20,
                  }}
                >
                  <Button
                    variant="contained"
                    className={classes.planButton}
                    onClick={() => {
                      setChangingPlan(true);
                      setShowPricingPlans(true);
                    }}
                  >
                    Change plan
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className={classes.currentPlanContainer}>
                <Typography className={classes.heading}>
                  Current Plan
                </Typography>

                <Typography className={classes.tagline2}>
                  Subscription Plan: <span>You are on a free trail</span>
                </Typography>
                <Typography className={classes.tagline2}>
                  Trail expires in:{" "}
                  <span style={{ color: "#30AE9F" }}>
                    {getTrailPeriod(userData?.user?.trail_started)}
                  </span>
                </Typography>
              </div>
            </>
          )}
        </>
      )}

      {ShowPricingPlans && (
        <div
          ref={allPlansRef}
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          {" "}
          <Typography className={classes.heading}>
            Choose Your Pricing Plan
          </Typography>
          <Typography className={classes.tagline}>
            Choose the pricing plan that suits based on the number of your
            customer agents who will respond to feedback
          </Typography>
          <div className={classes.pricingCards}>
            <PricingCards
              heading={START_PLAN.name}
              pricing={START_PLAN.price}
              cents={START_PLAN.cents}
              perks={START_PLAN.perks}
              plan={START_PLAN}
              subscribe={() => setSelectedPlan(START_PLAN)}
              selectedPlan={selectedPlan}
              totalBill={totalBill}
              setTotalBill={setTotalBill}
              switchPlan={switchPlan}
            />

            <PricingCards
              heading={SCALE_PLAN.name}
              pricing={SCALE_PLAN.price}
              cents={SCALE_PLAN.cents}
              perks={SCALE_PLAN.perks}
              plan={SCALE_PLAN}
              specialOffer
              subscribe={() => {
                setSelectedPlan(SCALE_PLAN);
              }}
              selectedPlan={selectedPlan}
              totalBill={totalBill}
              setTotalBill={setTotalBill}
              switchPlan={switchPlan}
            />
            <PricingCards
              heading={GROW_PLAN.name}
              pricing={GROW_PLAN.price}
              cents={GROW_PLAN.cents}
              perks={GROW_PLAN.perks}
              plan={GROW_PLAN}
              subscribe={() => {
                setSelectedPlan(GROW_PLAN);
              }}
              selectedPlan={selectedPlan}
              totalBill={totalBill}
              setTotalBill={setTotalBill}
              switchPlan={switchPlan}
            />
          </div>
          {selectedPlan && (
            <PaymentForm
              plan={selectedPlan}
              setSelectedPlan={setSelectedPlan}
              ref={paymentFormRef}
            />
          )}
        </div>
      )}

      {StripeSubscriptionId && (
        <>
          <div
            className={classes.pncContainer}
            // style={ShowPricingPlans ? { marginTop: 100 } : {}}
          >
            {" "}
            <Typography className={classes.heading}>Pause or Cancel</Typography>
            <Typography className={classes.tagline}>
              Choose the pricing plan that suits based on the number of your
              customer agents who will respond to feedback
            </Typography>{" "}
            <div className={classes.pauseOrCancel}>
              <PauseOrCancelButton
                title={
                  userData?.user?.isSubscriptionPaused
                    ? "Resume Subscription"
                    : "Pause Subscription"
                }
                tagline={
                  userData?.user?.isSubscriptionPaused
                    ? "ReJoin anytime"
                    : "Pause & ReJoin anytime"
                }
                pause
                onClick={
                  userData?.user?.isSubscriptionPaused
                    ? () => {
                        setIsModalOpen(true);
                        setSubscriptionStatus("resuming");
                      }
                    : () => {
                        setIsModalOpen(true);
                        setSubscriptionStatus("pausing");
                      }
                }
              />
              <PauseOrCancelButton
                title="Cancel Subscription"
                tagline="Cancel & lose all messages"
                stop
                onClick={() => {
                  setIsModalOpen(true);
                  setSubscriptionStatus("canceling");
                }}
              />
            </div>
          </div>

          <InvoiceComponent />
        </>
      )}

      <ConfirmationModal
        open={isModalOpen}
        setOpen={setIsModalOpen}
        handleSubmit={
          subscriptionStatus === "canceling"
            ? handleCancelSubscription
            : subscriptionStatus === "resuming"
            ? handleResumeSubscription
            : handlePauseSubscription
        }
        custom
        modalHeader={
          subscriptionStatus === "canceling"
            ? "cancel your subscription"
            : subscriptionStatus === "resuming"
            ? "Resume your subscription"
            : "pause your subscription"
        }
        isModalLoading={isModalLoading}
      />
    </div>
  );
}

export default PaymentPlans;
