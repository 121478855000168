import { MONTHLY_PLAN } from "../../appConstants/subscriptionTiers";

const initState = {
  currentBill: 0,
  planFrequency: MONTHLY_PLAN,
  customerPaymentMethod: null,
  currentPlan: null,
  totalAgentsSelected: 0,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initState, action) => {
  switch (action.type) {
    case "SET_CURRENT_BILL":
      return (state = {
        ...state,
        currentBill: action.payload,
      });
    case "SET_PAYMENT_METHOD":
      return (state = {
        ...state,
        customerPaymentMethod: action.payload,
      });
    case "SET_PLAN_FREQUENCY":
      return (state = {
        ...state,
        planFrequency: action.payload,
      });
    case "SET_CURRENT_PLAN":
      return (state = {
        ...state,
        currentPlan: action.payload,
      });
    case "SET_TOTAL_AGENTS":
      return (state = {
        ...state,
        totalAgentsSelected: action.payload,
      });

    default:
      return state;
  }
};
