import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER,
});
export const getUsersInCompany = async (companyId) => {
  try {
    const response = await api.get(`/company/${companyId}/users`, {
      companyId,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return false;
  }
};
export const getUserCompany = async (companyId) => {
  // console.log({ companyId });
  try {
    const response = await api.get(`/company/${companyId}`, {
      companyId,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return false;
  }
};
export const fetchTempUser = async (email) => {
  // console.log({ companyId });
  try {
    const response = await api.post(`/company/fetchTempUser`, {
      email,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return false;
  }
};
export const deleteTempUser = async (id) => {
  console.log({ id });
  try {
    const response = await api.delete(`/company/tempUser/${id}`);
    return response.data;
  } catch (error) {
    console.log({ error });
    return false;
  }
};
export const fetchEuroVatRates = async (country) => {
  // console.log({ country });
  try {
    const response = await axios.post(
      `http://api.vatlookup.eu/rates/` + country
    );
    return response.data;
  } catch (error) {
    console.log({ error });
    return false;
  }
};
