import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER,
});
export const sendWhatsAppMessage = async (
  message,
  phoneNumber,
  userId,
  receiverId,
  agentName
) => {
  try {
    const response = await api.post("/message/", {
      message,
      sendTo: phoneNumber,
      frAgent: userId,
      receiver: receiverId,
      agentName,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return false;
  }
};
export const sendWhatsAppMediaMessage = async (
  message,
  phoneNumber,
  url,
  userId,
  receiverId,
  type,
  agentName
) => {
  // console.log({
  //   message,
  //   phoneNumber,
  //   url,
  //   userId,
  //   receiverId,
  //   type,
  //   agentName,
  // });
  try {
    const response = await api.post("/message/media", {
      mediaUrl: url,
      message,
      sendTo: phoneNumber,
      frAgent: userId,
      receiver: receiverId,
      type,
      agentName,
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    return false;
  }
};
