import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import LandingPage from "../pages/landingPage/LandingPage";
// import TestStartUpPage from "../pages/landingPage/TestStartUpPage";
import CompanyRegistration from "../pages/registration/CompanyRegistration";
import LoginPage from "../pages/registration/LoginPage";
import PasswordReset from "../pages/registration/PasswordReset";
import RegistrationPage from "../pages/registration/RegistrationPage";
import AddCompany from "../pages/setupPages/AddCompany";
import PdfSign from "../pages/setupPages/PdfSign";
import PdfSignDocument from "../pages/setupPages/PdfSignDocument";
import WhatsappRedirect from "../pages/setupPages/WhatsappRedirect";
import PrivateRoute from "./privateRoute";
import { useHistory } from "react-router";

/**
 * @author
 * @function RouteRedirect
 **/

const RouteRedirect = () => {
  const history = useHistory();
  useEffect(() => {
    return window.addEventListener("popstate", (event) => {
      console.log("back");
      history.go(1);
    });

    // eslint-disable-next-line
  }, []);
  return (
    <div style={{ height: "100%" }}>
      <Route path="/login" exact component={LoginPage} />
      <Route
        path="/whatsAppRedirect/:redirectCode"
        exact
        component={WhatsappRedirect}
      />
      <Route path="/addCompany" exact component={AddCompany} />
      <Route path="/editSign" exact component={PdfSign} />
      <Route path="/pdfSign" exact component={PdfSignDocument} />

      <Route
        path="/register/:receivedEmail"
        exact
        component={RegistrationPage}
      />
      <Route path="/passwordReset/:userId" exact component={PasswordReset} />
      <Route
        path="/CompanyRegistration/"
        exact
        component={CompanyRegistration}
      />
      <PrivateRoute path="/" exact component={LandingPage} />
    </div>
  );
};

export default RouteRedirect;
