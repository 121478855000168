import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import "./styles.css";
import QRCode from "qrcode.react";
import MenuIcon from "@material-ui/icons/Menu";
import { useDispatch, useSelector } from "react-redux";
import { useWindowDims } from "../../utils/useDimensions";
// import { toast } from "react-toastify";
import { toJpeg } from "html-to-image";

import { uploadFilesToServer } from "../../utils/helperFunctions";
import { css } from "@emotion/react";
import HashLoader from "react-spinners/HashLoader";

const override = css`
  display: block;
  margin: 0 auto;
`;
const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100vh",
    overflowY: "scroll !important",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      borderRadius: "5px",
    },
    scrollBehavior: "smooth !important",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  inputField: {
    fontFamily: "Montserrat",
    background: "none",
    border: "none",
    color: "white",
    textAlign: "center",
    maxWidth: "60%",
    marginRight: 30,
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    marginTop: 20,
    justifyContent: "space-evenly",
  },
  content_row: {
    display: "flex",
    width: "100%",
    justifyContent: "space-evenly",
    marginTop: 20,
  },
  inputField_tagline: {
    fontFamily: "Kollektif-Bold",
    src: 'url("../../fonts/Kollektif-Bold.ttf") format("truetype")',
    fontStyle: "normal",
    background: "none",
    border: "none",
    fontWeight: 700,
    color: "white",
    textAlign: "center",

    overflowY: "scroll !important",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      borderRadius: "5px",
    },
    scrollBehavior: "smooth !important",
  },
  inputField_details: {
    background: "none",
    border: "none",
    fontSize: 22,
    fontWeight: 700,
    color: "black",
    textAlign: "right",
    overflow: "hidden",
    width: "60%",
  },
  inputField_scan: {
    fontFamily: "Bebas Neue",
    background: "none",
    border: "none",
    color: "white",
    fontWeight: 500,
    width: "60%",
    textAlign: "right",
    alignSelf: "flex-end",
    overflowX: "hidden",
  },
  footer: {
    fontFamily: "Bebas Neue",
    width: "100%",
    backgroundColor: "#ffc252",
    padding: 40,
    display: "flex",
    flexDirection: "column",
  },
}));
function PdfSign(props) {
  const classes = useStyles();

  const [companyName, setCompanyName] = useState("Tell us what you think");
  const [TagLine, setTagLine] = useState(
    `We love feedback. Talk to us 100% anonymously using WhatsApp. Just scan this QR code & we'll get back to you`
  );

  // eslint-disable-next-line
  const [whatsAppLink, setWhatsAppLink] = useState(
    "https://master.deo9rdy1inx1u.amplifyapp.com/"
  );
  const [qrCodeLink, setQrCodeLink] = useState("");
  // const [qrCodeContent, setQrCodeContent] = useState(
  //   "https://api.whatsapp.com/send?phone=12062027131&text=Hi,+I+would+like+to+send+anonymous+feedback+to+-+"
  // );
  const [qrCodeContent, setQrCodeContent] = useState(
    "https://app.reportanon.com/whatsAppRedirect/"
  );
  const [backgroundUrl, setBackgroundUrl] = useState(
    "https://reportanonstorage.s3.eu-west-1.amazonaws.com/1632479440551-NewSignBack.png"
  );
  const [logoUrl, setLogoUrl] = useState(
    "https://reportanonstorage.s3.eu-west-1.amazonaws.com/1632479440551-NewSignBack.png"
  );
  const [backgroundToUpload, setBackgroundToUpload] = useState(null);
  const [LogoToUpload, setLogoToUpload] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const ref = useRef(null);
  const changeBackground = useRef(null);
  const changeLogo = useRef(null);

  const dispatch = useDispatch();
  const { width, height } = useWindowDims();

  const userData = useSelector((state) => state.auth.userData);

  useEffect(() => {
    console.log({ userData, qrCodeContent });
    // let tempCode =
    //   qrCodeContent +
    //   "" +
    //   userData.user.name.split(" ").join("+") +
    //   "+-+" +
    //   userData.user.message_routing_id;
    // console.log({ tempCode });
    if (!qrCodeContent.includes(userData.user.message_routing_id)) {
      let tempCode = qrCodeContent + userData.user.message_routing_id;
      console.log({ tempCode });
      setQrCodeContent(tempCode);
    }
    setWhatsAppLink(userData.user.whatsAppLink);

    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (ref.current !== null) {
      toJpeg(ref.current).then(function (dataUrl) {
        console.log("setting link");
        setQrCodeLink(dataUrl);
      });
    }
  }, [ref, qrCodeContent]);
  useEffect(() => {
    if (backgroundToUpload) {
      console.log({ backgroundToUpload });
      setBackground(backgroundToUpload, setBackgroundUrl);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backgroundToUpload]);
  useEffect(() => {
    if (LogoToUpload) {
      console.log({ LogoToUpload });
      setBackground(LogoToUpload, setLogoUrl);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [LogoToUpload]);
  const printDocument = () => {
    console.log({
      companyName,
      TagLine,
      whatsAppLink,
      qrCodeLink,
      backgroundUrl,
      logoUrl,
    });
    const pdfData = {
      companyName,
      TagLine,
      whatsAppLink: userData.user.message_routing_id,
      qrCodeLink,
      backgroundUrl,
      logoUrl,
    };

    dispatch({ type: "SET_PDF_DATA", payload: pdfData });
    const win = window.open("pdfSign", "_blank");
    win.focus();
  };
  const onBackgroundSelected = (event) => {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];
    // console.log({ file });
    setBackgroundToUpload(file);
  };
  const onLogoSelected = (event) => {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];
    // console.log({ file });
    setLogoToUpload(file);
  };
  const setBackground = async (file, setData) => {
    const backgroundImage = await uploadFilesToServer(file, setIsLoading);
    setData(backgroundImage);
  };
  const downloadQrCode = async () => {
    if (ref.current !== null) {
      toJpeg(ref.current).then(function (dataUrl) {
        var link = document.createElement("a");
        link.download = "QRcode.jpeg";
        link.href = dataUrl;
        link.click();
      });
    }
  };
  return (
    <div
      className={classes.mainContainer}
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0.5)), url(${backgroundUrl})`,
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <MenuIcon
          className="back-button"
          onClick={() => {
            console.log("back");
            props.setShowList(true);
            props.setShowHead(true);
            // props.setType("assigned");
          }}
          style={{
            alignSelf: "baseline",
            fontSize: 30,
            margin: 10,
            color: "white",
          }}
        />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#FF2E56",
              color: "white",
              paddingLeft: 20,
              paddingRight: 20,
              alignSelf: "center",
            }}
            onClick={printDocument}
          >
            View PDF
          </Button>
        </div>
      </div>

      {/* Header */}
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <textarea
          type="text"
          id="PreventChromeAutocomplete"
          name="hidden"
          autoComplete="false"
          className={classes.inputField}
          onChange={(e) => {
            setCompanyName(e.target.value);
          }}
          multiple
          value={companyName.toUpperCase()}
          style={{
            fontSize: width * 0.06,
            alignSelf: "center",
          }}
        />
        {isLoading ? (
          <div style={{ margin: 20 }}>
            <HashLoader color="white" loading={true} css={override} size={30} />
          </div>
        ) : (
          <img
            src={logoUrl}
            alt="Nothing to show"
            style={{
              width: width * 0.2,
              height: width * 0.1,
              position: "absolute",
              right: 0,
              top: 0,
              objectFit: "contain",
              lineHeight: 50,
            }}
          />
        )}
      </div>
      <div style={{ width: "90%", borderBottom: "4px solid #F1F1F1" }}></div>

      <Grid container className={classes.contentContainer}>
        {/* middle  */}
        <div
          className={classes.content_row}
          style={{ flexDirection: width > 700 ? "row" : "column" }}
        >
          <textarea
            type="text"
            id="PreventChromeAutocomplete"
            name="hidden"
            autoComplete="false"
            className={classes.inputField_tagline}
            onChange={(e) => {
              setTagLine(e.target.value);
            }}
            value={TagLine}
            multiple
            style={{
              fontSize: width > 700 ? width * 0.035 : 30,
              width: width > 700 ? "65%" : "95%",
              height: width > 700 ? height * 0.45 : height * 0.35,
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: width < 700 ? 20 : 0,
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                padding: 10,
              }}
              ref={ref}
            >
              <QRCode
                value={qrCodeContent}
                size={width > 700 ? width * 0.15 : width * 0.35}
                renderAs="svg"
              />
            </div>
          </div>
        </div>

        {/* link  */}

        <Typography
          style={{
            fontSize: width > 700 ? width * 0.03 : 20,
            fontFamily: "Kollektif-Bold",
            src: 'url("../../fonts/Kollektif-Bold.ttf") format("truetype")',
            fontWeight: 700,
            fontStyle: "normal",
            alignSelf: "center",
            color: "white",
            textAlign: "center",
          }}
        >
          or visit https://ReportAnon.com/{userData.user.message_routing_id}
        </Typography>

        {/* footer  */}
        <Typography
          style={{
            fontSize: width > 700 ? width * 0.02 : 20,
            fontFamily: "Kollektif-Bold",
            src: 'url("../../fonts/Kollektif-Bold.ttf") format("truetype")',
            fontWeight: 700,
            fontStyle: "normal",
            alignSelf: "center",
            color: "#E6CF00",
            textAlign: "center",
            marginTop: 20,
          }}
        >
          Anonymity Powered By ReportAnon.com
        </Typography>

        <Button
          variant="contained"
          style={{
            backgroundColor: "#FF2E56",
            color: "white",
            paddingLeft: 20,
            paddingRight: 20,
            alignSelf: width > 700 ? "baseline" : "center",
            marginBottom: 10,
          }}
          disabled={isLoading}
          onClick={() => {
            changeBackground.current.click();
          }}
        >
          Change background
        </Button>
        <Button
          variant="contained"
          style={{
            backgroundColor: "#FF2E56",
            color: "white",
            paddingLeft: 20,
            paddingRight: 20,
            alignSelf: width > 700 ? "baseline" : "center",

            marginBottom: 10,
          }}
          disabled={isLoading}
          onClick={() => {
            changeLogo.current.click();
          }}
        >
          Change Logo
        </Button>
        <Button
          variant="contained"
          style={{
            backgroundColor: "#FF2E56",
            color: "white",
            paddingLeft: 20,
            paddingRight: 20,
            alignSelf: width > 700 ? "baseline" : "center",

            marginBottom: 10,
          }}
          onClick={downloadQrCode}
        >
          Download Qr code
        </Button>
        <input
          type="file"
          id="file"
          ref={changeBackground}
          style={{ display: "none" }}
          onChange={onBackgroundSelected}
          accept="image/*"
        />
        <input
          type="file"
          id="file"
          ref={changeLogo}
          style={{ display: "none" }}
          onChange={onLogoSelected}
          accept="image/*"
        />
      </Grid>
    </div>
  );
}

export default PdfSign;
