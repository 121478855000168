import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import CssBaseline from "@material-ui/core/CssBaseline";
import logo from "../../assets/ReportAnonLogoRed.png";
import { updatePassword } from "../../apiCalls/AuthenticationApi";

const useStyles = makeStyles((theme) => ({
  hideOnSm: { [theme.breakpoints.down("sm")]: { display: "none" } },
  mainContainer: {
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgb(247, 249, 252)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  loginContainer: {
    backgroundColor: "white",
    boxShadow: "rgb(0 0 0 / 9%) 0px 0.5rem 1rem !important",
    borderRadius: 10,
    padding: "2.25rem",
    width: "25%",
    [theme.breakpoints.down("xs")]: { width: "90%" },
  },
  "@media (max-width: 640px)": {
    loginContainer: {
      maxWidth: "90%",
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),

    borderRadius: 30,
    backgroundColor: "#30AE9F",
  },
  registrationText: {
    fontSize: 36,
    fontWeight: "bold",
    color: "#333333",
  },
}));

const PasswordReset = (props) => {
  const classes = useStyles();
  const { userId } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handlePasswordReset = async () => {
    console.log({ userId, password });
    if (password === confirmPassword) {
      const resetResponse = await updatePassword(userId, password);
      if (resetResponse) {
        console.log({ resetResponse });
        toast.info("Your password has been reset", {
          position: "top-right",
          autoClose: 15000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        props.history.push("/login");
      } else {
        toast.error("Something went wrong", {
          position: "top-right",
          autoClose: 15000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };
  return (
    <div className={classes.mainContainer}>
      <CssBaseline />
      <div className={classes.loginContainer}>
        <img src={logo} alt="Mountain" style={{ width: 150 }} />

        {/* <Typography className={classes.registrationText}>Sign In</Typography> */}
        <form className={classes.form} noValidate>
          <TextField
            margin="normal"
            required
            fullWidth
            name="New Password"
            label="New Password"
            type="password"
            id="password"
            // autoComplete="current-password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            variant="outlined"
            style={{
              fontFamily: "inter,Arial,sans-serif",
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="Confirm new password"
            label="Confirm new password"
            type="password"
            id="Confirm new password"
            value={confirmPassword}
            onChange={(e) => {
              setConfirmPassword(e.target.value);
            }}
            variant="outlined"
            style={{
              fontFamily: "inter,Arial,sans-serif",
            }}
          />
          <div style={{ padding: 10 }}>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#FF2E56",
                color: "white",
                paddingLeft: 20,
                paddingRight: 20,
                fontFamily: "inter,Arial,sans-serif",
              }}
              className={classes.submit}
              onClick={handlePasswordReset}
              fullWidth
            >
              Reset password
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PasswordReset;
