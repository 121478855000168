import React, { useEffect, useRef, useState } from "react";
import MessageLeft from "./MessageLeft";
import MessageRight from "./MessageRight";
import "../miscStyles.css";
import { Typography } from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {
  getPreSignedLink,
  uploadFile,
} from "../../../apiCalls/AuthenticationApi";
import { useSelector } from "react-redux";
import {
  NAVIGATION_TYPE,
  SENDER_NAME,
  USER_TYPE,
} from "../../../appConstants/constants";
import {
  assignConversation,
  getConversationMessagesFromDb,
} from "../../../apiCalls/conversationApi";
import SelectAgentModal from "../../modalComponents/SelectAgentModal";
import { toast } from "react-toastify";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import HashLoader from "react-spinners/HashLoader";
const override = css`
  display: block;
  margin: 0 auto;
`;

const useStyles = makeStyles((theme) => ({
  hideOnSm: { [theme.breakpoints.down("sm")]: { display: "none" } },
  customScroll: {
    overflow: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      borderRadius: "5px",
    },
  },
  messagesContainer: {
    display: "flex",
    // flex: 1,
    flexDirection: "column",
    height: "58vh",
    overflow: "scroll",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      borderRadius: "5px",
    },
    scrollBehavior: "smooth",

    // [theme.breakpoints.down("sm")]: { height: "50vh" },
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  // "@media (max-height: 700px)": {
  //   messagesContainer: {
  //     height: "45vh",
  //   },
  // },
  // "@media (max-height: 750px)": {
  //   messagesContainer: {
  //     height: "45vh",
  //   },
  // },
  // "@media (min-height: 800px)": {
  //   messagesContainer: {
  //     height: "65vh",
  //   },
  // },
}));

function InboxBody(props) {
  const classes = useStyles();
  const { doesConversationExist } = props;
  const [filesToUpload, setFilesToUpload] = useState(null);
  const [allMessages, setAllMessages] = useState([]);
  const [conversation, setConversation] = useState(null);
  const [openAgentSelection, setOpenAgentSelection] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldScrollToTop, setShouldScrollToTop] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLoading, setPageLoading] = useState(false);

  const userData = useSelector((state) => state.auth.userData);
  const currentMessages = useSelector((state) => state.data.currentMessages);
  const currentSender = useSelector((state) => state.data.currentSender);
  const currentConversation = useSelector(
    (state) => state.data.currentConversation
  );
  const role = useSelector((state) => state.auth.role);

  const scrollRef = useRef(null);
  const scrollTopRef = useRef(null);
  const inputFile = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (currentMessages) {
      setAllMessages(currentMessages);
    }
  }, [currentMessages, currentSender, currentConversation]);
  useEffect(() => {
    if (currentConversation) {
      setPageNumber(1);
      setConversation(currentConversation);
    }
  }, [currentConversation]);

  useEffect(() => {
    if (filesToUpload) {
      uploadFilesToServer();
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesToUpload]);
  useEffect(() => {
    if (!shouldScrollToTop) {
      scrollTopRef.current.scrollTo(0, 2);
      setShouldScrollToTop(true);
    } else {
      if (scrollRef) {
        scrollRef.current.scrollIntoView();
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allMessages]);

  const onFileSelected = (event) => {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];
    // console.log({ file });
    setFilesToUpload(file);
  };

  const uploadFilesToServer = async () => {
    setIsLoading(true);
    const response = await getPreSignedLink({
      name: filesToUpload.name,
      type: filesToUpload.type,
    });
    if (response) {
      // console.log({ response });
      const uploadFileResult = await uploadFile({
        url: response,
        file: filesToUpload,
        type: filesToUpload.type,
      });
      console.log("Uploaded : ", JSON.stringify(uploadFileResult, null, 2));
      const url = response.split("?")[0];
      // console.log({ url });
      props.handleSendMediaMessage(url, filesToUpload.type);
      setIsLoading(false);
    } else {
      setIsLoading(false);

      console.log("error while generating link...");
    }
  };

  const handleAssignment = async () => {
    // console.log("assigning..");
    const response = await assignConversation(
      userData.user._id,
      currentConversation?._id
    );
    if (response) {
      console.log({ response });
      setConversation(response.conversation);
      props.setAllConversation((prevData) =>
        prevData.filter((e) => e._id !== currentConversation?._id)
      );
      props.setValue("Assigned");
    }
  };
  const handleAssignmentToAgent = async () => {
    console.log("assigning to agents..");
    setOpenAgentSelection(true);
  };
  const scrollCheck = async (e) => {
    if (e.target.scrollTop === 0) {
      console.log("at top");
      setPageLoading(true);
      const response = await getConversationMessagesFromDb(
        currentConversation?._id,
        pageNumber,
        20
      );
      if (response) {
        // console.log({ messages: response.messages, allMessages, pageNumber });
        setShouldScrollToTop(false);
        setPageNumber(pageNumber + 1);
        setAllMessages(response?.messages?.concat(allMessages));
        setPageLoading(false);
      } else {
        setPageLoading(false);
      }
    }
  };

  return (
    <div
      style={{
        width: "100%",
        flex: "auto",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {currentConversation && (
        <Typography
          style={{
            fontSize: 16,
            fontWeight: 600,
            marginTop: 10,
            fontFamily: "inter,Arial,sans-serif",
          }}
        >
          Ticket No :
          {currentConversation?.ticket_number ||
            "No ticket assigned this is an older conversation"}
        </Typography>
      )}

      {pageLoading && (
        <div style={{ margin: 20 }}>
          <HashLoader color="black" loading={true} css={override} size={30} />
        </div>
      )}

      <div
        style={{
          flex: "auto",
        }}
        className={classes.messagesContainer}
        onScroll={scrollCheck}
        ref={scrollTopRef}
      >
        {allMessages.map((element, index) => (
          <div key={index}>
            {element?.from?.includes(SENDER_NAME) ? (
              <MessageRight data={element} currentSender={userData} />
            ) : (
              <MessageLeft data={element} currentSender={currentSender} />
            )}
          </div>
        ))}
        <div ref={scrollRef}></div>
      </div>

      {currentConversation?.status !== "assigned" &&
      currentConversation?.status !== "new" ? (
        <>
          <Typography
            style={{
              fontSize: 20,
              fontWeight: "bold",
              fontFamily: "inter,Arial,sans-serif",
            }}
          >
            {currentConversation?.status === "closed"
              ? "Conversation Closed"
              : currentConversation?.status === "spam"
              ? "This has been marked as spam"
              : ""}
          </Typography>
        </>
      ) : (
        <>
          {!doesConversationExist && props.type !== "topics" ? (
            <Typography
              style={{
                fontSize: 20,
                fontWeight: "bold",
                fontFamily: "inter,Arial,sans-serif",
              }}
            >
              There are no messages yet
            </Typography>
          ) : (
            <>
              {" "}
              {conversation?.assigned_to ? (
                <>
                  {props.type !== NAVIGATION_TYPE.USER_PROFILE && (
                    <div className="message-input-box">
                      <textarea
                        ref={inputRef}
                        type="textarea"
                        id="PreventChromeAutocomplete"
                        name="hidden"
                        autoComplete="false"
                        onSubmit={props.handleSendMessage}
                        className="message-input-field"
                        placeholder="Type your message"
                        value={props.typedMessage}
                        onChange={(event) => {
                          props.setTypedMessage(event.target.value);
                        }}
                        onKeyPress={(event) => {
                          if (event.key === "Enter") {
                            props.handleSendMessage();
                          }
                        }}
                        r
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          padding: 10,
                          alignItems: "center",
                        }}
                      >
                        {props.isMediaLoading || isLoading ? (
                          <ClipLoader
                            color="#FF2E56"
                            loading={true}
                            css={override}
                            size={30}
                          />
                        ) : (
                          <>
                            <AttachFileIcon
                              style={{
                                fontSize: 23,
                                marginRight: 10,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                inputFile.current.click();
                              }}
                            />
                            <input
                              type="file"
                              id="file"
                              ref={inputFile}
                              style={{ display: "none" }}
                              onChange={onFileSelected}
                            />

                            <div
                              className="send-button"
                              onClick={
                                props.typedMessage !== ""
                                  ? props.handleSendMessage
                                  : () => {
                                      console.log({
                                        typed: props.typedMessage,
                                      });
                                      toast.warn(
                                        "Please enter a message to send"
                                      );
                                    }
                              }
                            >
                              <SendIcon style={{ fontSize: 20 }} />
                              <Typography
                                style={{
                                  marginLeft: 10,
                                  marginRight: 10,
                                  fontFamily: "inter,Arial,sans-serif",
                                }}
                              >
                                Send
                              </Typography>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div>
                  {role === USER_TYPE.COMPANY ? (
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#FF2E56",
                        color: "white",
                        paddingLeft: 20,
                        paddingRight: 20,
                      }}
                      className={classes.submit}
                      onClick={handleAssignmentToAgent}
                    >
                      Assign to agent
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#FF2E56",
                        color: "white",
                        paddingLeft: 20,
                        paddingRight: 20,
                      }}
                      className={classes.submit}
                      onClick={handleAssignment}
                    >
                      Assign to me
                    </Button>
                  )}
                </div>
              )}
            </>
          )}
        </>
      )}
      <SelectAgentModal
        open={openAgentSelection}
        setOpen={setOpenAgentSelection}
        conversation={currentConversation?._id}
        setConversation={setConversation}
        setAllConversation={props.setAllConversation}
        value={props.value}
        setValue={props.setValue}
      />
    </div>
  );
}

export default InboxBody;
