import React from "react";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core";
import StripeCardInputField from "../SubscriptionComponents/StripeCardInputField";
import { useWindowDims } from "../../utils/useDimensions";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    // width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
  },
  submitYes: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "white",
    color: "black",
    paddingLeft: 20,
    paddingRight: 20,
    marginRight: 20,
    border: "2px solid #000",
  },
  submitNo: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#FF2E56",
    color: "white",
    paddingLeft: 20,
    paddingRight: 20,
    marginLeft: 20,
  },
  buttonDiv: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
}));
function AddPaymentModal(props) {
  const classes = useStyles();

  const { width } = useWindowDims();
  return (
    <div>
      <Modal
        open={props.open}
        onClose={() => {
          props.setOpen(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          style={
            width > 1268
              ? {
                  top: "50%",
                  left: "50%",
                  transform: `translate(-${50}%, -${50}%)`,
                  width: "50%",
                }
              : {
                  top: "50%",
                  left: "50%",
                  transform: `translate(-${50}%, -${50}%)`,
                  width: "90%",
                }
          }
          className={classes.paper}
        >
          <h3 id="simple-modal-title" style={{ textAlign: "center" }}>
            Credit card details
          </h3>
          <StripeCardInputField />
        </div>
      </Modal>
    </div>
  );
}

export default AddPaymentModal;
