import React, { useState } from "react";
import "../miscStyles.css";
import Avatar from "react-avatar";
import { Typography } from "@material-ui/core";
import ReactPlayer from "react-player/lazy";
import moment from "moment";
import GoogleMapReact from "google-map-react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app
import { useSelector } from "react-redux";
import { useWindowDims } from "../../../utils/useDimensions";

function MessageLeft(props) {
  const { data } = props;
  const [openLightbox, setOpenLightbox] = useState(false);
  // console.log({ messageData: props.currentSender?.name });
  const searchedMessage = useSelector((state) => state.data.searchedMessage);
  const { width } = useWindowDims();

  return (
    <div style={{ alignSelf: "flex-start", marginBottom: 10, width: "100%" }}>
      <div className="message-styling">
        {/* <Avatar
          name={props.currentSender?.name || "anonymous"}
          size={30}
          round
        /> */}
        <Avatar name="A N" size={30} round />

        {data?.message_type?.split("/")[0] === "image" ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <img
              src={data.media_uri}
              alt=""
              style={{ maxHeight: 200, maxWidth: 200, cursor: "pointer" }}
              onClick={() => {
                setOpenLightbox(true);
              }}
            />
            <Typography
              style={{
                marginLeft: 10,
                wordBreak: "break-word",
                textAlign: "left",
                fontFamily: "inter,Arial,sans-serif",
              }}
            >
              {data?.message || ""}
            </Typography>
            {openLightbox && (
              <Lightbox
                mainSrc={data.media_uri}
                onCloseRequest={() => setOpenLightbox(false)}
              />
            )}
          </div>
        ) : data?.message_type?.split("/")[0] === "video" ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <ReactPlayer
              url={data.media_uri}
              width={width > 400 ? "300px" : "250"}
              height="200px"
              controls
            />
            <Typography
              style={{
                marginLeft: 10,
                wordBreak: "break-word",
                textAlign: "left",
                fontFamily: "inter,Arial,sans-serif",
              }}
            >
              {data?.message || ""}
            </Typography>
          </div>
        ) : data?.message_type?.split("/")[0] === "audio" ? (
          <>
            <ReactPlayer
              url={data.media_uri}
              width="300px"
              height="50px"
              controls
            />
          </>
        ) : data?.message_type?.split("/")[0] === "location" ? (
          <div style={{ height: "300px", width: "100%" }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: "ASK John" }}
              defaultCenter={{
                lat: 59.95,
                lng: 30.33,
              }}
              defaultZoom={11}
            >
              <AnyReactComponent
                lat={59.955413}
                lng={30.337844}
                text="My Marker"
              />
            </GoogleMapReact>
          </div>
        ) : data?.message_type?.split("/")[0] === "application" ? (
          <>
            <Typography
              style={{
                textAlign: "start",
                marginLeft: 10,
                color: "grey",
                fontFamily: "inter,Arial,sans-serif",
              }}
            >
              <a href={data?.media_uri} target="_blank" rel="noreferrer">
                {data?.message_type}
              </a>
            </Typography>
          </>
        ) : (
          <Typography
            style={
              data?.message
                ?.toLowerCase()
                .includes(searchedMessage?.toLowerCase())
                ? {
                    marginLeft: 10,
                    wordBreak: "break-word",
                    textAlign: "left",
                    fontFamily: "inter,Arial,sans-serif",
                    backgroundColor: "yellow",
                  }
                : {
                    marginLeft: 10,
                    wordBreak: "break-word",
                    textAlign: "left",
                    fontFamily: "inter,Arial,sans-serif",
                    // backgroundColor: "yellow",
                  }
            }
          >
            {data.message}
          </Typography>
        )}
      </div>
      <Typography
        style={{
          textAlign: "start",
          marginTop: 10,
          color: "grey",
          fontFamily: "inter,Arial,sans-serif",
        }}
      >
        {/* {data.from} - */}
        Anonymous - {moment(data.date).startOf("ss").fromNow()}
      </Typography>
    </div>
  );
}
const AnyReactComponent = ({ text }) => <div>{text}</div>;
export default MessageLeft;
