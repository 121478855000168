import React, { useEffect, useState } from "react";
import "../miscStyles.css";
import Avatar from "react-avatar";
import { Typography } from "@material-ui/core";
import { MESSAGE_CONSTANTS } from "../../../appConstants/constants";
import moment from "moment";
import { useWindowDims } from "../../../utils/useDimensions";
import { useDispatch } from "react-redux";

const SearchedMessages = (props) => {
  const { allConversation, data, setSelectedPhone } = props;
  const [foundConversation, setFoundConversation] = useState({});

  const { width } = useWindowDims();
  const dispatch = useDispatch();
  useEffect(() => {
    // console.log({ props });
    setFoundConversation(
      allConversation.find((element) => element._id === data.conversation)
    );

    // eslint-disable-next-line
  }, [props]);
  //   useEffect(() => {
  //     if (foundConversation) console.log({ foundConversation });
  //   }, [foundConversation]);

  const getFormattedString = (str) => {
    if (width < 700) {
      if (str?.length >= 46) {
        const formattedString = str.slice(0, 46) + "...";
        return formattedString;
      } else {
        return str;
      }
    } else {
      if (str?.length >= 28) {
        const formattedString = str.slice(0, 28) + "...";
        return formattedString;
      } else {
        return str;
      }
    }
  };

  const handleConversationCardClick = async () => {
    // console.log("clicked");
    // console.log({ data });
    props.setIsSelected(foundConversation?._id);
    setSelectedPhone(foundConversation?.sender?.phone);
    props.setShowList(false);
    dispatch({
      type: "SET_CURRENT_SENDER",
      payload: foundConversation?.sender,
    });
    dispatch({
      type: "SET_CURRENT_CONVERSATION",
      payload: foundConversation,
    });
  };
  return (
    <div
      style={{
        display: "flex",
        // width: "100%",
        cursor: "pointer",
        backgroundColor: "white",
        borderRadius: 10,
        padding: 10,
        alignItems: "center",
        marginLeft: 12,
        marginRight: 12,
        paddingBottom: 15,
        borderBottom: "1px solid #F1F1F1",
        marginTop: 5,
      }}
      onClick={handleConversationCardClick}
    >
      {/* <Avatar name={data?.sender?.name} size={30} round /> */}
      <Avatar name="A N" size={30} round />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          marginLeft: 10,
          width: "90%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              fontWeight: 600,
              fontSize: 15,
              fontFamily: "inter,Arial,sans-serif",
            }}
          >
            {/* <strong>{data?.sender?.phone}</strong> */}
            <strong>Anonymous</strong>
          </Typography>
          {foundConversation?.last_message_sender ===
            MESSAGE_CONSTANTS.WHATS_APP_USER && (
            <Typography
              style={{
                fontSize: 12,
                color: "darkgray",
                fontFamily: "inter,Arial,sans-serif",
              }}
            >
              <strong>1</strong>
            </Typography>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            width: "100%",
            alignItems: "baseline",
          }}
        >
          <Typography
            style={{
              fontSize: 14,
              color: "#000000De",
              textAlign: "left",
              fontFamily: "inter,Arial,sans-serif",
            }}
          >
            {getFormattedString(foundConversation?.subject) || "No subject"}
          </Typography>
          <Typography
            style={{
              fontSize: 14,
              color: "#74798e",
              fontFamily: "inter,Arial,sans-serif",
              wordBreak: "break-all",
              textAlign: "left",
            }}
          >
            {getFormattedString(data?.message)}
          </Typography>

          {/* <div className={data.isOnline ? "green-light" : ""}></div> */}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography
            style={{
              fontSize: 12,
              color: "#ABAEBE",
              fontFamily: "inter,Arial,sans-serif",
            }}
          >
            {moment(data?.date).startOf("ss").fromNow() + ""}
          </Typography>
          {foundConversation?.last_message_sender ===
            MESSAGE_CONSTANTS.WHATS_APP_USER && (
            <div
              style={{
                width: 9,
                height: 9,
                borderRadius: "50%",
                backgroundColor: "#14b29f",
              }}
            ></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchedMessages;
