import React from "react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { Typography } from "@material-ui/core";

function IconText(props) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "flex-start",
        marginBottom: 15,
      }}
    >
      {props.provided ? (
        <CheckCircleIcon style={{ fontSize: 18 }} />
      ) : (
        <RemoveCircleIcon style={{ fontSize: 18 }} />
      )}
      <Typography
        style={{
          fontSize: 14,
          color: "#686868",
          fontFamily: "inter,Arial,sans-serif",
          marginLeft: 10,
        }}
      >
        {props.text}
      </Typography>
    </div>
  );
}

export default IconText;
