import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";

const useStyles = makeStyles((theme) => ({
  hideOnSm: { [theme.breakpoints.down("sm")]: { display: "none" } },

  mainContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  "@media (max-width: 640px)": {
    mainContainer: {
      display: "none",
    },
  },
  registrationText: {
    fontSize: 19,
    fontWeight: 400,
    color: "rgba(85, 90, 100, 0.65)",
    marginLeft: 10,
  },
  checkIconContainer: {
    padding: 4,
    backgroundColor: "#E7F7F5",
    borderRadius: 20,
  },
}));

function RegistrationTagline() {
  const classes = useStyles();

  return (
    <div className={classes.mainContainer}>
      <div style={{ margin: 10, display: "flex" }}>
        <div className={classes.checkIconContainer}>
          <CheckIcon style={{ fontSize: 20, color: "#14B29F" }} />
        </div>
        <Typography className={classes.registrationText}>
          14-day free trail
        </Typography>
      </div>
      <div style={{ margin: 10, display: "flex" }}>
        <div className={classes.checkIconContainer}>
          <CheckIcon style={{ fontSize: 20, color: "#14B29F" }} />
        </div>
        <Typography className={classes.registrationText}>
          Cancel Anytime
        </Typography>
      </div>
      <div style={{ margin: 10, display: "flex" }}>
        <div className={classes.checkIconContainer}>
          <CheckIcon style={{ fontSize: 20, color: "#14B29F" }} />
        </div>
        <Typography className={classes.registrationText}>
          No credit card required
        </Typography>
      </div>
    </div>
  );
}

export default RegistrationTagline;
