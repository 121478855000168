import {
  getAgentConversationTotals,
  getConversationTotals,
} from "../../apiCalls/conversationApi";

export const refreshTotals = (companyId) => {
  return async (dispatch) => {
    const totalsResponse = await getConversationTotals(companyId);
    if (totalsResponse) {
      //   console.log({ totalsResponse });
      dispatch({
        type: "SET_CONVERSATION_TOTALS",
        payload: totalsResponse,
      });
    }
  };
};
export const refreshAgentTotals = (companyId, agentId) => {
  return async (dispatch) => {
    const totalsResponse = await getAgentConversationTotals(companyId, agentId);
    if (totalsResponse) {
      dispatch({
        type: "SET_CONVERSATION_TOTALS",
        payload: totalsResponse,
      });
    }
  };
};
