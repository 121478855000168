const initState = {
  currentMessages: [],
  currentSender: {},
  currentConversation: {},
  currentCompanyEmployees: [],
  currentCompany: {},
  conversationTotals: {},
  pdfData: {},
  loading: false,
  isSiteDisabled: false,
  showEmployeeDetails: false,
  searchedMessage: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initState, action) => {
  switch (action.type) {
    case "SET_LOADING":
      return (state = {
        ...state,
        loading: action.payload,
      });
    case "SET_PDF_DATA":
      return (state = {
        ...state,
        pdfData: action.payload,
      });
    case "SET_DISABLE_SITE":
      return (state = {
        ...state,
        isSiteDisabled: action.payload,
      });
    case "SET_SEARCHED_MESSAGE":
      return (state = {
        ...state,
        searchedMessage: action.payload,
      });
    case "SET_EMPLOYEE_DETAILS_FLAG":
      return (state = {
        ...state,
        showEmployeeDetails: action.payload,
      });
    case "SET_MESSAGES":
      return (state = {
        ...state,
        currentMessages: action.payload,
      });
    case "SET_CURRENT_SENDER":
      return (state = {
        ...state,
        currentSender: action.payload,
      });
    case "SET_CURRENT_CONVERSATION":
      return (state = {
        ...state,
        currentConversation: action.payload,
      });
    case "SET_CURRENT_COMPANY":
      return (state = {
        ...state,
        currentCompany: action.payload,
      });
    case "SET_CONVERSATION_TOTALS":
      return (state = {
        ...state,
        conversationTotals: action.payload,
      });
    case "SET_CURRENT_COMPANY_EMPLOYEES":
      return (state = {
        ...state,
        currentCompanyEmployees: action.payload,
      });
    default:
      return state;
  }
};
