import React, { useEffect, useState } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import IconText from "../miscComponents/IconText";
import Button from "@material-ui/core/Button";
import CornerRibbon from "react-corner-ribbon";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import IconButton from "@material-ui/core/IconButton";
import { useDispatch } from "react-redux";
import { getNumberAfter, getNumberBefore } from "../../utils/helperFunctions";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 10,
    margin: "2rem",
    border: "1px solid #d3d4d5",
    width: "30%",
    boxShadow:
      "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
  },
  specialHeader: {
    padding: "20px 40px",
    backgroundColor: "#FF2D55",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    position: "relative",
  },
  specialOfferHeader: {
    padding: "20px 40px",
    backgroundColor: "#C60F32",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    position: "relative",
  },
  normalHeader: {
    padding: "20px 40px",
    backgroundColor: "#999999",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    position: "relative",
  },
  heading: {
    fontSize: 24,
    fontWeight: 600,
    color: "white",
    alignSelf: "center",
  },
  subscribeNormal: {
    backgroundColor: "white",
    color: "#686868",
    border: "1px solid #d3d4d5",
    borderRadius: 10,
    boxShadow: "none",
    "&.MuiButton-contained:hover": {
      border: "1px solid #FF2D55",
      color: "#FF2D55",
    },
  },
  subscribeSpecial: {
    backgroundColor: "#FF2D55",
    color: "white",
    border: "1px solid #d3d4d5",
    borderRadius: 10,
    boxShadow: "none",
    "&.MuiButton-contained:hover": {
      border: "1px solid #FF2D55",
      color: "#FF2D55",
    },
  },
  counterButtons: {
    padding: 5,
    backgroundColor: "#FF2D55",
    "&.MuiButton-root:hover": {
      border: "1px solid #FF2D55",
      color: "#FF2D55",
    },
  },
  "@media (max-width: 640px)": {
    card: {
      width: "90%",
    },
  },
}));

const PricingCards = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const [noOfAgents, setNoOfAgents] = useState(0);
  const [priceToDisplay, setPriceToDisplay] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    setNoOfAgents(props.plan.minAgents);
    setPriceToDisplay(props.pricing);
    // eslint-disable-next-line
  }, [props.plan?.minAgents, props.pricing]);

  useEffect(() => {
    if (props.plan === props.selectedPlan) {
      console.log({ noOfAgents });
      dispatch({ type: "SET_TOTAL_AGENTS", payload: noOfAgents });
    }

    const addedAgents = noOfAgents - props.plan.minAgents;
    setPriceToDisplay(
      props.pricing + addedAgents * props.plan.multiplierPerAgent
    );
    // eslint-disable-next-line
  }, [noOfAgents]);

  useEffect(() => {
    if (props.plan === props.selectedPlan) {
      if (props.plan.id === process.env.REACT_APP_HOTEL_PLAN) {
        dispatch({
          type: "SET_CURRENT_BILL",
          payload: props.pricing,
        });
      } else {
        dispatch({
          type: "SET_CURRENT_BILL",
          payload: props.pricing * noOfAgents,
        });
      }
    }
    // eslint-disable-next-line
  }, [priceToDisplay, props.selectedPlan]);

  return (
    <div className={classes.card}>
      <div
        className={
          props.specialOffer
            ? classes.specialOfferHeader
            : props.selectedPlan === props.plan
            ? classes.specialHeader
            : classes.normalHeader
        }
      >
        {props.specialOffer && (
          <CornerRibbon
            position="top-right"
            fontColor="white"
            backgroundColor="black"
            style={{
              fontSize: 13,
              fontFamily: "inter,Arial,sans-serif",
            }}
          >
            Popular
          </CornerRibbon>
        )}

        <Typography className={classes.heading}>{props.heading}</Typography>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          padding: 32,
          justifyContent: "center",
        }}
      >
        <Typography
          style={{
            fontSize: 20,
            fontWeight: 600,
            color: "black",
            alignSelf: "flex-start",
            fontFamily: "inter,Arial,sans-serif",
            marginTop: 15,
          }}
        >
          €
        </Typography>
        <Typography
          style={{
            fontSize: 65,
            fontWeight: 800,
            color: "black",
            alignSelf: "center",
            fontFamily: "inter,Arial,sans-serif",
          }}
        >
          {getNumberBefore(props.pricing)}
          {/* {parseInt(priceToDisplay.toString().split(".")[0], 10)} */}
        </Typography>
        <div style={{ alignSelf: "center" }}>
          <Typography
            style={{
              fontSize: 20,
              fontWeight: 600,
              color: "black",
              alignSelf: "center",
              fontFamily: "inter,Arial,sans-serif",
              textAlign: "left",
              lineHeight: 1,
            }}
          >
            {getNumberAfter(props.pricing)}

            {/* {parseInt(priceToDisplay.toString().split(".")[1], 10) || null} */}
          </Typography>
          <Typography
            style={
              props.cents
                ? {
                    fontSize: 16,
                    fontWeight: 400,
                    color: "#686868",
                    alignSelf: "flex-end",
                    fontFamily: "inter,Arial,sans-serif",
                  }
                : {
                    fontSize: 16,
                    fontWeight: 400,
                    color: "#686868",
                    alignSelf: "flex-end",
                    fontFamily: "inter,Arial,sans-serif",
                    marginTop: 20,
                  }
            }
          >
            +vat/Month
          </Typography>
        </div>
      </div>

      <div
        style={{
          paddingLeft: 32,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        {props.perks.map((e) => (
          <IconText text={e.value} provided={e.isProvided} />
        ))}
      </div>

      {/* Counter component */}
      <div
        style={{
          width: "90%",
          alignSelf: "center",
          margin: 10,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        <IconButton
          aria-label="delete"
          className={classes.counterButtons}
          onClick={() => {
            if (noOfAgents !== props.plan.minAgents)
              setNoOfAgents(noOfAgents - 1);
          }}
        >
          <RemoveIcon style={{ color: "white" }} />
        </IconButton>
        <Typography
          style={{
            fontSize: 16,
            color: "#686868",
            alignSelf: "center",
            fontFamily: "inter,Arial,sans-serif",
          }}
        >
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            {noOfAgents}{" "}
          </span>{" "}
          Agents
        </Typography>
        <IconButton
          aria-label="delete"
          className={classes.counterButtons}
          onClick={() => {
            if (noOfAgents !== props.plan.maxAgents) {
              setNoOfAgents(noOfAgents + 1);
            } else {
              props.switchPlan(noOfAgents + 1);
            }
          }}
        >
          <AddIcon style={{ color: "white" }} />
        </IconButton>
      </div>
      {/* End of counter component  */}

      <div style={{ width: "60%", alignSelf: "center", margin: 10 }}>
        <Button
          variant="contained"
          className={
            props.selectedPlan === props.plan
              ? classes.subscribeSpecial
              : classes.subscribeNormal
          }
          onClick={() => {
            console.log({ noOfAgents });
            if (props.plan.id === process.env.REACT_APP_HOTEL_PLAN) {
              dispatch({
                type: "SET_CURRENT_BILL",
                payload: props.pricing,
              });
            } else {
              dispatch({
                type: "SET_CURRENT_BILL",
                payload: props.pricing * noOfAgents,
              });
            }
            dispatch({ type: "SET_TOTAL_AGENTS", payload: noOfAgents });
            props.subscribe();
          }}
          fullWidth
        >
          {props.selectedPlan === props.plan ? "Selected" : "Subscribe now"}
        </Button>
      </div>
      <Typography
        style={{
          fontSize: 13,
          cursor: "pointer",
          color: "#686868",
          alignSelf: "center",
          fontFamily: "inter,Arial,sans-serif",
          marginBottom: 15,
        }}
        onClick={() => props.switchPlan(props?.plan.maxAgents + 1)}
      >
        {props.heading === "Hotel"
          ? ""
          : `Max ${props?.plan.maxAgents} agents. Need more? Click here`}
      </Typography>
    </div>
  );
});

export default PricingCards;
