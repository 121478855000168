import { Checkbox, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";

function TextWithCheckBox(props) {
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  useEffect(() => {
    if (checked) {
      props.setConversationToAdd((prevData) => [...prevData, props.id]);
    } else {
      props.setConversationToAdd((prevData) =>
        prevData.filter((e) => e !== props.id)
      );
    }
    // eslint-disable-next-line
  }, [checked]);
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Typography style={{ fontSize: 16, fontWeight: 600 }}>
        {props.textLabel}
      </Typography>
      <Checkbox
        checked={checked}
        onChange={handleChange}
        inputProps={{ "aria-label": "primary checkbox" }}
      />
    </div>
  );
}

export default TextWithCheckBox;
