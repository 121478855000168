import { Typography } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import "../miscStyles.css";
import Avatar from "react-avatar";
import Checkbox from "@material-ui/core/Checkbox";
import { useDispatch } from "react-redux";
import { useWindowDims } from "../../../utils/useDimensions";
import { MESSAGE_CONSTANTS } from "../../../appConstants/constants";
import { getConversationMessagesFromDb } from "../../../apiCalls/conversationApi";

function ConversationCards(props) {
  const { data, setCheckedConversation, setSelectedPhone } = props;
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch();
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const { width } = useWindowDims();

  useEffect(() => {
    if (checked) {
      setCheckedConversation((prev) => [...prev, data._id]);
    } else {
      if (setCheckedConversation) {
        setCheckedConversation((prev) => prev.filter((e) => e !== data._id));
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  const handleConversationCardClick = async () => {
    // console.log({ data });
    props.setInFocus(props.index);

    if (props.fromGroups) {
      props.setIsSelected(data?.conversation._id);
      setSelectedPhone(data?.sender?.phone);
      props.setShowList(false);
      dispatch({
        type: "SET_CURRENT_SENDER",
        payload: data?.sender,
      });
      dispatch({
        type: "SET_CURRENT_CONVERSATION",
        payload: {
          assigned_to: data?.conversation?.assigned_to,
          created_at: data?.conversation?.created_at,
          last_message: data?.conversation?.last_message,
          last_message_time: data?.conversation?.last_message_time,
          sender: data?.sender,
          status: data?.conversation?.status,
          was_previously_closed: data?.conversation?.was_previously_closed,
          _id: data?.conversation?._id,
        },
      });
    } else {
      if (props.isSelected === data?._id) {
        // console.log("equal..");
        const response = await getConversationMessagesFromDb(data?._id, 0, 500);
        if (response) {
          dispatch({ type: "SET_MESSAGES", payload: response.messages });
        }
      } else {
        props.setIsSelected(data?._id);
      }
      setSelectedPhone(data?.sender?.phone);
      props.setShowList(false);
      dispatch({
        type: "SET_CURRENT_SENDER",
        payload: data?.sender,
      });
      dispatch({
        type: "SET_CURRENT_CONVERSATION",
        payload: data,
      });
    }
  };
  const getFormattedString = (str) => {
    if (width < 700) {
      if (str?.length >= 46) {
        const formattedString = str.slice(0, 46) + "...";
        return formattedString;
      } else {
        return str;
      }
    } else {
      if (str?.length >= 28) {
        const formattedString = str.slice(0, 28) + "...";
        return formattedString;
      } else {
        return str;
      }
    }
  };
  return (
    <div
      style={{
        display: "flex",
        // width: "100%",
        cursor: "pointer",
        backgroundColor: props.inFocus && width > 768 ? "#f1f1f1" : "white",
        borderRadius: 10,
        padding: 10,
        alignItems: "center",
        marginLeft: 12,
        marginRight: 12,
        paddingBottom: 15,
        borderBottom: "1px solid #F1F1F1",
      }}
      onClick={handleConversationCardClick}
    >
      {/* <Avatar name={data?.sender?.name} size={30} round /> */}
      <Avatar name="A N" size={30} round />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          marginLeft: 10,
          width: "90%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              fontWeight: 600,
              fontSize: 15,
              fontFamily: "inter,Arial,sans-serif",
            }}
          >
            {/* <strong>{data?.sender?.phone}</strong> */}
            <strong>Anonymous</strong>
          </Typography>
          {data.last_message_sender === MESSAGE_CONSTANTS.WHATS_APP_USER && (
            <Typography
              style={{
                fontSize: 12,
                color: "darkgray",
                fontFamily: "inter,Arial,sans-serif",
              }}
            >
              <strong>1</strong>
            </Typography>
          )}

          {props.isGrouping && (
            <Checkbox
              checked={checked}
              onChange={handleChange}
              inputProps={{ "aria-label": "primary checkbox" }}
              style={{ zIndex: 100 }}
            />
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            width: "100%",
            alignItems: "baseline",
          }}
        >
          <Typography
            style={{
              fontSize: 14,
              color: "#000000De",
              textAlign: "left",
              fontFamily: "inter,Arial,sans-serif",
            }}
          >
            {getFormattedString(data?.subject) || "No subject"}
          </Typography>
          <Typography
            style={{
              fontSize: 14,
              color: "#74798e",
              fontFamily: "inter,Arial,sans-serif",
              wordBreak: "break-all",
              textAlign: "left",
            }}
          >
            {props.fromGroups
              ? getFormattedString(data.conversation.last_message)
              : getFormattedString(data.last_message)}
          </Typography>

          <div className={data.isOnline ? "green-light" : ""}></div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography
            style={{
              fontSize: 12,
              color: "#ABAEBE",
              fontFamily: "inter,Arial,sans-serif",
            }}
          >
            {props.fromGroups
              ? moment(data.conversation.last_message_time)
                  .startOf("ss")
                  .fromNow() + ""
              : moment(data.last_message_time).startOf("ss").fromNow() + ""}
          </Typography>
          {data.last_message_sender === MESSAGE_CONSTANTS.WHATS_APP_USER && (
            <div
              style={{
                width: 9,
                height: 9,
                borderRadius: "50%",
                backgroundColor: "#14b29f",
              }}
            ></div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ConversationCards;
