export const LOGIN = "LOGIN";
export const REGISTER = "REGISTER";
export const SENDER_NAME = "FIRST_RESPONDER";
export const SORT_BY = {
  NEW: "NEW",
  OLD: "OLD",
};

export const USER_TYPE = {
  USER: "USER",
  COMPANY: "ADMIN",
};
export const NAVIGATION_TYPE = {
  USER_PROFILE: "USER_PROFILE",
  ADD_A_USER: "ADD_A_USER",
};
export const MESSAGE_CONSTANTS = {
  WHATS_APP_USER: "WHATS_APP_USER",
  AGENT: "AGENT",
};
export const ALL_VAT_COUNTRIES = [
  "Spain",

  "Bulgaria",

  "Hungary",

  "Latvia",

  "Poland",

  "United Kingdom",

  "Czech Republic",

  "Malta",

  "Italy",

  "Slovenia",

  "Ireland",

  "Sweden",

  "Denmark",

  "Finland",

  "Cyprus",

  "Luxembourg",

  "Romania",

  "Estonia",

  "Greece",

  "Lithuania",

  "France",

  "Croatia",

  "Belgium",

  "Netherlands",

  "Slovakia",

  "Germany",

  "Portugal",

  "Austria",
];
