import React, { useEffect, useState } from "react";
import SearchBarComponent from "../SearchBarComponent";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MenuIcon from "@material-ui/icons/Menu";
import GroupConversations from "../allConversations/GroupConversations";
import { makeStyles, Typography, withStyles } from "@material-ui/core";
import { deleteGroup } from "../../../apiCalls/conversationApi";
import { toast } from "react-toastify";
import ResponsiveAllConversationHeader from "../allConversations/responsiveAllConversationHeader";
import { useWindowDims } from "../../../utils/useDimensions";
import { SORT_BY, USER_TYPE } from "../../../appConstants/constants";
import BottomMenu from "../allConversations/BottomMenu";
import BottomMenuButtons from "../allConversations/BottomMenuButtons";
import moment from "moment";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  hideOnSm: { [theme.breakpoints.down("sm")]: { display: "none" } },
  customScroll: {
    overflow: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      borderRadius: "5px",
    },
  },

  topicContainer: {
    height: "75vh",
    overflow: "scroll",
    overflowX: "hidden",

    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      borderRadius: "5px",
    },
    scrollBehavior: "smooth",

    // [theme.breakpoints.down("sm")]: { height: "50vh" },
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  "@media (max-height: 700px)": {
    messagesContainer: {
      height: "45vh",
    },
  },
  "@media (max-height: 750px)": {
    messagesContainer: {
      height: "52vh",
    },
  },
}));
const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.white,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);
function AllTopicComponent(props) {
  const classes = useStyles();
  const { width } = useWindowDims();

  const { allGroups, isSelected, setIsSelected, setShowList } = props;
  const [searchedItem, setSearchedItem] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [sortBy, setSortBy] = useState("new");
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [showBottomMenu, setShowBottomMenu] = useState(false);

  const userData = useSelector((state) => state.auth.userData);
  const role = useSelector((state) => state.auth.role);
  const currentCompany = useSelector((state) => state.data.currentCompany);

  // console.log({ isSelectedInTopics: isSelected });
  useEffect(() => {
    if (searchedItem === "") {
      setFilteredGroups(allGroups);
    } else {
      setFilteredGroups((prevData) =>
        prevData.filter((e) =>
          e.name.toLowerCase().includes(searchedItem.toLowerCase())
        )
      );
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedItem]);
  useEffect(() => {
    if (allGroups) {
      setFilteredGroups(allGroups);
    }
  }, [allGroups]);
  const handleClick = (event) => {
    if (width > 700) {
      setAnchorEl(event.currentTarget);
    } else {
      setShowBottomMenu(!showBottomMenu);
    }
  };
  const removeGroup = async (id) => {
    const response = await deleteGroup(id);
    if (response) {
      console.log({ response });
      setFilteredGroups((prevData) => prevData.filter((e) => e._id !== id));
      toast.success("Topic removed successfully");
    }
  };

  const sortedGroups = filteredGroups.sort(function compare(a, b) {
    var dateA = new Date(a.created_at);
    var dateB = new Date(b.created_at);
    if (sortBy === SORT_BY.NEW) {
      return dateB - dateA;
    } else {
      return dateA - dateB;
    }
  });
  const getTrailPeriod = (date) => {
    return moment(moment(date).add(14, "days")).fromNow();
  };
  return (
    <div style={{ width: "100%" }}>
      {props.showList && !props.showHead && width <= 900 ? (
        <ResponsiveAllConversationHeader
          showList={props.showList}
          showHead={props.showHead}
          setShowHead={props.setShowHead}
          employeeData={props.employeeData}
          type={props.type}
          handleSortingMenuClick={handleClick}
          searchedItem={searchedItem}
          setSearchedItem={setSearchedItem}
        />
      ) : (
        <>
          <SearchBarComponent
            searchedItem={searchedItem}
            setSearchedItem={setSearchedItem}
          />
          <div
            style={{
              display: "flex",
              padding: 20,
              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{
                fontSize: 16,
                fontWeight: 600,
                fontFamily: "inter,Arial,sans-serif",
              }}
            >
              Topics
            </Typography>

            <MenuIcon
              style={{ fontSize: 25, cursor: "pointer" }}
              onClick={handleClick}
            />
          </div>
        </>
      )}

      <StyledMenu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        <StyledMenuItem
          onClick={() => {
            setSortBy(SORT_BY.NEW);
            setAnchorEl(null);
          }}
        >
          Newest first
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => {
            setSortBy(SORT_BY.OLD);
            setAnchorEl(null);
          }}
        >
          Oldest first
        </StyledMenuItem>
      </StyledMenu>

      <div className={classes.topicContainer}>
        {sortedGroups.map((e) => (
          <GroupConversations
            data={e}
            isSelected={isSelected}
            setIsSelected={setIsSelected}
            setShowList={setShowList}
            selectedPhone={props.selectedPhone}
            setSelectedPhone={props.setSelectedPhone}
            removeGroup={removeGroup}
          />
        ))}
        {role === USER_TYPE.COMPANY ? (
          <>
            {!userData?.user?.stripeSubscriptionId && (
              <Typography
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#AEB6D1",
                  marginTop: 30,
                }}
              >
                Your trial expires{" "}
                <span
                  style={{
                    color: "#30AE9F",
                  }}
                >
                  {getTrailPeriod(userData?.user?.trail_started)}
                </span>
              </Typography>
            )}
          </>
        ) : (
          <>
            {!currentCompany?.stripeSubscriptionId && (
              <Typography
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#AEB6D1",
                  marginTop: 30,
                }}
              >
                Your trial expires{" "}
                <span
                  style={{
                    color: "#30AE9F",
                  }}
                >
                  {getTrailPeriod(currentCompany?.trail_started)}
                </span>
              </Typography>
            )}
          </>
        )}
      </div>
      {showBottomMenu && (
        <BottomMenu
          open={showBottomMenu}
          handleClose={() => {
            setShowBottomMenu(false);
          }}
          options={
            <>
              <BottomMenuButtons
                text="Newest first"
                onClick={() => {
                  setSortBy(SORT_BY.NEW);
                  setShowBottomMenu(false);
                }}
              />
              <BottomMenuButtons
                text="Oldest First"
                onClick={() => {
                  setSortBy(SORT_BY.OLD);
                  setShowBottomMenu(false);
                }}
              />
            </>
          }
        />
      )}
    </div>
  );
}

export default AllTopicComponent;
