import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles, TextField } from "@material-ui/core";
import { toast } from "react-toastify";
import SearchBarComponent from "../chatComponenets/SearchBarComponent";
import TextWithCheckBox from "../chatComponenets/allTopicsComponent/TextWithCheckBox";
import { useSelector } from "react-redux";
import { USER_TYPE } from "../../appConstants/constants";
import { groupConversations } from "../../apiCalls/conversationApi";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      //   width: "25ch",
    },
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
  },
  submitYes: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "white",
    color: "black",
    paddingLeft: 20,
    paddingRight: 20,
    marginLeft: 20,
    border: "2px solid #000",
  },
  submitNo: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#FF2E56",
    color: "white",
    paddingLeft: 20,
    paddingRight: 20,
    marginRight: 20,
  },
  buttonDiv: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  formDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 10,
  },
  TextFieldStyle: {
    width: "100%",
  },
  searchBar: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#F1F1F1",
    marginTop: 10,
    marginBottom: 10,
    padding: 5,
    borderRadius: 10,
  },
}));
function CreateTopicModal(props) {
  const classes = useStyles();

  const [searchedItem, setSearchedItem] = useState("");
  const [filteredConversations, setFilteredConversations] = useState([]);
  const [conversationToAdd, setConversationToAdd] = useState([]);
  const [disableButton, setDisableButton] = useState(true);
  const [topicName, setTopicName] = useState("");

  const role = useSelector((state) => state.auth.role);
  const userData = useSelector((state) => state.auth.userData);

  useEffect(() => {
    if (props.allConversation) {
      setFilteredConversations(props.allConversation);
    }
    // eslint-disable-next-line
  }, [props]);
  useEffect(() => {
    if (conversationToAdd.length >= 1) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [conversationToAdd]);

  useEffect(() => {
    if (searchedItem === "") {
      setFilteredConversations(props.allConversation);
    } else {
      setFilteredConversations((prevData) =>
        prevData.filter(
          (e) =>
            e?.last_message
              ?.toLowerCase()
              .includes(searchedItem.toLowerCase()) ||
            e?.subject?.toLowerCase().includes(searchedItem.toLowerCase())
        )
      );
    }
    // eslint-disable-next-line
  }, [searchedItem]);

  const createNewTopic = async () => {
    if (topicName !== "") {
      setDisableButton(true);
      console.log("creating new topic..");
      if (role === USER_TYPE.COMPANY) {
        const response = await groupConversations(
          conversationToAdd,
          topicName,
          userData?.user?._id
        );
        if (response) {
          // console.log({ response });
          toast.success("Topic created successfully");
          props.setOpen(false);
          setDisableButton(false);
        }
      } else {
        const response = await groupConversations(
          conversationToAdd,
          topicName,
          userData?.user?.company
        );
        if (response) {
          // console.log({ response });
          toast.success("Topic created successfully");
          props.setOpen(false);
          setDisableButton(false);
        }
      }
    } else {
      toast.warn("please add an name to the topic");
    }
  };
  return (
    <div>
      <Modal
        open={props.open}
        onClose={() => {
          props.setOpen(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          style={{
            top: "50%",
            left: "50%",
            transform: `translate(-${50}%, -${50}%)`,
          }}
          className={classes.paper}
        >
          <h4 id="simple-modal-title" style={{ textAlign: "center" }}>
            Select conversations
          </h4>
          <TextField
            id="filled-multiline-static"
            className={classes.TextFieldStyle}
            label="Topic name"
            variant="standard"
            onChange={(e) => {
              setTopicName(e.target.value);
            }}
            // fullWidth
          />
          <SearchBarComponent
            searchedItem={searchedItem}
            setSearchedItem={setSearchedItem}
            customStyle={classes.searchBar}
            placeHolder="Search conversations"
          />

          <FormControl component="fieldset" style={{ width: "100%" }}>
            <form className={classes.root} noValidate autoComplete="off">
              <div className={classes.formDiv}></div>
            </form>
            {filteredConversations.map((e, index) => (
              <TextWithCheckBox
                textLabel={e.subject || e.last_message}
                setConversationToAdd={setConversationToAdd}
                id={e._id}
                key={index}
              />
            ))}
          </FormControl>

          <div className={classes.buttonDiv}>
            <Button
              className={classes.submitNo}
              onClick={() => {
                props.setOpen(false);
              }}
            >
              cancel
            </Button>
            <Button
              variant="contained"
              className={classes.submitYes}
              onClick={createNewTopic}
              disabled={disableButton}
            >
              Create Topic
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default CreateTopicModal;
