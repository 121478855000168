import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {
  sendRegistrationLink,
  updateUserProfile,
} from "../../apiCalls/AuthenticationApi";
import { toast } from "react-toastify";
import { USER_TYPE } from "../../appConstants/constants";
import CustomInputField from "./CustomInputField";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import MenuIcon from "@material-ui/icons/Menu";
import { useWindowDims } from "../../utils/useDimensions";
import PhoneInput from "react-phone-input-2";
const useStyles = makeStyles((theme) => ({
  hideOnSm: {
    [theme.breakpoints.down("sm")]: { display: "none" },
  },
  mainBox: {
    height: "100%",
    backgroundColor: "white",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "60%",
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    alignItems: "center",
    width: "80%",
    borderRadius: 10,
    marginTop: 50,
  },
  headingText: {
    fontSize: 16,
    fontWeight: 600,
    alignSelf: "baseline",
    padding: 16,
  },
  buttonDiv: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    // // paddingTop: "2rem",
    paddingBlock: 16,
    paddingLeft: 16,
  },
  conversationsColumn: {
    display: "flex",
    flexDirection: "column",
    borderRight: "solid",
    borderWidth: 1,
    borderColor: "#cacdd7 !important",
    backgroundColor: "white",
  },
  messagesColumn: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    backgroundColor: "#F8F8F8",
  },
  rightSideHeader: {
    display: "flex",
    width: "100%",
    height: "10%",
    backgroundColor: "white",
    padding: 10,
    borderBottom: "1px solid #d3d4d5",
    alignItems: "center",
  },
  inviteCheck: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  inputFieldPhone: {
    width: "50%",
    border: "2px solid #F1F1F1",
    padding: "8px 12px",
    fontFamily: "inter,Arial,sans-serif",
    borderRadius: 5,
    "&:focus": {
      border: "2px solid #F1F1F1",
    },
  },
  "@media (max-width: 1000px)": {
    inputContainer: {
      width: "90%",
      padding: 0,
      marginTop: 20,
      justifyContent: "center",
    },
    mainContainer: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    headingText: {
      padding: 0,
      paddingTop: 16,
      paddingBottom: 16,
    },
    buttonDiv: {
      justifyContent: "center",
    },
    inputFieldPhone: {
      width: "90%",
    },
  },
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    // padding: 20,
    backgroundColor: "#F8F8F8",
    // backgroundColor: "red",

    // height: "100%",
    // [theme.breakpoints.down("sm")]: { width: "100%", padding: 0 },
    overflowY: "scroll !important",
    overflowX: "hidden",
    // maxHeight: "85vh",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      borderRadius: "5px",
    },
    scrollBehavior: "smooth !important",
  },
  inputField: {
    marginBottom: 10,
    width: "60%",
    marginTop: theme.spacing(2),
  },

  "@media (max-width: 700px)": {
    inputField: {
      width: "100%",
    },
  },
  primaryText: {
    fontSize: 22,
    fontWeight: 600,
    alignSelf: "flex-start",
  },
  submit: {
    backgroundColor: "#FF2E56",
    color: "white",
    alignSelf: "center",
    paddingLeft: 30,
    paddingRight: 30,
    // marginTop: theme.spacing(2),
  },
}));
function UserDetails(props) {
  const classes = useStyles();

  //COnditional rendering flags
  const [employeeToShow, setEmployeeToShow] = useState(null);
  const [showInvitePage, setShowInvitePage] = useState(false);

  //Email for sending link
  const [sendLinkTo, setSendLinkTo] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [role, setRole] = useState(USER_TYPE.USER);
  const [disableButton, setDisableButton] = useState(false);
  const [phone, setPhone] = useState("");

  //for profile update
  const [roleToUpdate, setRoleToUpdate] = useState(USER_TYPE.USER);
  const [name, setName] = useState("");

  //Responsive flags
  const [showLeftColumn, setShowLeftColumn] = useState(true);

  const dispatch = useDispatch();
  const { width, height } = useWindowDims();
  const userData = useSelector((state) => state.auth.userData);
  const roleFromRedux = useSelector((state) => state.auth.role);
  const currentCompanyEmployees = useSelector(
    (state) => state.data.currentCompanyEmployees
  );
  const showEmployeeDetails = useSelector(
    (state) => state.data.showEmployeeDetails
  );

  useEffect(() => {
    // console.log({ showEmployeeDetails });
    if (!showEmployeeDetails) {
      setEmployeeToShow(null);
    } else {
      setEmployeeToShow({
        name:
          "" +
          userData?.user?.adminFirstName +
          " " +
          userData?.user?.adminLastName,
        email: userData?.user?.email,
        type: userData?.role,
      });
    }

    // eslint-disable-next-line
  }, [showEmployeeDetails]);

  useEffect(() => {
    console.log({ userData });
    if (roleFromRedux === USER_TYPE.USER) {
      setEmployeeToShow(userData.user);
    }
    // eslint-disable-next-line
  }, [userData]);

  useEffect(() => {
    // console.log({ employeeToShow });
    if (employeeToShow) {
      setName(employeeToShow.name);
      setRoleToUpdate(employeeToShow.type);
    }
  }, [employeeToShow]);

  useEffect(() => {
    if (roleFromRedux === USER_TYPE.COMPANY && !showEmployeeDetails) {
      setEmployeeToShow(null);
      setShowLeftColumn(false);
    }
  }, [roleFromRedux, showEmployeeDetails]);
  const handleSendEmail = async () => {
    console.log({ currentCompanyEmployees, userData });
    if (
      currentCompanyEmployees?.length >=
      userData?.user?.stripeSubscriptionDetails?.selectedAgents
    ) {
      toast.warn(
        "You have reached the max number of agents allowed on you subscription to add more subscribe to a bigger plan"
      );
    } else {
      // console.log({
      //   sendLinkTo,
      //   role,
      //   firstName,
      //   lastName,
      //   company: userData.user._id,
      //   phone,
      // });
      if (validateEmail(sendLinkTo)) {
        setDisableButton(true);
        const response = await sendRegistrationLink(
          sendLinkTo,
          role,
          firstName + " " + lastName,
          userData.user._id,
          phone
        );
        if (response) {
          console.log({ response });
          toast.success("Email sent to: " + sendLinkTo);
          setSendLinkTo("");
          setDisableButton(false);
        } else {
          toast.error("Something went wrong!");
          setDisableButton(false);
        }
      } else {
        toast.error("Invalid Email");
      }
    }
  };

  const handleUpdateProfile = async () => {
    console.log({ email: userData.user.email, name, roleToUpdate });
    if (name === employeeToShow?.name) {
      toast.warn("Please provide updated data");
    } else {
      setDisableButton(true);
      const response = await updateUserProfile(userData.user.email, name);
      if (response) {
        console.log({ response });
        const updatedUser = {
          token: userData.token,
          user: response.user,
        };
        dispatch({ type: "UPDATE_USER_DATA", payload: updatedUser });
        toast.success("Profile updated ");
        setDisableButton(false);
      } else {
        console.log({ response });
        toast.error("Something went wrong");
        setDisableButton(false);
      }
    }
  };
  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  const handleRoleSelect = (event) => {
    setRole(event.target.value);
  };
  return (
    <Grid container className={classes.mainBox}>
      <Grid
        item
        xs={12}
        md={3}
        className={
          showLeftColumn ? classes.conversationsColumn : classes.hideOnSm
        }
        style={
          roleFromRedux === USER_TYPE.COMPANY && !showEmployeeDetails
            ? { display: "none" }
            : {}
        }
      >
        <MenuIcon
          className="back-button"
          onClick={() => {
            // setShowLeftColumn(true);
            console.log("goin back..");
            props.setShowList(true);
            props.setShowHead(true);
          }}
          style={{
            marginRight: 10,
            margin: 10,
            fontSize: 30,
          }}
        />
        {roleFromRedux === USER_TYPE.COMPANY ? (
          <>
            {!showEmployeeDetails ? (
              <></>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #d3d4d5",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "red",
                    },
                    padding: 10,
                  }}
                  onClick={() => {
                    setEmployeeToShow({
                      name:
                        "" +
                        userData?.user?.adminFirstName +
                        " " +
                        userData?.user?.adminLastName,
                      email: userData?.user?.email,
                      type: userData?.role,
                    });
                    setShowLeftColumn(false);
                  }}
                >
                  <Typography className={classes.headingText}>
                    {userData?.user?.adminFirstName || "Admin"}{" "}
                    {userData?.user?.adminLastName || ""}
                  </Typography>
                </div>
                {currentCompanyEmployees.map((e, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderBottom: "1px solid #d3d4d5",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "red",
                      },
                      padding: 10,
                    }}
                    onClick={() => {
                      setEmployeeToShow(e);
                      setShowLeftColumn(false);
                    }}
                  >
                    <Typography className={classes.headingText}>
                      {e.name}
                    </Typography>
                  </div>
                ))}
              </>
            )}
          </>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                padding: 10,
                borderBottom: "1px solid #d3d4d5",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "red",
                },
              }}
              onClick={() => {
                setEmployeeToShow({
                  name: userData?.user?.name,
                  email: userData?.user?.email,
                  type: userData?.role,
                });
                setShowLeftColumn(false);
              }}
            >
              <Typography className={classes.headingText}>
                {userData.user.name}
              </Typography>
            </div>
          </>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        md={
          roleFromRedux === USER_TYPE.COMPANY && !showEmployeeDetails ? 12 : 9
        }
        className={!showLeftColumn ? classes.messagesColumn : classes.hideOnSm}
      >
        <div className={classes.rightSideHeader}>
          {!employeeToShow && showInvitePage ? (
            <MenuIcon
              className="back-button"
              onClick={() => {
                // setShowLeftColumn(true);
                // console.log("goin back..");
                props.setShowList(true);
                props.setShowHead(true);
              }}
              style={{
                marginRight: 10,
                margin: 10,
                fontSize: 30,
              }}
            />
          ) : (
            <KeyboardBackspaceIcon
              className="back-button"
              onClick={() => {
                // setShowLeftColumn(true);
                // console.log("goin back..");
                props.setShowList(true);
                props.setShowHead(true);
              }}
              style={{
                marginRight: 10,
                margin: 10,
                fontSize: 30,
              }}
            />
          )}

          <Typography className={classes.headingText}>
            {userData?.user.name}
          </Typography>
        </div>
        <div className={classes.mainContainer} style={{ height: height - 100 }}>
          <div className={classes.inputContainer}>
            {employeeToShow ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={
                    width < 1000
                      ? {
                          width: "90%",
                          display: "flex",
                          flexDirection: "column",
                          alignSelf: "center",
                          justifyContent: "center",
                          marginBottom: 20,
                        }
                      : {
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          alignSelf: "center",
                          justifyContent: "center",
                          marginBottom: 20,
                          marginTop: 20,
                        }
                  }
                >
                  <CustomInputField
                    label="Email"
                    value={employeeToShow.email}
                    onChange={(e) => {
                      // setSendLinkTo(e.target.value);
                    }}
                  />
                  <CustomInputField
                    label="Name"
                    value={name}
                    onChange={(e) => {
                      if (roleFromRedux !== USER_TYPE.COMPANY)
                        setName(e.target.value);
                    }}
                  />

                  <CustomInputField
                    label="Authorization"
                    value={roleToUpdate}
                    onChange={(event) => {
                      if (roleFromRedux !== USER_TYPE.COMPANY)
                        setRoleToUpdate(event.target.value);
                    }}
                    select
                  />

                  {roleFromRedux !== USER_TYPE.COMPANY && (
                    <div className={classes.buttonDiv}>
                      <Button
                        variant="contained"
                        className={classes.submit}
                        onClick={handleUpdateProfile}
                        disabled={disableButton}
                      >
                        {disableButton ? "Updating.." : "Update"}
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {!showInvitePage ? (
                  <div className={classes.inviteCheck}>
                    <Typography
                      style={{
                        fontSize: 18,
                        fontWeight: 700,
                        marginBottom: "1rem",
                        paddingTop: "2rem",
                      }}
                    >
                      Invite your colleagues
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 16,
                        marginBottom: "1rem",
                      }}
                    >
                      Invite colleagues to start working together.
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        paddingTop: "2rem",
                        paddingBlock: "2rem",
                        paddingLeft: 16,
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: "#30AE9F",
                          color: "white",
                          alignSelf: "center",
                          // marginTop: theme.spacing(2),
                        }}
                        onClick={() => setShowInvitePage(true)}
                      >
                        Invite a colleague
                      </Button>
                    </div>
                  </div>
                ) : (
                  <>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "baseline",
                          borderBottom: "2px solid #F1F1F1",
                          padding: 16,
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: 15,
                            alignSelf: "baseline",
                            fontFamily: "inter,Arial,sans-serif",
                          }}
                        >
                          User
                        </Typography>
                      </div>
                      <div
                        style={
                          width < 1000
                            ? {
                                width: "90%",
                                display: "flex",
                                flexDirection: "column",
                                alignSelf: "center",
                                justifyContent: "center",
                                marginBottom: 20,
                              }
                            : {
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                alignSelf: "center",
                                justifyContent: "center",
                                marginBottom: 20,
                                marginTop: 20,
                              }
                        }
                      >
                        <CustomInputField
                          label="Email"
                          value={sendLinkTo}
                          onChange={(e) => {
                            setSendLinkTo(e.target.value);
                          }}
                        />
                        <CustomInputField
                          label="First name"
                          value={firstName}
                          onChange={(e) => {
                            setFirstName(e.target.value);
                          }}
                        />
                        <CustomInputField
                          label="Last name"
                          value={lastName}
                          onChange={(e) => {
                            setLastName(e.target.value);
                          }}
                        />
                        <CustomInputField
                          label="Phone number"
                          customSelect={
                            <div className={classes.inputFieldPhone}>
                              <PhoneInput
                                country="ie"
                                regions={"europe"}
                                value={phone}
                                onChange={(phone) => setPhone(phone)}
                                enableSearch
                                dropdownClass="drop-down"
                                inputClass="input-class"
                                containerClass="container-class"
                              />
                            </div>
                          }
                        />

                        <CustomInputField
                          label="Authorization"
                          value={role}
                          onChange={handleRoleSelect}
                          select
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        height: 20,
                        backgroundColor: "#F8F8F8",
                      }}
                    ></div>
                    <div className={classes.buttonDiv}>
                      <Button
                        variant="contained"
                        className={classes.submit}
                        onClick={handleSendEmail}
                        disabled={disableButton}
                      >
                        Send Invite
                      </Button>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

export default UserDetails;
