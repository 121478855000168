export const START_PLAN = {
  name: "Coffee Shop",
  price: 19.95,
  minAgents: 1,
  maxAgents: 2,
  multiplierPerAgent: 19.95,
  id: process.env.REACT_APP_COFFEE_PLAN,
  perks: [
    { isProvided: true, value: "€19.95 per Agent" },
    { isProvided: true, value: "Max 2 Agents" },
    { isProvided: true, value: "100 Reports" },
    { isProvided: true, value: "500 Messages" },
    { isProvided: false, value: "WhatsApp Number" },
  ],
};
export const GROW_PLAN = {
  name: "Restaurant",
  price: 17.95,
  minAgents: 3,
  maxAgents: 5,
  multiplierPerAgent: 17.95,
  id: process.env.REACT_APP_RESTAURANT_PLAN,
  perks: [
    { isProvided: true, value: "€17.95 per Agent" },
    { isProvided: true, value: "Max 5 Agents" },
    { isProvided: true, value: "300 Reports" },
    { isProvided: true, value: "750 Messages" },
    { isProvided: false, value: "WhatsApp Number" },
  ],
};
export const SCALE_PLAN = {
  name: "Hotel",
  price: 125,
  minAgents: 6,
  maxAgents: 999,
  multiplierPerAgent: 0,
  id: process.env.REACT_APP_HOTEL_PLAN,
  perks: [
    { isProvided: true, value: "Price of a hotel room" },
    { isProvided: true, value: "Unlimited Agents" },
    { isProvided: true, value: "Unlimited Reports" },
    { isProvided: true, value: "Unlimited Messages" },
    { isProvided: true, value: " WhatsApp Number" },
  ],
};
export const YEARLY_PLAN = "YEARLY";
export const MONTHLY_PLAN = "MONTHLY";
